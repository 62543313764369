import React from 'react';
import { Add, Remove } from '@mui/icons-material';
import { Backdrop, Slider } from '@mui/material';
import { TextField } from '@zetadisplay/zeta-ui-components';

import { MiniButton } from './MiniButton';

interface INumberEditProps {
    label?: string;
    value?: number | string;
    onChange: (newValue: number | string | undefined) => void;
    disabled?: boolean;
    error?: boolean;
    placeholder?: string;
    required?: boolean;
    style?: any;
    disableDecimals?: boolean;
    precision?: number;
    min?: number;
    max?: number;
    unit?: string;
    useUnitInValue?: boolean;
    unitBefore?: boolean;
    step?: number;
}

const posNumReg = new RegExp('[^0-9.]|\\.(?=.*\\.)', 'g');
const numReg = new RegExp('[^0-9.-]|\\.(?=.*\\.)', 'g');

const trimZeros = (val?: number, precision?: number): string => {
    if (val === undefined) return '';
    if (precision === undefined) return String(val);
    let s = val.toFixed(precision);
    let n = s.indexOf('.');
    if (n < 0) return s;
    n = s.lastIndexOf('0');
    while (n === s.length - 1) {
        s = s.substring(0, s.length - 1);
        n = s.lastIndexOf('0');
    }
    n = s.lastIndexOf('.');
    return n === s.length - 1 ? s.substring(0, s.length - 1) : s;
};

export const NumberEdit = (props: INumberEditProps) => {
    const {
        value,
        label,
        onChange,
        disabled,
        error,
        placeholder,
        required,
        style,
        disableDecimals,
        precision = 2,
        min,
        max,
        unit,
        useUnitInValue,
        unitBefore,
        step = 1,
    } = props;
    const [intermediate, setIntermediate] = React.useState<string | undefined>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const blurDisabled = React.useRef(false);
    const ref = React.useRef<any>();

    /*let numValue: number | undefined =
        useUnitInValue && unit && typeof value === 'string'
            ? Number(String(value).substring(0, String(value).indexOf(unit)))
            : Number(value);
    if (isNaN(numValue)) numValue = undefined;*/

    let numValue: number | undefined;
    if (useUnitInValue && unit && typeof value === 'string') {
        numValue = unitBefore
            ? Number(String(value).substring(String(value).indexOf(unit)))
            : Number(String(value).substring(0, String(value).indexOf(unit)))
    } else numValue = Number(value);
    if (isNaN(numValue)) numValue = undefined;

    const textValue = intermediate !== undefined ? intermediate : trimZeros(numValue, precision);

    const changeHandler = (ev: any) => {
        let val: string = !disableDecimals ? ev.target.value.replace(',', '.') : ev.target.value;
        const endsWithPoint = !disableDecimals && val.indexOf('.') === val.length - 1;
        const neg = min === undefined || min < 0;
        const minus = neg && val === '-';
        if (!minus) val = val.replace(neg ? numReg : posNumReg, '');
        const num = Number(val);
        if (
            endsWithPoint ||
            minus ||
            val === '' ||
            (min !== undefined && num < min) ||
            (max !== undefined && num > max)
        ) {
            setIntermediate(val);
            if (val === '') onChange(undefined);
        } else {
            setIntermediate(undefined);
            if (useUnitInValue && unit) onChange(unitBefore ? `${unit}${num}` : `${num}${unit}`);
            else onChange(num);
        }
    };

    const keyDownHandler = (ev: React.KeyboardEvent) => {
        const { key } = ev;
        if (
            (key === '.' && textValue.toString().indexOf('.') >= 0) ||
            (key === '-' && textValue.toString().indexOf('-') >= 0)
        ) {
            ev.preventDefault();
        }
    };

    const increment = (delta: number) => {
        let val = (numValue || 0) + delta;
        if (min !== undefined && val < min) val = min;
        if (max !== undefined && val > max) val = max;
        setIntermediate(undefined);
        if (useUnitInValue && unit) onChange(unitBefore
            ? `${unit}${trimZeros(val, precision)}`
            : `${trimZeros(val, precision)}${unit}`);
        else onChange(val);
    };

    const wheelHandler = (ev: React.WheelEvent) => {
        if (disabled) return;
        const delta = ev.deltaY > 0 ? -step : step;
        increment(delta);
    };

    const sliderChangeHandler = React.useCallback(
        (ev: Event, value: number | number[]) => {
            ref.current.focus();
            if (useUnitInValue && unit) onChange(unitBefore
                ? `${unit}${trimZeros(value as number, precision)}`
                : `${trimZeros(value as number, precision)}${unit}`);
            else onChange(value as number);
            setIntermediate(undefined);
        },
        [onChange, useUnitInValue, unit, precision, unitBefore]
    );

    const sliderMoveHandler = React.useCallback(() => {
        blurDisabled.current = true;
    }, []);

    const sliderOutHandler = React.useCallback(() => {
        blurDisabled.current = false;
    }, []);

    const rect = anchorEl && anchorEl.getBoundingClientRect();
    const hasSlider = min !== undefined && max !== undefined;

    return (
        <>
            <TextField
                value={textValue}
                inputRef={ref}
                onChange={changeHandler}
                onKeyDown={keyDownHandler}
                onWheel={wheelHandler}
                onFocus={(ev: any) => {
                    if (anchorEl) return;
                    const rootDiv = ev.currentTarget.parentElement.parentElement;
                    setAnchorEl(rootDiv);
                }}
                onBlur={() => {
                    if (!blurDisabled.current && !!anchorEl) setAnchorEl(null);
                }}
                disabled={disabled}
                error={error || (!disabled && !!intermediate)}
                placeholder={placeholder}
                label={label}
                required={required}
                style={{ ...{ minWidth: 100, maxWidth: 100 }, ...style }}
                endAdornment={unitBefore ? undefined : unit}
                startAdornment={unitBefore ? unit : undefined}
            />
            {!!rect && (
                <Backdrop open={!!rect} invisible style={{ display: 'block', zIndex: 2000 }} onWheel={wheelHandler}>
                    <div
                        style={{
                            position: 'absolute',
                            /*left: hasSlider ? rect.left : rect.right + 2,
                    top: hasSlider ? rect.bottom + 2: rect.top - (unit ? 0 : 1),
                    height: hasSlider ? 50 : rect.height + (unit ? 0 : 1),*/
                            left: rect.right + 2,
                            top: rect.top - (unit ? 0 : 1),
                            height: rect.height + (unit ? 0 : 1),
                            width: hasSlider ? 216 : 60,
                            backgroundColor: '#1D1D1D',
                            display: 'flex',
                            zIndex: 3000,
                            alignItems: 'center',
                            justifyContent: hasSlider ? 'flex-end' : 'center',
                            padding: '0 4px',
                            border: '1px solid #999999',
                        }}
                        onMouseDownCapture={(ev: any) => {
                            // prevent popup from closing when clicked
                            // don't call preventDefault() when target is on the slider
                            if (typeof ev.target.className !== 'string' || !ev.target.className?.startsWith('MuiSlider')) {
                                ev.preventDefault();
                            }
                        }}
                    >
                        {hasSlider && (
                            <Slider
                                min={min}
                                max={max}
                                step={step}
                                value={numValue}
                                style={{ margin: '-1px 12px 0 8px' }}
                                onWheel={wheelHandler}
                                onChange={sliderChangeHandler}
                                onMouseMove={sliderMoveHandler}
                                onMouseOut={sliderOutHandler}
                            />
                        )}
                        <MiniButton
                            color="#FFF"
                            style={{ padding: 2, marginRight: '0px' }}
                            onClick={() => {
                                increment(-step);
                            }}
                        >
                            <Remove width={14} height={14} style={{ fontSize: '1.1rem' }} />
                        </MiniButton>
                        <MiniButton
                            color="#FFF"
                            style={{ padding: 2 }}
                            onClick={() => {
                                increment(step);
                            }}
                        >
                            <Add width={14} height={14} style={{ fontSize: '1.1rem' }} />
                        </MiniButton>
                    </div>
                </Backdrop>
            )}
        </>
    );
};
