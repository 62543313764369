import { Box } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Logo } from '@zetadisplay/zeta-ui-components';
import { colors } from '@zetadisplay/zeta-ui-components/utils/theme/theme-options';
import React from 'react';

import EditorTheme from './EditorTheme';
import FileControls from './FileControls';
import FileHeader from './FileHeader';
import { Use_mq_MobileWidth } from './mediaQueries';
import Page from './Page';
import PageNavigation from './PageNavigation';
import UserControls from './UserControls';
import ViewControls from './ViewControls';
import {processEnv} from "../config/processEnv";

export default function SpotEditor(props) {
        const { editorIntf, zdlib } = props;
        const editing = zdlib.editor.editing
        const tHook = useTranslation();
        const mobileWidth = Use_mq_MobileWidth();
        const transProps = {...props, ...{tHook}};
        return (editing 
                && 
                <EditorTheme dark editorIntf={editorIntf}> 
                        <PageNavigation {...transProps}/>
                        <Box display="flex" 
                                alignItems="stretch"
                                width={1}
                                height={1}
                                style={{overflow: 'hidden'}}
                                >
                                        <Page {...transProps}/>
                                        <Box display="flex" 
                                                flexGrow={1} 
                                                flexDirection="column" 
                                                alignItems="stretch"                                                                  
                                        >  
                                                <Box display="flex"
                                                     justifyContent="space-between"
                                                     alignItems="center"
                                                     padding="0 16px"
                                                     height={editorIntf.layoutConfig.infobar.height}
                                                     bgcolor={colors.CONTENT_DARK}
                                                >
                                                        {!mobileWidth
                                                            && <div style={{color: '#F39200', display: 'flex', alignItems: 'center', fontSize: 13, letterSpacing: 2}}>
                                                                        <Logo height={26} style={{opacity: 0.6, marginRight: 8}}/>
                                                                    {processEnv.ENGAGE_ENV !== 'prod' ? `engage${processEnv.ENGAGE_ENV}` : ''}
                                                                </div>
                                                        }
                                                        <FileHeader {...transProps}/>
                                                        {!mobileWidth
                                                            && <UserControls {...transProps}/>}
                                                </Box>
                                                        <Box flexGrow={1}>
                                                                { /* Space for template root div */ }
                                                        </Box>
                                                        <ViewControls {...transProps}/>
                                                        <FileControls {...transProps}/>
                                        </Box>
                        </Box>    
                </EditorTheme>                    
                    
                );  
}
