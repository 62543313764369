import React from 'react';
import { Snackbar } from '@mui/material';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import type { InfoFunc, SnackbarOptions } from './InfoProvider';

const useStyles = makeStyles()((theme) => ({
    snackbar: {
        // right: 70,
        // bottom: 12,
        pointerEvents: 'auto',
    },
    snackPaper: {
        borderRadius: 0,
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.text,
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.text,
    },
    info: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.text,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.text,
    },
}));

interface SnackbarProviderProps {
    setShowSnackbar: (showSnackbar: InfoFunc<SnackbarOptions>) => void;
}

interface SnackbarProviderInfo extends SnackbarOptions {
    key?: string;
}

export default function SnackbarProvider({ setShowSnackbar }: SnackbarProviderProps): JSX.Element | null {
    const [info, setInfo] = React.useState<SnackbarProviderInfo | null>(null);
    const { classes } = useStyles();
    React.useEffect(() => {
        setShowSnackbar((options) => {
            const {
                text,
                timeout = 6000,
                color = 'info',
                horizontal = 'right',
                vertical = 'bottom',
                parseFunction,
            } = options;
            setInfo({
                ...options,
                ...{
                    text: parseFunction ? parseFunction(text) : text,
                    key: `sn${new Date().getTime()}`,
                    timeout,
                    color,
                    horizontal,
                    vertical,
                },
            });
            return () => setInfo(null);
        });
    }, [setShowSnackbar]);

    const handleClose = (event: unknown, reason: string) => {
        if ((reason === 'timeout' && info?.action) || reason === 'clickaway') {
            return;
        }
        setInfo(null);
    };

    if (!info) return null;

    const { text, key, timeout, color = 'info', horizontal = 'right', vertical = 'bottom', action, onAction } = info;

    return (
        <Snackbar
            open
            message={text}
            key={key}
            autoHideDuration={timeout}
            ContentProps={{ className: classes[color], classes: { root: classes.snackPaper } }}
            className={classes.snackbar}
            anchorOrigin={{ vertical, horizontal }}
            action={
                action ? (
                    <Button
                        label={action}
                        className={classes[color]}
                        style={{ textTransform: 'uppercase' }}
                        onClick={() => {
                            onAction && onAction(info);
                            setInfo(null);
                        }}
                    />
                ) : null
            }
            onClose={handleClose}
        />
    );
}
