import {autorun, extendObservable, reaction, toJS} from 'mobx';

import { getLocalizedString } from '../localization/localizationUtils';
import { AssetLoader } from './utils/AssetLoader';
import { DataLoader } from './utils/DataLoader';
import { EventLoader } from './utils/EventLoader';
import {FeedLoader} from "./utils/FeedLoader";
import { getAssetSrcUrl } from './utils/getAssetSrcUrl';
import { isEqual } from './utils/isEqual';
import {getTransform, zoomTo} from "./utils/panZoom";
import { Timeline } from './utils/Timeline';
import { Timer } from './utils/Timer';

export const createZDLib = (version) => {
    let spot;
    let autoRuns;
    let reactions;
    const host = document.location.host;
    const zdlib = {
        utils: {
            isEqual,
            getLocalizedString: (value) => getLocalizedString(zdlib.currentLanguage, value),
        },
        dbg: console.log,
        version,
        busy: true,
        seeking: true,
        evID: getPersistentEvID(),
        spotCB,
        externalDataLoaded: () => {},
        cleanup,
        loadTemplate,
        // obsolete; dummy function needed for legacy templates
        loadAssets: () => {},
        env: {
            isRunningOnZPlayer: false,
            isLocal: host.endsWith('.local'),
            isDev: host.startsWith('secure1-restored.zetadisplay')
                    || document.location.pathname.includes('transcodedev')
                    || (host.includes('contentcreator') && host.endsWith('.dev')),
            isContentPlatform: host.includes('contentcreator'),
            soc: false,
            online: navigator.onLine,
            zoomTo: (target, options) => {
                zoomTo(zdlib, target, options);
            }
        },
        exports: {
            mobx: {
                autorun, extendObservable, reaction, toJS,
            },
        },
    };
    Object.defineProperty(zdlib.env, 'urlParams', {
        get: () => {
            const search = {};
            const params = new URLSearchParams(window.location.search);
            Array.from(params.keys()).forEach((key) => {
                const param = params.get(key);
                search[key] = param === '' || param;
            });
            return search;
        },
    });
    zdlib.env.isTranscoding = zdlib.env.urlParams.Transcoder === '1';
    zdlib.env.isPreviewing = zdlib.env.urlParams.Preview === '1';
    zdlib.env.isInEditor = !zdlib.env.isTranscoding && !zdlib.env.isPreviewing;
    zdlib.timer = Timer(zdlib);
    zdlib.feedLoader = FeedLoader(zdlib);
    zdlib.assetLoader = AssetLoader(zdlib);
    const dataLoader = DataLoader(zdlib);
    zdlib.loadUrl = dataLoader.loadUrl;
    zdlib.timeline = Timeline(zdlib);
    zdlib.setTimeline = zdlib.timeline.setTimeline;
    zdlib.play = zdlib.timeline.play;
    zdlib.pause = zdlib.timeline.pause;
    zdlib.eventLoader = EventLoader(zdlib);

    extendObservable(
        zdlib,
        {
            pages: [],
            components: [],
            templateSize: { width: 1920, height: 1080 },
            get hasTimeline() {
                return zdlib.timeline.isEnabled() && zdlib.duration > 0 && zdlib.duration < 86399;
            },
            duration: 0,
            time: 0,
            playing: false,
            currentLanguage: 'en',
            responsive: false,
        },
        undefined,
        { name: 'zdlib' }
    );

    extendObservable(
        zdlib.env,
        {
            windowSize: { width: window.innerWidth, height: window.innerHeight },
            viewScale: 1,
            zoom: 1,
            pan: { x: 0.5, y: 0.5 },
            get viewArea() {
                return zdlib.editor
                    ? zdlib.editor.viewArea
                    : { left: 0, top: 0, width: zdlib.env.windowSize.width, height: zdlib.env.windowSize.height }
            },
            templateArea: null,
            previewSize: { width: 1920, height: 1080 },
        },
        undefined,
        { name: 'zdlib.env' }
    );
    setupArrays();

    zdlib.replaceData = async (data) => {
        disconnectAutoruns();
        await handleLoadedData(data);
        zdlib.dataLoaded(document.getElementById('zd_se_spot'));
        connectAutoruns();
    }

    function getPersistentEvID() {
        return `x${Date.now()}`;
    }

    const methods = [
        'dataLoaded',
        'editorLoaded',
        'beginEditing',
        'onResize',
        'endEditing',
        'onBeforeSave',
        'onSave',
        'onPageChange',
        'loadError',
        'onDurationChanged',
        'onSaveBegin',
        'onSaveEnd',
    ];

    async function loadTemplate(SpotTemplate, editor, editorIntf) {
        zdlib.busy = true;
        zdlib.seeking = true;
        zdlib.env.transcodeLog =
            zdlib.env.isTranscoding && new URLSearchParams(window.location.search).has('transcodeLog');
        zdlib.templateSize = { width: 1920, height: 1080 };
        spot = new SpotTemplate();
        if (!spot.dataLoaded) throw new Error('Template does not implement dataLoaded');
        zdlib.spot = { name: SpotTemplate.name };

        zdlib.timeline.setSpot(spot);

        const dummy = () => undefined;
        methods.forEach((name) => (zdlib[name] = spot[name] ? spot[name].bind(spot) : dummy));
        zdlib.valueChanged = dummy;
        if (zdlib.env.isTranscoding) {
            if (spot.makeThumb) zdlib.makeThumb = spot.makeThumb.bind(spot);
            console.log('-- zdlib version ', zdlib.version, '- transcoding');
        } else {
            if (zdlib.env.isPreviewing) console.log('-- zdlib version ', zdlib.version, '- previewing');
            window.addEventListener('resize', onResizeWindow);
        }

        zdlib.sid = zdlib.env.urlParams.mediaId || zdlib.env.urlParams.sid;
        let responseText;

        if (new URLSearchParams(window.location.search).has('testFailOnLoad')) throw new Error('testFailOnLoad');

        responseText = await loadEventServerData();
        const evData = responseText && zdlib.eventLoader.parseEventServerData(responseText);
        let isBlankTemplate = true;
        if (evData?.eventServerData === null || Object.keys(evData?.eventServerData || {}).length === 0) {
            console.log('No data from event server');
        } else if (evData?.eventServerData) {
            await handleLoadedData(evData.eventServerData, editor);
            isBlankTemplate = false;
        }
        try {
            zdlib.dataLoaded(document.getElementById('zd_se_spot'));
            // fill in localized default texts, if this a new template (from template library, not saved yet)
            // currently supported for textfields only
            if (isBlankTemplate) getLocalizedDefaults();
        } catch (err) {
            console.error(err.message);
        }

        connectAutoruns();
        if (spot.loadExternalData) {
            await new Promise((resolve, reject) => {
                const timeoutId = setTimeout(reject, 60000, new Error('loadExternalData is taking more than 60s'));
                zdlib.externalDataLoaded = (fail) => {
                    zdlib.externalDataLoaded = dummy;
                    clearTimeout(timeoutId);
                    if (fail) reject('loadExternalData failed');
                    else resolve();
                };
                spot.loadExternalData.call(spot);
            });
        }
        // feedLoader.isReady() returns number of data feeds loaded.
        // if > 0 , wait for extra 500ms in case feed includes images that need to be waited for too
        const feeds = await zdlib.feedLoader.isReady();
        if (feeds > 0) {
            await new Promise((resolve) => setTimeout(resolve, 500));
        }
        await zdlib.assetLoader.isReady();
        everythingLoaded();
        // Templates may have event listeners for e.g. image loading
        // In transcoder, allow listeners to run before returning (in editor it doesn't matter)
        // 500ms timeout may not be necessary, but just in case...
        if (zdlib.env.isTranscoding) await new Promise((resolve) => setTimeout(resolve, 500));
        if (spot.valueChanged) {
            zdlib.valueChanged = spot.valueChanged.bind(spot);
            zdlib.valueChanged();
        }
        console.log('%cTemplate loaded', 'background-color:green;color:white', zdlib.timer.log(undefined, true));
        return true;
    }

    function cleanup() {
        // engage templates declare cleanupExt
        zdlib.cleanupExt?.call(undefined);
        window.removeEventListener('resize', onResizeWindow);
        disconnectAutoruns();
        zdlib.components.clear();
        zdlib.pages.clear();
        methods.forEach((name) => delete zdlib[name]);
        delete zdlib.valueChanged;
        dataLoader.cancelAll();
        zdlib.eventLoader.cancelAll();
        zdlib.assetLoader.cancel();
        zdlib.feedLoader.cancel();
        zdlib.timeline.cleanup();
        zdlib.makeThumb = undefined;
        zdlib.setTimeline = zdlib.timeline.setTimeline;
        zdlib.externalDataLoaded = () => {};

    }



    function loadEventServerData() {
        spot?.beginLoad?.call(spot);
        if (!zdlib.sid) {
            console.log('** No sid = no data to load => use template defaults');
            return Promise.resolve();
        }
        return zdlib.eventLoader.getEvent({ eventPath: `${zdlib.sid}\\private\\template\\`, ID: zdlib.evID });
    }

    async function handleLoadedData(loadedData, editor) {
        if (loadedData?.components && Array.isArray(loadedData.components)) {
            console.log(
                'Data loaded: ',
                loadedData.pages?.length,
                'pages, ',
                loadedData.components.length,
                'components.'
            );
            if (zdlib.env.isTranscoding || zdlib.env.isPreviewing) zdlib.currentLanguage = loadedData.template?.language || 'en';
            loadedData.components.forEach((component) => {
                const value = spot.onParseValue?.call(spot, component);
                if (value !== undefined) {
                    component.value = value;
                } else if (
                    component.value &&
                    (component.ui?.name === 'SE_TimePicker' || component.ui?.name === 'SE_DatePicker')
                ) {
                    // remove timezone info from date/time values, IF they are not UTC
                    // with UTC, dates would be converted to local time, which could be different from the original on transcoder/player
                    // time was saved as UTC prior to 2024-01-10
                    component.value = new Date(Date.parse(component.value.replace(/ GMT.+$/i, '')));
                }
            });
            spot.onDataParsed?.call(spot, loadedData);
            if (editor) await editor.onDataLoaded(loadedData);
            if (loadedData.pages && Array.isArray(loadedData.pages)) zdlib.pages.replace(loadedData.pages);
            zdlib.components.replace(loadedData.components);
        } else {
            throw new Error('invalid event data');
        }
    }


    function everythingLoaded() {
        try {
            spot.everythingLoaded?.call(spot);
        } catch (err) {
            console.log('!!everythingLoaded: ',  err);
        }
        //if (zdlib.timeline.isEnabled()) zdlib.duration = zdlib.timeline.getDuration();
        /*if (!zdlib.hasTimeline)
            if (zdlib.timeline.isEnabled()) console.warn('Template implements playback methods, but duration is 0');
            else if (zdlib.duration > 0)
                console.warn(`Duration is ${zdlib.duration}, but template doesn't implement playback methods`);*/
        if (zdlib.hasTimeline) {
            if (zdlib.env.isTranscoding)
                zdlib.mode = zdlib.env.urlParams.transcodeThumbnailOnly ? 'thumb' : 'transcode';
        } else {
            console.log('## Template has no timeline');
            if (zdlib.env.isTranscoding) zdlib.mode = 'thumb';
        }
        if (!window.transcodeapi_templateLoaded) {
            zdlib.play(0);
        } else {
            console.log('Template loaded, seek to frame 0');
            zdlib.pause(0);
        }
        zdlib.busy = false;
        zdlib.seeking = false;
    }

    function setupArrays() {
        const exts = {
            getItem: function (id) {
                return this.find((item) => item.id === id);
            },
            indexOfItem: function (id) {
                return this.findIndex((item) => item.id === id);
            },
            replaceItem: function (itemOrId, onlyIfExists) {
                const id = itemOrId.id || itemOrId;
                if (typeof itemOrId === 'string') itemOrId = null;
                const n = this.indexOfItem(id);
                if (n < 0) {
                    if (!itemOrId || onlyIfExists) return null;
                    this.push(itemOrId);
                    if (itemOrId.log) console.log(`> Push: ${id} , value=${itemOrId.value}`);
                    return this[this.length - 1];
                }
                let A;
                if (itemOrId) {
                    if (reactions && reactions[id]) {
                        if (itemOrId.log) console.log(`> Dispose(): ${id}`);
                        reactions[id]();
                        delete reactions[id];
                    }
                    const keep = !itemOrId.disableSave;// && this[n].value !== undefined; // <= keep existing if if undefined!
                    if (itemOrId.log) {
                        console.log(`> Replace: ${id} , value=${keep ? `keep: ${this[n].value}` : `repl: ${this[n].value} => ${itemOrId.value}` }`)
                    }
                    if (keep) {
                        itemOrId.value = this[n].value;
                    }
                    itemOrId.customValue = this[n].customValue;
                    //A = this.splice(n, 1, itemOrId);
                    this[n] = itemOrId;
                    if (zdlib.editor) zdlib.editor.updateComponent(id);
                    return itemOrId;
                } else {
                    A = this.splice(n, 1);
                }
                return A?.length === 1 ? A[0] : null;
            },
            copyItem: function (id) {
                const item = this.getItem(id);
                return item && toJS(item);
            },
            getItemByElementId: function (elementId, typeName) {
                if (!elementId) return null;
                return this.find((comp) => {
                    return comp.element?.id === elementId && (!typeName || comp.ui?.name === typeName);
                });
            },
        };

        for (const func in exts) {
            zdlib.components[func] = exts[func].bind(zdlib.components);
            zdlib.pages[func] = exts[func].bind(zdlib.pages);
        }
        preventDuplicates(zdlib.pages);
        preventDuplicates(zdlib.components);
    }

    function connectAutoruns() {
        if (autoRuns) return;
        reactions = {};
        autoRuns = [
            /*preventDuplicates(zdlib.pages),
            preventDuplicates(zdlib.components),*/
            setupReactions(),
            autorun(autoResize),
            reaction(
                () => zdlib.duration,
                (value, prevValue) => {
                    durationChanged();
                }
            ),
            reaction(
                () => zdlib.currentLanguage,
                (value, prevValue) => {
                    languageChanged(prevValue);
                }
            ),
        ];
    }

    function disconnectAutoruns() {
        if (!autoRuns) return;
        autoRuns.forEach((disposer) => {
            disposer();
        });
        Object.values(reactions).forEach((disposer) => {
            disposer();
        });
        reactions = {};
        autoRuns = null;
    }

    function preventDuplicates(arr) {
        return reaction(
            () => arr.length,
            () => {
                const unique = arr.filter((item, i) => item.id && arr.findIndex((obj) => obj.id === item.id) === i);
                if (unique.length !== arr.length) {
                    arr.replace(unique);
                }
            },
            { fireImmediately: true }
        );
    }

    function setupReactions() {
        return autorun(() => {
                zdlib.components.forEach((component) => {createComponentReaction(component)});
                Object.keys(reactions).forEach((key) => {
                    if (!zdlib.components.find((component) => component.id === key)) {
                        if (reactions[key]) {
                            spot?.onComponentRemoved?.call(spot, key);
                            reactions[key]();
                        }
                        delete reactions[key];
                    }
                });
            });
    }


    function createComponentReaction(component) {
            if (!component.id) {
                console.warn('Component.id not defined');
                return;
            }
            if (reactions[component.id]) return;

            if (component.log) console.log(`> add reaction: ${component.id}`);


            if (component.ui?.name === 'SE_Image') {
                if (typeof component.value === 'string') {
                    try {
                        if (component.log) console.log(`> Image value is string - parse: ${component.id}`);
                        component.value = JSON.parse(component.value);
                    } catch (err) {
                        if (component.log) console.log(`> Image value parse failed, set to: ${component.value} : ${component.id}`);
                        component.value = {url: component.value};
                    }
                }
            }
            if (component.ui && !Object.prototype.hasOwnProperty.call(component.ui, 'info'))
                component.ui.info = null;

            const componentValueChanged = () => {
                if (component.log) console.log(`> componentValueChanged: ${component.id}${component.onChange 
                                                    ? `${component.event ? ' ev' : ''} change(${component.value})` 
                                                    : ` -no_change-(${component.value})`}`);
                if (component.onChange) {
                    const change =
                        typeof component.onChange === 'string'
                            ? spot[component.onChange]
                            : component.onChange;
                    if (zdlib.env.isLocal) {
                        //console.log(component.onChange);
                        if (!change || !(change instanceof Function))
                            console.warn(
                                `${component.id}.onChange ${
                                    typeof component.onChange === 'string' ? `"${component.onChange}"` : ''
                                } is not a function`
                            );
                        change.call(spot, component);
                    } else {
                        try {
                            if (!change || !(change instanceof Function))
                                throw new Error(`onChange callback ${component.onChange} is undefined`);
                            change.call(spot, component);
                        } catch (err) {
                            if (!zdlib.env.isTranscoding) console.error(err.message);
                            console.log(
                                `!! Error in ${component.id}.onChange callback ${
                                    typeof component.onChange === 'string' ? component.onChange : 'function'
                                }(${component.value}${
                                    component.value !== null && component.value !== undefined
                                        ? `:${typeof component.value}`
                                        : ''
                                }) ${zdlib.env.isTranscoding ? `-> ${err.message}` : ''}`
                            );
                        }
                    }
                }
                if (component.event) component.event = undefined;
                // image/video autoload
                if (
                    component.ui?.name === 'SE_Image' &&
                    !component.disableAutoLoad &&
                    component.element?.id
                ) {
                    const srcUrl = getAssetSrcUrl(component) || '';
                    //console.log(srcUrl);
                    const imageEl = document.getElementById(component.element.id);
                    if (!imageEl) {
                        if (srcUrl || component.log)
                            console.log(
                                `Autoload "${srcUrl}" failed: DOM element ${component.id} @ ${component.element.id} not found`
                            );
                    }
                    else {
                        //if (srcUrl) console.log(`..auto-load ${imageEl.tagName.toLowerCase()}.src="${srcUrl}" @ ${component.id}`);
                        imageEl.setAttribute('data-component-id', component.id);
                        imageEl.setAttribute('src', srcUrl || '');
                    }
                }
                zdlib.valueChanged();
            };

        reactions[component.id] =
            component.ui?.name === 'SE_List'
            || component.mutableValue
                ? autorun(componentValueChanged, {
                    name: `autorun->${component.id}`,
                    delay: 50,
                })
                : reaction(() => component.value, componentValueChanged, {
                    fireImmediately: true,
                    name: `reaction->${component.id}`,
                    delay: 50,
                });
    }




    function spotCB(component, callbackName, query) {
        const args = !query && [component].concat(Array.prototype.slice.call(arguments).slice(3));
        const isEvent = (ev) => ev && ev.type && ev.target;
        const event = args && args.length === 2 && isEvent(args[args.length - 1]) && args.pop();

        let callback = component && component[callbackName];
        if (!callback || typeof callback === 'string') callback = spot[callback];
        if (query) return !!callback;
        else if (!callback) return undefined;
        return event ? callback.call(spot, { ...toJS(component), ...{ event } }) : callback.apply(spot, args);
    }

    function onResizeWindow() {
        zdlib.env.windowSize = { width: window.innerWidth, height: window.innerHeight };
    }

   

    

    function autoResize() {
        const {scaling, x, y, hMax, viewScale} = getTransform(zdlib, zdlib.env.zoom, zdlib.env.pan);
        const template = document.getElementById('zd_se_spot');
        template.style.transform = `matrix(${scaling}, 0, 0, ${scaling}, ${x}, ${y})`
        const viewArea = zdlib.env.viewArea;
        const rect = template.getBoundingClientRect();
        zdlib.env.templateArea = {
            left: Math.max(rect.x, viewArea.left),
            top: Math.max(rect.y, viewArea.top),
            width: hMax
                    ? zdlib.env.zoom < 1 ? Math.min(viewArea.width, rect.width) : viewArea.width
                    : Math.min(viewArea.width, rect.width),
            height: hMax
                    ? Math.min(viewArea.height, rect.height)
                    : zdlib.env.zoom < 1 ? Math.min(rect.height, viewArea.height) : viewArea.height,
        };
        zdlib.env.viewScale = viewScale;
        zdlib.onResize();
    }



    function durationChanged() {
        if (!zdlib.hasTimeline) zdlib.time = 0;
        zdlib.onDurationChanged();
    }

    function languageChanged(previousLanguage) {
        if (spot.onLanguageChanged) {
            spot.onLanguageChanged.call(spot, previousLanguage, zdlib.currentLanguage);
        }
    }

    function getLocalizedDefaults() {
        zdlib.components.forEach((comp) => {
            if (comp.defaultValue && comp.ui?.name === 'SE_TextField')
                comp.value = zdlib.utils.getLocalizedString(comp.defaultValue);
            //delete comp.defaultValue;
        });
    }

    return zdlib;
};
