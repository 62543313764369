import { Theme } from '@zetadisplay/zeta-ui-components';
import React from 'react';

export default function EditorTheme({ editorIntf, dark, children, nested = true }) {
    return (
        <Theme
            dark={dark}
            nested={nested}
            override={(theme) => ({
                ...theme,
                ...{
                    components: {
                        MuiDialogTitle: {
                            styleOverrides: {
                                root: {
                                    padding: '38px 25px 10px 32px',
                                },
                            },
                        },
                        MuiDialogContent: {
                            styleOverrides: {
                                root: {
                                    padding: '24px 0 52px',
                                    margin: '0 32px',
                                },
                            },
                        },
                        MuiDialogActions: {
                            styleOverrides: {
                                root: {
                                    marginBottom: '8px',
                                },
                            },
                        },
                        MuiButton: {
                            styleOverrides: {
                                label: {
                                    alignItems: 'normal',
                                },
                            },
                        },
                    },
                },
                ...editorIntf.layoutConfig,
            })}
        >
            {children}
        </Theme>
    );
}
