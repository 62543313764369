import React from 'react';
import Icon from '@mui/material/Icon';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { Button } from '@zetadisplay/zeta-ui-components';
import { observer } from 'mobx-react';

import { getLocalizedString } from '../localization/localizationUtils';
import HTMLParser from "../UIComponents/HTMLParser.jsx";
import { changeValue } from './SE__Base';

const SE_Dropdown = observer((props) => {
    const { spotData, events, forceOnChangeCallback, zdlib } = props;
    const uiProps = { ...spotData.ui, ...(spotData.ui.props || {}) };
    const { label, disabled, icon, hideSelection } = uiProps;
    const items = spotData.ui.items || spotData.items;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const btnClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleRequestClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event, newValue) => {
        //console.log(spotData.value , '-->', newValue)
        changeValue(event, spotData, newValue, forceOnChangeCallback);
        setAnchorEl(null);
    };

    const getMenuItem = (item, index) => {
        const label = getLocalizedString(zdlib.currentLanguage, item.label || item.value || item);
        if (!label) return null;
        const uiProps = { ...spotData.ui, ...(spotData.ui.props || {}) };

        return (
            <MenuItem
                key={'menu_' + index}
                style={{ minWidth: 120 }}
                disabled={item.disabled}
                selected={!uiProps.hideSelection && item.value === spotData.value}
                divider={item.divider}
                onClick={(ev) => {
                    handleChange(ev, item.value || item);
                }}
            >
                {item.icon && <Icon style={{ marginRight: 8, opacity: item.disabled ? 0.5 : 1 }}>{item.icon}</Icon>}
                {HTMLParser(label)}
            </MenuItem>
        );
    };

    if (!items) {
        console.warn(`Dropdown component id="${spotData.id}" doesn't have any items: nothing to show`);
        return null;
    }

    let itemLabel;

    let itemIcon;

    if (!hideSelection) {
        const item = items.find((item) => (item.value || item) === spotData.value);
        if (item) {
            itemIcon = item.icon;
            itemLabel = item.label || item.value || item;
        }
    }

    return (
        <>
            <Button disabled={disabled} {...events} onClick={btnClick}>
                {itemIcon || icon ? (
                    <Icon style={{ marginRight: 8,  position: 'relative' }}>{itemIcon || icon}</Icon>
                ) : null}
                {HTMLParser(getLocalizedString(zdlib.currentLanguage, itemLabel || label))}

                <Icon style={{ marginLeft: 8, top: 1, position: 'relative' }}>{'expand_more'}</Icon>
            </Button>

            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                PaperProps={{ style: { borderRadius: 0 } }}
                onClose={handleRequestClose}
            >
                {items && items.map(getMenuItem)}
            </Popover>
        </>
    );
});

export default SE_Dropdown;
