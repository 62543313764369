import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css';
import App from './Layout/App'


ReactDOM.createRoot(document.getElementById('zd_se')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
