import React from 'react'; 
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { observer } from 'mobx-react';

import {RTEditorLazy} from "./EditorControls/RTE/RTEditorLazy";
import { changeValue } from './SE__Base';

// TODO: add multi-language support
const useStyles = makeStyles()((theme) => ({
    editor: {
        fontSize: 14,
        marginTop: 2,
        marginBottom: 8,
        textTransform: 'none',
        borderStyle: 'solid',
        borderColor: theme.palette.text.label,
        borderWidth: 1,
        paddingLeft: 16,
        paddingRight: 12,
        paddingTop: 12,
        paddingBottom: 16,
        color: '#FFF',
        '&:focus': {
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
            paddingLeft: 15,
            paddingTop: 11,
            paddingBottom: 15,
            paddingRight: 11,
        },
    },
    iconButton: {
        marginLeft: -12,
        marginRight: 2,
        '&$buttonDisabled': {
            color: theme.palette.text.label,
        },
        '&:hover': {
            color: '#F39200'
        },
    },
    iconButtonInactive: {
        color: '#FFF',
        '&:hover': {
            color: '#FFF',
        },
    },
    buttonDisabled: {},
}));

const SE_RichText = observer((props) => {
    const { classes } = useStyles();
    const { spotData, events, forceOnChangeCallback, zdlib, editorIntf } = props;
    const { placeholder} = spotData.ui;

    const handleChange = (newValue) => {
        changeValue({ type: 'RichTextChange' }, spotData, newValue, forceOnChangeCallback);
    };

    const handleStateChange = (newState) => {
        spotData.editorState = newState;
        try {
            props.zdlib.spotCB(spotData, 'onEditorStateChange');
        } finally {
            delete spotData.editorState;
        }
    };

    return (
        <div className={'SE_RichTextEditorContainer'}>
            <React.Suspense fallback={<div style={{ color: 'gray' }}>Loading...</div>}>
                <RTEditorLazy
                    classes={classes}
                    placeholder={placeholder}
                    zdlib={zdlib}
                    editorIntf={editorIntf}
                    onChange={handleChange}
                    spotData={spotData}
                    options={spotData.ui}
                    onEditorStateChanged={handleStateChange}
                    events={events}
                    tHook={props.tHook}
                />
            </React.Suspense>
        </div>
    );

    /* return <RTEditor
                classes={classes}
                placeholder={placeholder}
                zdlib={zdlib}
                htmlText={spotData.value}  
                onChange={handleChange}
                spotData={spotData}
                onEditorStateChanged={handleStateChange}
                events={events}
                tHook={props.tHook}
                {...rest}
                />*/
});

export default SE_RichText;
