import React from 'react';
import { CircularProgress } from '@mui/material';
import { colors } from '@zetadisplay/zeta-ui-components/utils/theme/theme-options';
import { observer } from 'mobx-react';

import { getLocalizedString } from '../localization/localizationUtils';
import HTMLParser from '../UIComponents/HTMLParser';
import { InfoButton } from '../UIComponents/InfoButton';
import { components } from './components';
import Popup from './EditorControls/Popup';


export const eventNames = [
    'onCopy',
    'onCut',
    'onPaste',
    'onKeyDown',
    'onKeyPress',
    'onKeyUp',
    'onFocus',
    'onBlur',
    'onClick',
    'onDoubleClick',
    'onMouseOver',
    'onMouseOut',
    'onMouseDown',
    'onMouseUp',
    'onTouchCancel',
    'onTouchEnd',
    'onTouchMove',
    'onTouchStart',
    'onScroll',
    'onWheel',
    'onSelect',
];



const styleToObj = (style) => {
    const ret = {};
    const camelCased = key => key.replace(/-([a-z])/g, s => s[1].toUpperCase());
    if (style) for (const key in style) {
        const value = `${style[key]}`;
        if (isNaN(Number(key)) && value !== '') {
            let camel = key.startsWith('--') ? key : camelCased(key);
            if (camel !== 'cssText') {
                if (camel.startsWith('webkit') || camel.startsWith('moz')) {
                    camel = `${camel[0].toUpperCase()}${camel.substring(1)}`
                }
                ret[camel] = value;
            }
        }
    }
    return ret;
}

export function changeValue(event, spotData, value, forceOnChangeCallback) {
    if (event && event.persist) event.persist();
    spotData.event = event;
    //spotData.value = typeof value === 'string' ? DOMPurify.sanitize(value) : value;
    spotData.value = value;
    if (forceOnChangeCallback && spotData.onChange) spotData.onChange(spotData);
    //delete spotData.event;
}

function evHandler(props, eventName, event) {
    if (!props) {
        console.log('%%%%%%%%%%%%% evHandler!', eventName);
        return;
    }
    const { spotData, zdlib } = props;
    event.persist?.call(undefined);

    spotData.event = event;
    zdlib.spotCB(spotData, eventName);
}

const SE_Base = observer(props => {

    const { spotData, zdlib, editorIntf } = props;
    const {/* events,*/ theme, ...other } = props;
    const ui = spotData.ui;
    
    const [editorComponent, setEditorComponent] = React.useState(null);
    const [popupPos, setPopupPos] = React.useState(null);
    const [updateKey, setUpdateKey] = React.useState(0);
    

    const updateComponent = React.useCallback(() => {
        if (!props?.spotData) return;
        setUpdateKey(updateKey + 1)
    }, [props.spotData, updateKey]);

    
    const closeInline = React.useCallback(() => {
        editorIntf.updatePopup(spotData.popup.id || spotData.popup.popupId);
        setPopupPos(null);
        if (spotData.onPopupClosed) spotData.onPopupClosed(spotData);
    }, [spotData, editorIntf]);

    
    React.useEffect(() => {        
        const Component = components[ui.name || 'SE_HTML'];
        if (!Component) {
            if (ui.name) console.warn('Unknown component:', ui.name);
            setEditorComponent(null);
            return;
        }
        const eventHandlers = {};
        const spotCB = zdlib.spotCB;
        const spotEvents = eventNames.filter((evName) => {
            // call spotCB with param query=true. If spot implements the (event handler) callback, spotCB returns true
            return spotCB(spotData, evName, true);
        });
        spotEvents.forEach((evName) => {
            eventHandlers[evName] = evHandler.bind(undefined, props, evName);
        });    

        if (spotData.popup?.popupId || spotData.popup?.id) {
            const spotClick = eventHandlers.onClick;
            eventHandlers.onClick = (event) => {
                // if (props.popup) = popup has been spread to component.props in  getPopupControls()
                // --> the click comes from control that is already on an inline popup
                // --> keep it inline
                if (!props.popup && !spotData.popup.inline) {
                    zdlib.editor.showSubpage({
                        ...spotData.popup,
                        ...{ id: spotData.popup.id || spotData.popup.popupId },
                    });
                    if (spotData.onPopupOpened) spotData.onPopupOpened(spotData);
                    if (spotClick) spotClick(event);
                    return;
                }
                const rect = event.currentTarget.getBoundingClientRect();
                editorIntf.updatePopup(spotData.popup.id || spotData.popup.popupId, closeInline);
                setTimeout(setPopupPos, 50, { left: rect.left, top: rect.bottom, duration: 'auto' });
                if (spotClick) spotClick(event);
                if (spotData.onPopupOpened) spotData.onPopupOpened(spotData);
            };
        }

        const editorComponent = React.createElement(Component, {
            ...{ ...props, ...{ events: eventHandlers } },
            getPopupState: () => {
                return popupPos;
            },
        });
        setEditorComponent(editorComponent);
        zdlib.editor.registerControlUpdater(spotData.id, updateComponent);

        return () => {
            zdlib.editor.registerControlUpdater(spotData.id);
        };
    }, [editorIntf, closeInline, popupPos, props, props.spotData, spotData, ui.name, updateComponent, zdlib.editor, zdlib.spotCB]);


    



    

    const renderHeader = React.useCallback((header, large) => {
        if (!header) return null;
        const htmlParams = {data: spotData};
        if (header.label === undefined) return HTMLParser(getLocalizedString(zdlib.currentLanguage, header), htmlParams);
        return (
            <>
                {HTMLParser(getLocalizedString(zdlib.currentLanguage, header.label), htmlParams)}

                {header.progress && (
                    <CircularProgress
                        color="inherit"
                        classes={{ root: 'zdheaderProgress' }}
                        size={large ? 17 : 13}
                        thickness={large ? 4.2 : 3.6}
                    />
                )}

                {header.info && <InfoButton zdlib={zdlib} info={{...header.info, ...{htmlParams}}} large={large}/>}
            </>
        );
    }, [spotData, zdlib]);

    


        const divStyle = !ui.classes
            ? {
            ...{
                margin: '0px 6px 32px 0px',
                marginBottom: spotData.ui && (spotData.ui.name === 'SE_HTML' || !spotData.ui.name) ? '2px' : '32px',
                display: 'inline-block',
                width: '100%',
                fontFamily: 'Sarabun, sans-serif',
                fontSize: 12,
                lineHeight: '18px',
                letterSpacing: 0.47,
                color: colors.MEDIUMGRAY, // '#999'
                },
            ...ui.divStyle || {}
            }
            : undefined;

        const legacyStyling = !ui.classes && !ui.styles && ui.divStyle;

        const uiClasses = ui.classes
            ? typeof ui.classes === 'function' ? ui.classes(spotData) : ui.classes
            : undefined;

        const defaultClasses = legacyStyling
            ? undefined
            : {
                root: 'zd-ui-root',
                largeHeader: 'zd-ui-large-header',
                header: 'zd-ui-header',
                control: 'zd-ui-control',
                footer: 'zd-ui-footer',
                //highlight: 'zd-ui-highlight', // not used here, but directly added to classList when needed
            }

        const uiStyles = ui.styles
            ? typeof ui.styles === 'function' ? ui.styles(spotData) : ui.styles
            : undefined;


        const getStyleRefFunction = style => {
            return style
                ? ref => {
                    if (ref) {
                        if (typeof style === 'string') {
                            ref.setAttribute('style', style);
                        } else setTimeout(() => {
                            const obj = styleToObj(style , true);
                            const str = Object.keys(obj).map(key => `${key}:${obj[key]}`).join(';');
                            ref.setAttribute('style', str);
                        })
                    }
                }
                : undefined;
        }



        return (
                <div style={legacyStyling ? divStyle : undefined}
                     key={updateKey}
                     className={`${defaultClasses?.root || ''} ${uiClasses?.root || ''}`}
                     ref={getStyleRefFunction(uiStyles?.root)}
                     data-component-id={spotData.id}
                     data-ui-element={'root'}
                >
                    {
                        ui.largeHeader &&
                        <div style={legacyStyling ?
                                {
                                color: theme.palette.text.primary,
                                fontSize: 14,
                                marginBottom: 16,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                marginTop: 16,
                                }
                                : undefined
                            }
                             className={`${defaultClasses?.largeHeader || ''} ${uiClasses?.largeHeader || ''}`}
                             data-ui-element={'largeHeader'}
                             ref={getStyleRefFunction(uiStyles?.largeHeader)}
                        >
                            {renderHeader(ui.largeHeader, true)}
                        </div>
                    }
                    {
                        ui.header &&
                        <div className={`${defaultClasses?.header || ''} ${uiClasses?.header || ''}`}
                             data-ui-element={'header'}
                             ref={getStyleRefFunction(uiStyles?.header)}
                            >
                            {renderHeader(ui.header, false)}
                        </div>
                    }

                    <div style={legacyStyling ? { fontWeight: 400, opacity: 1 } : undefined}
                         ref={getStyleRefFunction(uiStyles?.control)}
                         className={`${defaultClasses?.control || ''} ${uiClasses?.control || ''}`}
                         data-ui-element={'control'}
                        >
                        {editorComponent}

                        {ui.info && ui.info.hideButton && <InfoButton info={ui.info} zdlib={zdlib} />}

                        {popupPos && (
                            <Popup
                                anchorPos={{ left: popupPos.left, top: popupPos.top }}
                                popupId={spotData.popup.id || spotData.popup.popupId}
                                transitionDuration={popupPos.duration}
                                close={closeInline}
                                ownerProps={props}
                                theme={theme}
                                {...other}
                            />
                        )}
                    </div>

                    {
                        ui.footer &&
                        <div style={legacyStyling ? { marginTop: '2px', textTransform: 'none' } : undefined}
                             className={`${defaultClasses?.footer || ''} ${uiClasses?.footer || ''}`}
                             ref={getStyleRefFunction(uiStyles?.footer)}
                             data-ui-element={'footer'}
                            >
                            {renderHeader(ui.footer, false)}
                        </div>
                    }
                </div>
        );
});

export default SE_Base;
