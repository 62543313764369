import React from "react";
import {useOidcAccessToken} from "@axa-fr/react-oidc";
import {Error} from "@mui/icons-material";
import {CircularProgress} from "@mui/material";
import type {LibraryModelsMedia, LibraryModelsTemplateRelations} from "@zetadisplay/engage-api-client";
import {LibraryModelsTemplateTypesEnum} from "@zetadisplay/engage-api-client/models/library-models-template-types-enum";
import {useWorkspace} from "@zetadisplay/engage-components/modules/workspaces";

import apiConfig from "../config/apiConfig";
import {DEFAULT_OIDC_CONFIGURATION_NAME} from "../config/oidcConfiguration";
import {getGauddiImportTemplateId} from "../templateContext/getGauddiImportTemplateId";



interface LivePreviewProps {
    zdlib: {currentLanguage: string};
    selectedItem?: LibraryModelsMedia | LibraryModelsTemplateRelations;
    size?: number;
}

export const LivePreview = ({zdlib,
                             selectedItem,
                             size = 160,
                             }: LivePreviewProps) => {
    const iframeRef = React.useRef<HTMLIFrameElement>(null);
    const {workspace} = useWorkspace();
    const { accessToken } = useOidcAccessToken(DEFAULT_OIDC_CONFIGURATION_NAME);

    const [error, setError] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);


    const {sourcePath, templateId, mediaId} = React.useMemo(() => {
        //console.log('%c---------------------\n', 'background-color: pink', selectedItem)
        // ...no idea which of the zillion types this is... => cast to any & ignore TS warnings
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const item = selectedItem as any;
        let sourcePath = '';
        let templateId = '';
        let mediaId = '';
        if (item.discriminatorType === 'MediaFile') {
            sourcePath = item.template?.sourcePath
            if (!sourcePath && item.template?.type === LibraryModelsTemplateTypesEnum.Html) sourcePath = getGauddiImportTemplateId();
            templateId = item.templateId;
            mediaId = item.id;
        } else if (item.discriminatorType === 'Template') {
            sourcePath = item.sourcePath;
            if (!sourcePath && item.type === LibraryModelsTemplateTypesEnum.Html) sourcePath = getGauddiImportTemplateId();
            templateId = item.id;
        }
        //console.log('%c---------------------\n', 'background-color: lime', sourcePath, templateId, mediaId)
        return {sourcePath, templateId, mediaId}
    }, [selectedItem])


    React.useEffect(() => {
        if (!sourcePath && !templateId) {
            const err = 'No sourcePath or template id';
            console.log(err)
            console.log(selectedItem)
            setError(err);
        } else setError('')

    }, [selectedItem, sourcePath, templateId])





    const handleMessage = React.useCallback((ev: MessageEvent) => {
        if (ev.origin.match(/zetadisplay\.(com|dev|local)$/i) && ev.data?.evId === 'preview') {
            const {msg, details} = ev.data;
           /* let ratio = 1;
            let width = 1;
            let height = 1;*/
            switch (msg) {
                case 'templateLoaded': {
                    const ratio = details.templateWidth / details.templateHeight;
                    const width = ratio >= 1 ? size : size * ratio;
                    const height = ratio >= 1 ? size / ratio : size;
                    iframeRef.current!.width = `${width}`;
                    iframeRef.current!.height = `${height}`;
                    break;
                }
                case 'templateLoadError':
                    setError(details.message);
                    break;
            }
            setLoading(false)
        }
    }, [size])

    React.useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        }
    }, [handleMessage]);


    const previewUrl = React.useMemo<string>(() => {
        const params = new URLSearchParams(window.location.search);
        return `/preview/?sourcePath=${sourcePath
                }&workspace=${workspace.id
                }&templateId=${templateId
                }&language=${zdlib.currentLanguage
                }&apiBaseUrl=${apiConfig.BASE_URL
                }&apiVersion=${apiConfig.API_VERSION
                }&token=${accessToken
                }${mediaId ? `&mediaId=${mediaId}` : ''}${
                    params.has('debugAssets') ? '&debugAssets' : ''}${params.has('enable-log') ? '&enable-log' : ''}`
    }, [sourcePath, workspace.id, templateId, zdlib.currentLanguage, accessToken, mediaId]);

    React.useEffect(() => {
        setLoading(!!previewUrl);
        setError('');
    }, [previewUrl])

    return <>
            { error
                ? <span style={{fontSize: '0.9em', display: 'flex', alignItems: 'center'}}>
                        <ErrorIcon/>
                        {'Cannot open template'}
                  </span>
                : <>
                    {
                    loading
                    && <div>
                        <CircularProgress
                            color={'primary'}
                            size={16}
                            thickness={4}
                        />
                    </div>
                    }
                    {
                    previewUrl
                    && <div className={'template-preview-container'} style={{visibility: loading ? 'hidden' : 'visible', height: loading ? 0 : 'fit-content'}}>
                            <iframe src={previewUrl}
                                    allow={'autoplay'}
                                    ref={iframeRef}
                                    width={size}
                                    height={size / 1.777}
                                    onLoad={() => {
                                        if (iframeRef.current) {
                                            iframeRef.current.contentWindow?.postMessage('preview', document.location.origin);
                                        }
                                    }}
                            />
                        </div>
                    }
                  </>
                }
    </>
}

const ErrorIcon = () => (
    <Error htmlColor={'#DC0000'} titleAccess={`ERROR`} style={{marginRight: 4}}/>
);