import React from 'react';
import {Tab, Tabs, Typography} from '@mui/material';
import type {
    LibraryModelsFolder,
    LibraryModelsMedia,
    LibraryModelsTemplateRelations
} from '@zetadisplay/engage-api-client';
import {
    LibraryModelsMediaTypes
} from '@zetadisplay/engage-api-client';
import type {LibraryItemType} from "@zetadisplay/engage-components/modules/library";
import {FilterFields} from "@zetadisplay/engage-components/modules/search";
import { useTranslation } from '@zetadisplay/zeta-localization';
import {Button} from "@zetadisplay/zeta-ui-components";

import { Use_mq_MobileWidth } from '../Layout/mediaQueries';
import {LivePreview} from "../templatePreview/LivePreview";
import DebugFileSelect from './DebugFileSelect';
import FileDialog from "./FileDialog";
import {FileDialogSidekick} from "./FileDialogSidekick.tsx";
import LibraryView from './LibraryView';
import TemplateLibraryView from "./TemplateLibraryView";


const OpenDialog = (props) => {
    const { options, editorIntf, zdlib } = props;
    const mobile = Use_mq_MobileWidth();

    const [selectedItem, setSelectedItem] = React.useState<LibraryModelsMedia | LibraryModelsTemplateRelations  | undefined>();

    const [openFrom, setOpenFrom] = React.useState<'media' | 'templates'>('media');

    const close = (ev?: object, reason?:  "backdropClick" | "escapeKeyDown") => {
        if (reason !== 'backdropClick' && options.close) options.close();
    };

    const handleTabChange = (event: React.SyntheticEvent<Element, Event> | null, newValue: string | number | null) => {
        setSelectedItem(undefined);
        setOpenFrom(newValue as 'media' | 'templates');
    };

    const isSelectable = (item: LibraryItemType)  => {
       return [LibraryModelsMediaTypes.TemplateZetaCast, LibraryModelsMediaTypes.TemplateHtml].includes((item as LibraryModelsMedia).type)
    }



    const tHook = useTranslation();

    const handleFolderChange = (toFolder?: LibraryModelsFolder) => {
            if (toFolder?.id) {
               setSelectedItem(undefined);
            }
    };


    const handleSelect = (items: Record<string, LibraryItemType>): void => {
            if (Object.keys(items).length === 0) {
                return;
            }
            const item = Object.values(items)[0];
            setSelectedItem(item as LibraryModelsMedia);
    };

    function onTemplateSelected(template: LibraryModelsTemplateRelations) {        
        setSelectedItem(template);
    }


    const getDialogResult = () => {
        return {
            ...{
                templateId: editorIntf.mediaItem?.templateId,
            },
            ...(selectedItem || {}),
        }
    }



    const renderContent = () => {
        return <>

            <Tabs value={openFrom} onChange={handleTabChange} sx={{marginBottom: '16px', marginLeft: '-15px', marginTop: '16px'}} variant={mobile ? 'fullWidth' : 'standard'}>
                <Tab value={'templates'} label={'Template library'} wrapped sx={{fontWeight: 600, fontSize: '14px'}}/>
                <Tab value={'media'} label={'Media library'} wrapped sx={{fontWeight: 600, fontSize: '14px'}}/>
                {zdlib.env.isLocal && (
                    <DebugFileSelect editorIntf={editorIntf} onSelect={close}/>
                )}
            </Tabs>
            <div className={'file-list-container'}>
                { openFrom === 'templates' &&
                    <TemplateLibraryView onTemplateSelected={onTemplateSelected}
                    />
                }
                { openFrom === 'media' &&
                    <LibraryView
                        contentFilters={{[FilterFields.MEDIA_TYPE]: [LibraryModelsMediaTypes.TemplateZetaCast, LibraryModelsMediaTypes.TemplateHtml]}}
                        selectable={isSelectable}
                        onSelectItemCallback={handleSelect}
                        onFolderChangeCallback={handleFolderChange}
                    />
                }
                <FileDialogSidekick zdlib={zdlib}
                                    rootClass={`file-dialog-sidekick ${openFrom === 'templates' ? 'narrow-header' : 'wide-header'}`}
                                    selectedItem={selectedItem}
                    />
            </div>
        </>
    }

    return <FileDialog
                renderContent={renderContent}
                title={tHook.trans('common.action.open_file')}
                getDialogResult={getDialogResult}
                disabled={
                    !selectedItem || selectedItem.id === editorIntf.mediaItem?.id
                }
                okAction={tHook.trans('common.action.open')}
                footerHeight={'80px'}
                {...props}
            />;

};

export default OpenDialog;
