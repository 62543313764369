
const debug = new URLSearchParams(window.location.search).has('debugAssets') || window.transcodeapi_init;

export const FeedLoader = (zdlib) => {
    let map, resolver;
    const checkDone = () => {
        if (resolver) {
            const status = {loading: 0, complete: 0, error: 0};
            map.forEach((value) => {
                status[value] = status[value] + 1
            });
            if (debug && status.loading === 0) console.log(`%c${status.complete + status.error} / ${status.complete + status.error} feeds loaded`, 'color:cyan;background-color:blue')
            if (status.loading === 0) resolver(status.complete);
        }
    }

    return {
        begin: (editorIntf) => {
            if (debug) console.log(`%cBEGIN FEEDS`, 'color: blue; background-color: cyan');
            map = new Map();
            resolver = undefined;
        },
        onClear: (feedFileName) => {
            if (map?.has(feedFileName)) {
                map.delete(feedFileName);
            }
        },
        onLoading: (feedFileName) => {
            if (debug) console.log(`%cLOADING: ${feedFileName}`, 'color: yellow; background-color: green')
            if (map && !map.has(feedFileName)) {
                map.set(feedFileName, 'loading');
            }
        },
        onLoaded: (feedFileName) => {
            if (debug) console.log(`%cLOADED:  ${feedFileName}`, 'color: blue; background-color: cyan');
            if (map) {
                map.set(feedFileName, 'complete');
                checkDone();
            }
        },
        onError: (feedFileName) => {
            if (map) {
                map.set(feedFileName, 'error');
                checkDone();
            }
        },
        cancel: () => {
            if (map) {
                if (resolver) resolver();
                else {
                    map = undefined;
                }
            }
        },
        isReady: () => {
            if (!map) return Promise.resolve();
            const promise = new Promise((resolve, reject) => {
                const timeoutId = setTimeout(reject, 10000, new Error('Loading feeds is taking more than 10s'));
                resolver = (loadedCount) => {
                    clearTimeout(timeoutId);
                    map = undefined;
                    resolver = undefined;
                    resolve(loadedCount);
                }
            });
            checkDone();
            return promise;
        }
    }
};