import React, { useCallback } from 'react';
import { PreviewDialog} from '@zetadisplay/engage-components';
import { isMediaFolder} from '@zetadisplay/engage-components/models';
import type { LibraryItemType } from '@zetadisplay/engage-components/modules/library';
import { LibraryWidget } from '@zetadisplay/engage-components/modules/library/components';
import type {
    LibraryWidgetProps
} from "@zetadisplay/engage-components/modules/library/components/library-widget/library-widget";
import { getPreviewUrl, isPreviewable } from '@zetadisplay/engage-components/utils/media';
import type { SearchFilters, SearchState} from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { processEnv } from "../config/processEnv";

const useStyles = makeStyles()(() => ({
    spacing: {
        marginTop: 18,
    },
    emptyLabel: {
        display: 'grid',
        justifyItems: 'center',
        marginTop: 16,
    },
    actions: {
        display: 'inline-block',
        width: '25%',
    },
    breadcrumbs: {
        display: 'inline-block',
        width: '75%',
    },
    divider: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    title: {
        margin: '12px 0',
    },
    container: {
        height: '100%',
    },
}));

export type LibraryViewProps = Pick<LibraryWidgetProps,
    'isFolderNavigable' |
    'multiSelect' |
    'primaryAction' |
    'onFolderChangeCallback' |
    'onSelectItemCallback' |
    'renderPrimaryAction' |
    'selectable' |
    'uploadAction' |
    'urlAction'
    > & {
    contentFilters?: SearchState<SearchFilters>;
};

export default function LibraryView({
    contentFilters,
    isFolderNavigable,
    multiSelect = false,
    onFolderChangeCallback,
    onSelectItemCallback,
    primaryAction,
    renderPrimaryAction,
    selectable,
    uploadAction = false,
    urlAction = false,
}: LibraryViewProps) {
    const { classes } = useStyles();

    const renderPreview = useCallback((item: LibraryItemType) => {
        if (isMediaFolder(item)) {
            return null;
        }
        return <PreviewDialog media={item} previewUrl={getPreviewUrl(item, undefined, processEnv.API_ROOT)} previewable={isPreviewable(item)} />;
    }, []);
    return (
        <div className={classes.container}>
            <LibraryWidget
                defaultSearchFilters={contentFilters}
                isFolderNavigable={isFolderNavigable}
                onFolderChangeCallback={onFolderChangeCallback}
                onSelectItemCallback={onSelectItemCallback}
                multiSelect={multiSelect}
                primaryAction={primaryAction}
                renderPreview={renderPreview}
                renderPrimaryAction={renderPrimaryAction}
               /* scrolling={false}*/
                selectable={selectable}
                showTitle={false}
                uploadAction={uploadAction}
                urlAction={urlAction}
            />
        </div>
    );
}
