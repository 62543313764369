import React, { useCallback } from 'react';
import { CheckCircle, Error, Help, Info, Warning } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import type { ButtonProps } from '@zetadisplay/zeta-ui-components';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import type { DialogOptions, InfoCallback, InfoFunc } from './InfoProvider';

const useStyles = makeStyles()((theme) => ({
    button: {
        minWidth: '150px',
    },
    icon: {
        marginRight: '8px',
    },
}));

interface DialogProviderProps {
    setShowDialog: (showDialog: InfoFunc<DialogOptions>) => void;
}

interface DialogInfo extends DialogOptions {
    close: InfoCallback<DialogOptions>;
}

interface DialogButtonProps {
    className: string;
    close: (info: DialogOptions) => void;
    info: DialogInfo;
    buttonProps: Partial<ButtonProps>;
}

const DialogButton = ({ className, close, info, buttonProps }: DialogButtonProps) => (
    <Button className={className} onClick={() => close(info)} {...buttonProps} />
);

interface TitleIconProps {
    icon?: string;
}

const TitleIcon = ({ icon }: TitleIconProps) => {
    const { classes } = useStyles();
    if (icon === 'warning') return <Warning htmlColor={'#F39200'} className={classes.icon} />;
    if (icon === 'info') return <Info htmlColor={'#3574C2'} className={classes.icon} />;
    if (icon === 'confirm') return <Help htmlColor={'#2c3138'} className={classes.icon} />;
    if (icon === 'help') return <Help htmlColor={'#3574C2'} className={classes.icon} />;
    if (icon === 'success') return <CheckCircle htmlColor={'#0F9802'} className={classes.icon} />;
    if (icon === 'error') return <Error htmlColor={'#DC0000'} className={classes.icon} />;
    return null;
};

export default function DialogProvider({ setShowDialog }: DialogProviderProps): JSX.Element {
    const [dialogs, setDialogs] = React.useState<DialogInfo[]>([]);
    const dialogsRef = React.useRef<DialogInfo[]>([]);
    const { classes } = useStyles();

    const refresh = useCallback(() => {
        setDialogs(dialogsRef.current.concat());
    }, []);

    const findIndex = useCallback((item: DialogOptions) => {
        return dialogsRef.current.findIndex((info) => info && info.key === item.key);
    }, []);

    const close = useCallback(
        (info: DialogOptions, reason?: string) => {
            const index = findIndex(info);
            if (index < 0) return;
            dialogsRef.current.splice(index, 1);
            refresh();
            if (info.onClose) info.onClose(info, reason);
        },
        [findIndex, refresh]
    );

    /*const getButton = (info: DialogInfo, props: Partial<ButtonProps>) => (
        <Button className={classes.button} onClick={() => close(info)} {...props} />
    );*/

    React.useEffect(() => {
        setShowDialog((options: DialogOptions) => {
            const key: string = options.content ? (options.content as unknown as Record<string, string>)?.key : '';
            const info: DialogInfo = {
                ...options,
                ...{
                    key: options.key || key || `_dialog_${new Date().getTime()}`,
                    close: () => close(info),
                },
            };
            if (findIndex(info) >= 0) {
                // console.log('key exists:', info)
                return () => {
                    close(info);
                };
            }
            dialogsRef.current.push(info);
            refresh();
            if (info.onShow) info.onShow(info);

            return () => {
                close(info);
            };
        });
    }, [setShowDialog, close, findIndex, refresh]);

    return (
        <>
            {dialogs.map((info) => {
                if (!info) return null;
                const { key, text, title, contentOnly = false, parseFunction } = info;
                return (
                    <Dialog
                        key={key}
                        open
                        onClose={(ev, reason) => {
                            if (reason !== 'backdropClick' || !info.disableBackdropClick) close(info, reason);
                        }}
                        disableEscapeKeyDown={info.disableEscapeKeyDown}
                    >
                        {!contentOnly && (
                            // && info.title
                            <DialogTitle>
                                {title && <TitleIcon icon={info.icon} />}
                                {title}
                            </DialogTitle>
                        )}

                        {!contentOnly ? (
                            <DialogContent>
                                {info.text && (
                                    <Typography style={{ textAlign: title ? 'left' : 'center' }}>
                                        {parseFunction ? parseFunction(text) : text}
                                    </Typography>
                                )}
                                {info.content}
                            </DialogContent>
                        ) : (
                            info.content
                        )}

                        {!info.contentOnly && (
                            <DialogActions>
                                {!info.actions && !info.buttons ? (
                                    <DialogButton
                                        className={classes.button}
                                        info={info}
                                        close={close}
                                        buttonProps={{ kind: 'primaryAction', label: 'OK' }}
                                    /> //getButton(info, { kind: 'primaryAction', label: 'OK' })
                                ) : (
                                    info.actions
                                )}
                                {info.buttons &&
                                    info.buttons.map(
                                        (item, index, arr) => (
                                            <DialogButton
                                                className={classes.button}
                                                info={info}
                                                close={close}
                                                buttonProps={{
                                                    ...{
                                                        kind:
                                                            index === arr.length - 1
                                                                ? 'primaryAction'
                                                                : 'secondaryAction',
                                                    },
                                                    ...item,
                                                }}
                                                key={`dlgBtn${index}`}
                                            />
                                        )
                                        /*getButton(info, {
                                        ...{
                                            key: `dlgBtn${index}`,
                                            kind: index === arr.length - 1 ? 'primaryAction' : 'secondaryAction',
                                        },
                                        ...item,
                                    })*/
                                    )}
                            </DialogActions>
                        )}
                    </Dialog>
                );
            })}
        </>
    );
}
