import {action, autorun, extendObservable, observable, reaction, toJS} from 'mobx';

import { getLocalizedString } from '../../../localization/localizationUtils';

const disposerMap = new Map();
let listReaction;

export const deleteTempListItemFields = (comp) => {
    if (comp.ui?.name === 'SE_List') {
        delete comp.ui._selected;
        delete comp.ui._editing;
        delete comp.ui.select;
        delete comp.ui.edit;
        if (comp.value)
            comp.value.forEach((item) => {
                if (item) {
                    //delete item._itemId;
                    Object.keys(item).forEach((key) => {
                        if (item[key] === undefined || item[key] === null) delete item[key];
                    });
                }
            });
    }
};

export const addNewItem = async(spotData, itemType) => {
    let newItem = (spotData.ui.createNewItem || spotData.createNewItem)?.call(spotData, itemType, true);
    newItem = await Promise.resolve(newItem);
    if (newItem === undefined || newItem === null) {
        console.log('Trying to add list item, but template returned', newItem);
        return;
    }
    const item = observable(newItem);
    if (!spotData.value) spotData.value = [];
    let selIndex = -1;
    if (spotData.ui._selected) {
        selIndex = spotData.value.findIndex((item) => item._itemId === spotData.ui._selected);
        if (selIndex >= 0 && selIndex < spotData.value.length - 1) selIndex++;
    }
    if (selIndex < 0 || selIndex >= spotData.value.length - 1) spotData.value.push(item);
    else spotData.value.splice(selIndex, 0, item);

    //const itemProps = spotData.getListItemProps && spotData.getListItemProps(item, spotData.value.indexOf(item));
    const itemProps = (spotData.ui.getListItemProps || spotData.getListItemProps)?.call(spotData, item, spotData.value.indexOf(item));
    if (itemProps && itemProps.disableEdit) spotData.ui._selected = item._itemId;
    else spotData.ui._editing = item._itemId;
};

export const moveItem = (comp, oldIndex, { newIndex, delta }) => {
    if (comp.value.length < 2 || oldIndex < 0) return;
    let toIndex = delta ? oldIndex + delta : newIndex;
    if (toIndex === oldIndex) return;
    if (toIndex < 0) toIndex = comp.value.length - 1;
    else if (toIndex > comp.value.length - 1) toIndex = 0;
    //if (comp.onMove) if (comp.onMove(comp.value[oldIndex], oldIndex, toIndex) === false) return;
    if ((comp.ui.onMove || comp.onMove)?.call(comp, comp.value[oldIndex], oldIndex, toIndex) === false) return;
    comp.value.splice(toIndex, 0, comp.value.splice(oldIndex, 1)[0]);
};

export const deleteItem = async(comp, item) => {
    const n = comp.value.indexOf(item);
    if (n < 0) return;
    const cancelFunc = comp.ui.onDelete || comp.onDelete;
    if (cancelFunc) {
        const result = await(Promise.resolve(cancelFunc.call(comp, item, n)))
        if (result === false) return;
    }
    //if ((comp.ui.onDelete || comp.onDelete)?.call(comp, item, n) === false) return;
    comp.value.splice(n, 1);
    comp.ui._editing = undefined;
    comp.ui._selected = undefined;
};

export const duplicateItem = async(comp, item) => {
    let newItem = (comp.ui.onCopyItem || comp.onCopyItem)
        ? (comp.ui.onCopyItem || comp.onCopyItem)?.call(comp, toJS(item), comp.value.indexOf(item))
        : toJS(item);
    newItem = await Promise.resolve(newItem);
    if (newItem === false) return;
    if (!newItem) newItem = toJS(item);
    newItem._itemId = '';
    generateId(comp, newItem);
    let n = comp.value.indexOf(item);
    if (n < comp.value.length - 1) {
        n++;
        comp.value.splice(n, 0, newItem);
    } else comp.value.push(newItem);

    return newItem;
};

export const getItemRestrictions = (comp, item, forEditing) => {
    if (!item) {
        item = comp.ui._selected && comp.value.find((item) => item._itemId === comp.ui._selected);
        if (!item) return false;
    }
    let editOptions =
        forEditing && (comp.ui.onBeginEditing || comp.onBeginEditing)
            ? (comp.ui.onBeginEditing || comp.onBeginEditing)?.call(comp, item, comp.value.indexOf(item))
            : undefined;
    if (editOptions === false) return false;
    if (!forEditing && !editOptions && (comp.ui.onGetActions || comp.onGetActions)) {
        editOptions = (comp.ui.onGetActions || comp.onGetActions)?.call(comp, item, comp.value.indexOf(item));
        if (editOptions === false) return false;
    }
    const { hideMove, hideDelete, hideCopy } = comp.ui;
    return {
        hideMove: editOptions ? editOptions.hideMove : hideMove,
        hideDelete: editOptions ? editOptions.hideDelete : hideDelete,
        hideCopy: editOptions ? editOptions.hideCopy : hideCopy,
    };
};

const generateId = (comp, listItem) => {
    const maxRand = Math.pow(2, 20); // 1.048576 M
    while (!listItem._itemId) {
        let rnd = `${comp.id}_${Math.round(maxRand * Math.random()).toString(16)}`;
        if (!comp.value.find((listItem) => listItem._itemId === rnd)) {
            listItem._itemId = rnd;
            break;
        }
    }
};

export const initListComponent = (editorIntf, zdlib) => {
    const editItem = async(comp, editingItem) => {
        //console.log('>>> EDIT >>>> ', editingItem.itemId);

        const editOptions = getItemRestrictions(comp, editingItem, true);
        if (editOptions === false) return;
        const editComponents = (comp.ui.getEditComponents || comp.getEditComponents)?.call(comp, editingItem, comp.value.indexOf(editingItem));

        let model = (comp.ui.createNewItem || comp.createNewItem)?.call(comp, editingItem.itemType, false);
        model = await Promise.resolve(model);
        if (!model) return;

        let ext = null;
        Object.keys(model).forEach((key) => {
            if (!Object.prototype.hasOwnProperty.call(editingItem, key)) {
                if (!ext) ext = {};
                ext[key] = model[key];
            }
        });
        if (ext) extendObservable(editingItem, ext);

        const tempPageId = editingItem._itemId;// 'list_' + new Date().getTime();

        const setSubpageTitle = () => {
             const listItem = (comp.ui.getListItemProps || comp.getListItemProps)?.call(comp, editingItem, comp.value.indexOf(editingItem))
             const page = editorIntf.getSubpage({id: tempPageId});
             //console.log('#¤#¤#¤#¤¤', listItem, toJS(page))
             if (listItem?.text && page) page.title = listItem.text;
         }
        // array to store item fields and their corresponding editor component ids
        const updates = [];

        const valueDisposer = autorun(() => {
            // this is needed so that if the template modifies the component data
            // (array of items on list, or any item) , => the UI in the sub-page updates
            const index = comp.value.findIndex(item => item._itemId === editingItem._itemId);
            if (index < 0) return;
            const current = comp.value[index];
            const compData =  (comp.ui.getEditComponents || comp.getEditComponents)?.call(comp, current, index);
            if (compData) {
                updates.forEach(item => {
                    const updatedComp = zdlib.components.getItem(item.id);
                    if (updatedComp && updatedComp.value !== current[item.field]) {
                        if (comp.log && updatedComp.value !== current[item.field]) console.log(`List data[${index}].${item.id} changed:`, updatedComp.value, '-->', current[item.field])
                        //updatedComp.value = current[item.field]
                        Object.assign(updatedComp, {...compData[item.field], ...{value: current[item.field]}});
                    }
                })
                setSubpageTitle()
            }
        }, {delay: 50})


        // array of editor components on (sub)page
        const components =
            (editComponents &&
                Object.keys(editComponents).map((key, index) => {
                    const compId = editComponents[key].id || tempPageId + key;
                    updates.push({id: compId, field: key});
                    const data = {
                        ...editComponents[key],
                        ...{
                            id: compId,
                            value: editingItem[key],
                            pageId: editComponents[key].pageId || tempPageId,
                            disableSave: true,
                            onChange: (data) => {
                                if (comp.log && editingItem[key] !== data.value)
                                    console.log(`List item.${key} edited:`, editingItem[key], '-->', data.value);
                                editingItem[key] = data.value;
                                editComponents[key].onChange?.call(undefined, data)
                            }
                        }}
                    zdlib.components.replaceItem(data);
                    return data;
                })) ||
            [];




        const divStyle = { width: 'auto', marginBottom: 16, maxWidth: 120, minWidth: 120 };

        if (!editOptions.hideMove) {
            const moveUp = {
                id: 'mu_' + tempPageId + Math.round(Math.random() * 10000),
                pageId: tempPageId,
                disableSave: true,
                sortIndex: -999999,
                ui: {
                    name: 'SE_Button',
                    label: 'common.action.move_up',
                    translate: true,
                    icon: 'arrow_upward',
                    disabled: comp.value.length < 2,
                    divStyle,
                },
                onClick: function (data) {
                    moveItem(comp, comp.value.indexOf(editingItem), { delta: -1 });
                },
            };
            components.push(moveUp);
            zdlib.components.replaceItem(moveUp);

            const moveDown = {
                id: 'md_' + tempPageId + Math.round(Math.random() * 10000),
                pageId: tempPageId,
                disableSave: true,
                sortIndex: -999998,
                ui: {
                    name: 'SE_Button',
                    label: 'common.action.move_down',
                    translate: true,
                    icon: 'arrow_downward',
                    disabled: comp.value.length < 2,
                    divStyle,
                },
                onClick: function (data) {
                    moveItem(comp, comp.value.indexOf(editingItem), { delta: 1 });
                },
            };
            components.push(moveDown);
            zdlib.components.replaceItem(moveDown);
        }

        if (!editOptions.hideCopy) {
            const copy = {
                id: 'copy_' + tempPageId + Math.round(Math.random() * 10000),
                pageId: tempPageId,
                disableSave: true,
                sortIndex: -999996,
                ui: {
                    name: 'SE_Button',
                    label: 'common.action.copy',
                    translate: true,
                    icon: 'content_copy',
                    divStyle,
                },
                onClick: function (data) {
                    duplicateItem(comp, editingItem).then(newItem => {
                        if (newItem) {
                            comp.ui._editing = newItem._itemId;
                            zdlib.editor.showMessage('editor.list.message.itemcopied', 6, null, null, 'info', true);
                        }
                    })
                },
            };
            components.push(copy);
            zdlib.components.replaceItem(copy);
        }

        if (!editOptions.hideDelete) {
            const del = {
                id: 'del_' + tempPageId + Math.round(Math.random() * 10000),
                pageId: tempPageId,
                disableSave: true,
                sortIndex: -999995,
                ui: {
                    name: 'SE_Button',
                    label: 'common.action.delete',
                    translate: true,
                    icon: 'delete',
                    divStyle,
                },
                onClick: function (data) {
                    void deleteItem(comp, editingItem);
                },
            };
            components.push(del);
            zdlib.components.replaceItem(del);
            const spacer = {
                id: 'spa_' + tempPageId + Math.round(Math.random() * 10000),
                pageId: tempPageId,
                disableSave: true,
                sortIndex: -999990,
                ui: {
                    name: 'SE_HTML',
                    html: '<div style="width: 100%; display: block"></div>',
                },
                onClick: function (data) {
                    void deleteItem(comp, editingItem);
                },
            };
            components.push(spacer);
            zdlib.components.replaceItem(spacer);
        }

        //const listItem = comp.getListItemProps(editingItem, comp.value.indexOf(editingItem));
        const listItem = (comp.ui.getListItemProps || comp.getListItemProps)?.call(comp, editingItem, comp.value.indexOf(editingItem));
        // this will fire onSelectionChange() callback
        comp.ui._selected = editingItem._itemId;

        zdlib.editor.showSubpage({
            id: tempPageId,
            title: listItem?.text || editingItem.itemType?.label || editorIntf.tHook.trans('common.action.edit'),
            translate: !(listItem?.text || editingItem.itemType?.label),
            onClose: function (options) {
                //console.log('XXX onClose:', options.id)
                comp.ui._editing = undefined;
            },
        });

        return {components, valueDisposer};
    };

    const disposeEditingComponents = ({components, valueDisposer}) => {
        // dispose autoruns
        if (valueDisposer) valueDisposer();
        components.forEach((data) => {
            //if (data.__itemValueDisposer) data.__itemValueDisposer();
            // remove temp editing components for the list item
            zdlib.components.replaceItem(data.id);
        });
    };

    const initList = (comp) => {
        if (!comp.value) comp.value = [];
        // add temp fields for comp.ui
        extendObservable(comp.ui, {
            _selected: undefined,
            _editing: undefined,
            select: action(item => comp.ui._selected = item?._itemId),
            edit: action(item => comp.ui._editing = item?._itemId),
        });

        let editingComponents;
        disposerMap.set(comp, [
            // when component value (array) changes, make sure every item has an _itemId
            autorun(
                () => {
                    comp.value?.forEach((item) => {
                        if (item && !item._itemId) {
                            Object.keys(item).forEach((key) => {
                                const value = item[key];
                                // if new item contains localized fields, get current translation
                                if (typeof value === 'object' && value?.en) {
                                    item[key] = getLocalizedString(zdlib.currentLanguage, value);
                                }
                            });
                            generateId(comp, item);
                        }
                    });
                },
            ),
            // when selected list item changes, fire onSelectItem callback (in template, if defined)
            reaction(
                () => comp.ui._selected,
                (value, previousValue) => {
                    if (!comp.onSelectItem && !comp.onSelectionChange && !comp.ui.onSelectionChange) return;
                    const previous = previousValue && comp.value.find((item) => item._itemId === previousValue);
                    const index = value ? comp.value.findIndex((item) => item._itemId === value) : -1;
                    const item = index >= 0 && comp.value[index];
                    // added new callback onSelectionChange(). Previously item was always defined,
                    // so this is to make sure existing templates with onSelectItem() don't break
                    // IOW from now on, always use onSelectionChange()
                    if (previous !== item) (comp.ui.onSelectionChange || comp.onSelectionChange)?.call(comp, item, index);
                    // legacy:
                    if (item && (!previous || previous !== item) && comp.onSelectItem) comp.onSelectItem(item, index);
                }
            ),
            // when a list item is being edited
            reaction(
                () => comp.ui._editing,
                (newValue, previousValue, reaction) => {
                    //trace(reaction);
                    //console.log('XXX _editing:', previousValue, '=>', newValue);

                    if (previousValue) {
                        // if there's an item currently being edited --> dispose autoruns & end editing
                        const editingItem = comp.value.find((item) => item._itemId === previousValue);
                        if (editingItem || !newValue) {
                            if (editingComponents) disposeEditingComponents(editingComponents);
                            editingComponents = null;
                            if (editingItem) (comp.ui.onEndEditing || comp.onEndEditing)?.call(comp, editingItem, comp.value.indexOf(editingItem));
                            // if there's an image file picker open, close it
                            const dlg = editorIntf.dialogs.find(item => item?.key === 'selectfile');
                            if (dlg) {
                                dlg.close();
                            }
                            if (newValue) {
                                const subPage = zdlib.editor.getSubpage({id: previousValue});
                                if (subPage) subPage.onClose = undefined;
                            }
                            zdlib.editor.showSubpage({id: previousValue}, true);
                            if (editingItem) comp.ui._selected = previousValue;
                        }
                    }
                    // if there's a new item to be edited --> edit (calls onBeginEditing() in template, if defined)
                    const item = newValue && comp.value.find((item) => item._itemId === newValue);
                    if (item) {
                        editItem(comp, item).then(comps => editingComponents = comps);
                    }
                }
            ),
        ]);
    };

        listReaction?.call(undefined);
        listReaction = autorun(
            () => {
                    zdlib.components.forEach((comp) => {
                        if (comp.ui?.name === 'SE_List' && !disposerMap.has(comp)) initList(comp);
                    });
                    if (disposerMap.keys().length > 0)
                        Array.from(disposerMap.entries())
                            .filter((entry) => !zdlib.components.find(entry[0]))
                            .forEach((entry) => {
                                entry[1].forEach((disposer) => disposer());
                                disposerMap.delete(entry[0]);
                            });

        });

};
