import type {
    LibraryModelsMedia,
    LibraryModelsMediaRelations,
    LibraryModelsTemplateInfo
} from "@zetadisplay/engage-api-client";
import type {LibraryModelsTemplateRelations} from "@zetadisplay/engage-api-client/models/library-models-template-relations";
import type { ApiInterface } from '@zetadisplay/engage-components/modules/api';

export const getTemplateItem = async (api: ApiInterface, workspaceid: string, templateid: string): Promise<LibraryModelsTemplateInfo> => {
    const template = await api.templates
        .getTemplateById({
            templateid,
            workspaceid,
        })
        .then((result) => result.data);

    /*if (!template?.sourcePath) {
        throw new Error(`No sourcePath in template: ${template?.name}`);
    }*/

    return template;
};

export const getMediaPublishedPlaylists = async (api: ApiInterface, workspaceid: string, mediaid: string) => {
    const relations = await api.media
        .getMediaRelations({
            mediaid,
            workspaceid,
        })
        .then((result) => result.data);

    return relations?.campaigns?.filter((campaign) => campaign.status === 2) || []; // Published
};

export const getMediaItem = async (api: ApiInterface, workspaceid: string, mediaid: string): Promise<LibraryModelsMediaRelations> => {
    const mediaItem = await api.media
        .getMediaRelations({
            mediaid,
            workspaceid,
        })
        .then((result) => result.data);
   /* if (!mediaItem?.templateId) {
        throw new Error(`Invalid template media item: ${mediaItem?.name}`);
    }*/

    return mediaItem;
}

export const getMediaItemById = async (api: ApiInterface, workspaceid: string, mediaid: string): Promise<LibraryModelsMedia> => {
    const mediaItem = await api.media
        .getMediaById({
            mediaid,
            workspaceid,
        })
        .then((result) => result.data);

    return mediaItem;
}

export const getMediaList = async (api: ApiInterface, workspaceid: string, folderId: string): Promise<LibraryModelsMedia[]> => {
    const items = await api.media
        .getMediaList({
            folderId,
            workspaceid,
        })
        .then((result) => result.data.items);
    return items || [];
}

export const getTemplates = async (api: ApiInterface, workspaceid: string): Promise<LibraryModelsTemplateRelations[]> => {
    const items = await api.templates
        .getTemplates({
            workspaceid,
        })
        .then((result) => result.data.items);
    return items || [];
}