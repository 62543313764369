import { useMediaQuery} from '@mui/material';

const dbg = false;

/**
 * return true if width <= 430
 */
export function Use_mq_Narrow() {
    var ret = useMediaQuery('(max-width:470px)');
    if (dbg && ret) console.log('@@ Narrow @@')
    return ret
}

/**
 * return true if width <= 600
 */
export function Use_mq_MobileWidth() {
    var ret =  useMediaQuery('(max-width:680px)');
    if (dbg && ret) console.log('@@ MobileWidth @@')
    return ret
}

/**
 * return true if width <= 600 || height <= 600

export function Use_mq_Mobile() {
    return useMediaQuery('(max-width:600px), screen and (max-height: 600px)');
}
*/

/**
 * return true if width  < 1024
 */
export function Use_mq_NarrowContent() {
    return useMediaQuery(`(max-width:1024px)`);
}

