import React from 'react';
import {Accordion} from '@zetadisplay/zeta-ui-components'
import {getLocalizedString} from "localization/localizationUtils";
import { observer } from 'mobx-react';
import {getSortedComponents} from "State/PageHandler";

import HTMLParser from "../UIComponents/HTMLParser";
import {getControl} from "./components";

export const SE_Accordion = observer((props) => {
    const { zdlib, spotData } = props;
    const ref = React.useRef();
    const isHTML = typeof  spotData.ui?.label === 'function';

    const clicked = React.useCallback(ev => {
        ev.preventDefault()
        ev.stopImmediatePropagation()
        spotData.ui.expanded = !spotData.ui.expanded;
        spotData.ui.onExpandedChange?.call(undefined, spotData)
    }, [ spotData])

    React.useEffect(() => {
        if (!ref.current) return;
        const div = ref.current.querySelector('[data-testid="accordion-summary"]');
        div?.addEventListener('click', clicked)
        return () => {
            div?.removeEventListener('click', clicked)
        }
    }, [clicked])

    return <div ref={ref}>
            <Accordion title={isHTML ? undefined : getLocalizedString(zdlib.currentLanguage, spotData.ui.label)}
                      type={'compact'}
                      expanded={!!spotData.ui.expanded}
                      renderSummary={isHTML ? () => HTMLParser(spotData.ui.label) : undefined}
            >
                {
                getSortedComponents(zdlib, spotData.id).map((component, index) => {
                    return getControl(props, component, index)
                    })
                }
            </Accordion>
         </div>
});



