
export const Timeline = (zdlib) => {
    let tracks, rootTL, videoTL, templateTL, _spot, _isEngage;
    let _time = -1

    function control(playing, time) {
        const method = playing ? 'play' : 'pause';

        // in Engage templates => handle in the template
        if (_isEngage) {
            _spot[method](time);
            return;
        }

        zdlib.playing = playing;
        if (rootTL) rootTL[method](time, false)
        if (templateTL) templateTL[method](time, false)
        if (videoTL) {
            videoTL[method](time, false)
        }
        let seek = 0;
        let n = 0;
        if (tracks)
            Object.keys(tracks).forEach( function(componentId) {
                const tw = tracks[componentId].tween;
                tw.vars.onUpdate.call(tw);
                if (tracks[componentId].seeking) seek++;
                n++;
            });
        if (_spot && _spot[method])
            _spot[method](time);
        if (zdlib.env.transcodeLog && !playing) console.log('++zdlib waiting for', Number(seek), '/', Number(n), 'videos...' , zdlib.timer.log());
        if (zdlib.env.isTranscoding && !playing) {
            const seeking = Number(seek) > 0;
            if (window.transcodeapi_seeking && seeking !== zdlib.seeking) window.transcodeapi_seeking(seeking);
            zdlib.seeking = seeking;
        }
    }


    function onTick() {
        _time = getTime();

        if (zdlib.playing && Number(zdlib.duration) > 0 && _time >= Number(zdlib.duration)) {
            control(true, 0)
            _time = 0
        }

        if (Math.abs(zdlib.time - _time) >= 0.04) zdlib.time = _time;

        if (rootTL) {
            const t = rootTL.duration()
            if (t < 999999999999 && Math.abs(t - Number(zdlib.duration)) > 0.01) {
                zdlib.duration = t
                console.log('** zdlib.duration set to', t, 'from timeline **')
            }
        }

    }

    function getTime() {
        return (rootTL && rootTL.time()) || (_spot && _spot.getTime && _spot.getTime()) || 0
    }


    function vSeek(domVideo, time, componentId) {

        if (!zdlib.env.isTranscoding) {
            domVideo.currentTime =  time;
            return;
        }

        const track = tracks[componentId];
        if (track.seeking) return;

        function _seekHandler( event) {
            if (zdlib.env.transcodeLog) console.log('++seeked event, video id=', event.target.id, 'to', event.target.currentTime, zdlib.timer.log());
            _seekDone();
        }

        function _seekDone() {
            //domVideo.removeEventListener('seeked', _seekHandler);
            domVideo.onseeked = undefined;
            track.seeking = false;
            const seeking = Object.keys(tracks).some( function(componentId) {
                return tracks[componentId].seeking;
            } );
            if (zdlib.env.transcodeLog && seeking !== zdlib.seeking)
                console.log('++seek complete; zdlib.seeking=', seeking, !seeking ? '++++++++++++' : '..........', zdlib.timer.log());
            if (window.transcodeapi_seeking && seeking !== zdlib.seeking) window.transcodeapi_seeking(seeking);
            zdlib.seeking = seeking;
        }

        track.seeking = Math.abs(time - domVideo.currentTime) >= 0.010; // 100fps;
        if ( !track.seeking ) {
            if (zdlib.env.transcodeLog) console.log('++video id=', domVideo.id, '@', domVideo.currentTime, '= OK', zdlib.timer.log());
            _seekDone();
            return;
        }
        if (zdlib.env.transcodeLog) console.log('++seek video id=', domVideo.id, domVideo.paused ? '(paused)' : '(playing)' , domVideo.currentTime, '=>', time, '...', zdlib.timer.log()) ;
        if (zdlib.env.transcodeLog &&  Math.abs(time - domVideo.currentTime) >= 0.060) // 16fps
            console.log('################# ?????????- GAP', time - domVideo.currentTime, '?????????? ########################', zdlib.timer.log());

        domVideo.onseeked = _seekHandler;
        domVideo.currentTime =  time;
    }


    function vPause(vid, track) {
        if (track) track.pending = !vid.paused;
        return vid.paused ? Promise.resolve()
            : new Promise((resolve) => {
                function paused() {
                    if (track) track.pending = false;
                    vid.removeEventListener('pause', paused)
                    resolve();
                }
                try {
                    if (zdlib.env.transcodeLog) console.log('++pause video:', vid.currentTime, zdlib.timer.log());
                    vid.addEventListener('pause', paused);
                    vid.pause();
                }
                catch(err) {
                    if (zdlib.env.isLocal) console.warn('!! video pause error', err.message)
                    paused();
                }
            })
    }
    function vPlay(vid, track) {
        if (track) track.pending = vid.paused;
        return !vid.paused ? Promise.resolve()
            : new Promise((resolve) => {
                function playing() {
                    if (track) track.pending = false;
                    resolve();
                }
                if (zdlib.env.transcodeLog) console.log('++play video:', vid.currentTime, zdlib.timer.log());
                vid.play().then(() => {
                    playing();
                }).catch((err) => {
                    if (zdlib.env.isLocal) console.warn('!! video play error', err.message);
                    playing();
                })
            })
    }

    function createRootTL() {
        if (rootTL) return
        const vars = {repeat : 0, paused: true };
        rootTL =  window.gsap ? window.gsap.timeline(vars) : new window.TimelineMax(vars);
    }



    return {
        setSpot: function(spot) {
            _spot = spot;
            _isEngage = _spot?.isEngage?.call(undefined);
            if (!spot?.isEngage?.call(undefined) && window.TweenLite) window.TweenLite.ticker.addEventListener('tick', onTick); //gsap2
        },
        setTimeline: function(timeline) {
            if (!rootTL)  {
                if (!timeline) return;
                createRootTL();
            }
            if (timeline && timeline.repeat() < 0) timeline.repeat(0);
            templateTL = timeline;
            rootTL.remove(templateTL)
            if (templateTL)
                rootTL.add(templateTL, 0)
        },
        isEnabled: function() {
            return !!(rootTL || (_spot && _spot.play && _spot.pause));
        },
        play: function(time) {
            control(true, time)
        },
        pause: function(time) {
            if (zdlib.env.transcodeLog) console.log('+++++zdlib.pause', '@', time, zdlib.seeking ? 'SEEKING!!!' : '..', zdlib.timer.log());
            if (zdlib.env.isTranscoding && !_isEngage) {
                if (window.transcodeapi_seeking) window.transcodeapi_seeking(true);
                zdlib.seeking = true;
            }
            control(false, time)
        },
        cleanup: function() {
            if (rootTL) {
                rootTL.getChildren(true, true).forEach(tween => tween.kill());
                rootTL.remove(rootTL.getChildren(false));
                rootTL.kill();
                rootTL.clear();
                tracks = null;
                templateTL = null;
                videoTL = null;
                _time = -1;
                rootTL = null;
            }
            if (!_spot?.isEngage?.call(undefined) && window.TweenLite) window.TweenLite.ticker.removeEventListener('tick', onTick);
            _spot = null;
            _isEngage = false;
            zdlib.duration = 0;
        },
        addVideo: async function(domVideo) {
            if (_isEngage) return;
            const isPlaying = zdlib.playing;
            zdlib.pause();
            //const domVideo = ev.target;
            const componentId = domVideo.getAttribute('data-component-id')
            let component = componentId && zdlib.components.getItem(componentId);
            if (!component) return;
            if (!component.video?.audioEnabled) domVideo.muted = true;
            //component.event = ev;
            //zdlib.spotCB(component, 'onLoaded', false, {type: 'loadeddata', target: domVideo});
            if (component.video?.noAutoSync) return;
            if (!videoTL) {
                createRootTL()
                const vars =  {repeat : 0, paused: true};
                videoTL = window.gsap ? window.gsap.timeline(vars) : new window.TimelineMax( vars );
                rootTL.add(videoTL, 0)
            }

            if (!tracks) tracks = {};
            let track = tracks[component.id];
            if (track) {
                if (track.elementId === domVideo.id) {
                    console.log('video exists, remove; element id=', track.elementId);
                    await vPause(domVideo, track);
                }
                videoTL.remove(track.tween);
                videoTL.remove(track.ended);
            }
            track = { time: 0 , elementId: domVideo.id, pending: false}
            tracks[component.id] = track;

            let timeAfter = (component.video && component.video.timeAfter) || 0
            const duration = domVideo.duration + (timeAfter < 0 ? timeAfter : 0)

            track.tween = new window.TweenMax( track,
                duration,
                { paused: zdlib.playing,
                    time: duration,
                    repeat: (component.video && component.video.repeat) || 0,
                    // eslint-disable-next-line no-undef
                    ease: Power0.easeNone,
                    onUpdate: function() {
                        if (track.pending) return;
                        const time = (this.target || this.targets()[0]).time;
                        if (!rootTL.isActive()) {
                            vPause(domVideo, track).then(() => {
                                vSeek(domVideo, time, component.id);
                            })
                            //vPause(domVideo, function(){ vSeek(domVideo, this.target.time, component.id) }.bind(this))
                            //console.log('### video update', this.target.time.toFixed(1), '<->', vid.currentTime.toFixed(1), '/', vid.duration.toFixed(1), vid.paused ? '-paused-' : '*PLAYING??*' )
                            return
                        }
                        if (zdlib.env.isTranscoding) return;
                        const inBetween = time >= 0 && time < duration
                        if (domVideo.paused) {
                            //console.log(domVideo.paused, track.pending, time, component.id)
                            vSeek(domVideo, time, component.id)
                            if (inBetween) {
                                vPlay(domVideo, track)
                            }
                        }
                        else if (!inBetween) {
                            //vPause(domVideo, function() {vSeek(domVideo, this.target.time, component.id) }.bind(this))
                            vPause(domVideo).then(() => {
                                vSeek(domVideo, time, component.id);
                            })
                        }
                    }
                })
            videoTL.add(track.tween, (component.video && component.video.startTime) || 0)
            timeAfter = timeAfter >= 0 ? `+=${timeAfter}` : undefined;
            track.ended = function() {
                component = (componentId && zdlib.components.getItem(componentId))
                    || zdlib.components.getItemByElementId(domVideo.id, 'SE_Image');
                if (component) zdlib.spotCB(component, 'onVideoEnded', false, {type: 'videoended', target: domVideo });
            }
            // addcallback: gsap2; add: gsap3
            if (videoTL.addCallback)
                videoTL.addCallback(track.ended, timeAfter);
            else videoTL.add(track.ended, timeAfter)
            if (isPlaying) zdlib.play();
        },
        removeVideo: async function(vid) {
            if (tracks) {
                const cid = vid.getAttribute('data-component-id')
                const component = cid && zdlib.components.getItem(cid);
                const track = component && tracks[component.id];
                if (track) {
                    videoTL.remove(track.tween)
                    videoTL.remove(track.ended)
                    delete tracks[component.id]
                }
            }
            await vPause(vid);
            if (vid.hasAttribute('src')) {
                vid.removeAttribute('src');
                vid.load();
            }
        },


    };

};