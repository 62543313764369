/* date-fns has known issues with their typings which seems to be bothering the whole internet */
/* eslint-disable import/no-duplicates */

import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import fiLocale from 'date-fns/locale/fi';
import svLocale from 'date-fns/locale/sv';
import { observer } from 'mobx-react';

import { getLocalizedString } from '../localization/localizationUtils';
import { changeValue } from './SE__Base';

const locales = { fi: fiLocale, sv: svLocale };

export const SE_TimePicker = observer( ({ spotData, forceOnChangeCallback, zdlib }) =>  {

        const handleChange = (newValue) => {
            changeValue({ type: 'TimePickerChange' }, spotData, newValue, forceOnChangeCallback);
        };

        const { minutesStep = 5 } = spotData.ui;
        //delete uiProps.maxWidth;
        // const emptyLabel = uiProps.emptyLabel || '';
        const locale = locales[navigator.language.substring(0, 2)];

        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                <div style={{ paddingBottom: 4, fontSize: 16 }}>
                    {getLocalizedString(zdlib.currentLanguage, spotData.ui.label)}
                </div>

                <TimePicker
                    onChange={handleChange}
                    value={typeof spotData.value === 'string' ? new Date(spotData.value) : spotData.value}
                    minutesStep={minutesStep}
                    slotProps={{ textField: { variant: 'outlined', InputLabelProps: { shrink: true }}}}
                />

            </LocalizationProvider>
        );
})

