import {autorun, extendObservable, makeAutoObservable} from 'mobx';

import {processEnv} from "../config/processEnv";
import { initListComponent } from '../Editors/EditorControls/List/listUtils';
import { getLocalizedString } from '../localization/localizationUtils';
import ChangeHandler from './ChangeHandler';
import MessageHandler from './MessageHandler';
import NavigationHandler from './NavigationHandler';
import PageHandler, {getSortedPages} from './PageHandler';

const handlerClasses = [ChangeHandler, NavigationHandler, PageHandler, MessageHandler];

class EditorIntf {
    constructor(zdlib) {
        this.zdlib = zdlib;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const THIS = this;

        extendObservable(this, {
            mediaItem: undefined,
            appTitle: 'Content Creator',
            _editing: false,
            layoutConfig: null,
            isGauddiTemplate: false,
        });

        zdlib.editor = makeAutoObservable({
                get editing() {
                    return THIS._editing;
                },
                get viewArea() {
                   return THIS.getViewArea();
                },
                toggleEditing: this.toggleEditing.bind(this),
                uiConfig: {
                    openButton: { visible: true, disabled: false },
                    saveButton: { visible: true, disabled: false },
                    saveAsButton: { visible: true, disabled: false },
                },
                previewSizes: [
                    {label: 'FullHD landscape', width: 1920, height: 1080},
                    {label: 'FullHD portrait', width: 1080, height: 1920},
                ],
                saving: false,
                margin: {
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                },
                get activePopupId() {
                    return THIS.popupStack.length > 0
                        ? THIS.popupStack[THIS.popupStack.length - 1].popupId
                        : undefined;
                }
        });

        
        zdlib.editor.closePopup = this.closePopup.bind(this);
        zdlib.editor.highlightUIControl = this.highlightUIControl.bind(this);


        // ChangeHandler, NavigationHandler, PageHandler, MessageHandler
        // extend zdlib.editor and editorIntf interfaces
        this.handlers = handlerClasses.map((hclass) => {
            return new hclass(THIS, zdlib);
        });

        initListComponent(this, zdlib);

        // version ################################################################################
        zdlib.editor.version = processEnv.SE_VERSION; // e.g. '190301'
        // version ################################################################################

        this.handlers.forEach((handler) => {
            handler.init && handler.init();
        });
    }

    //highlightUIControl(id: string, autoShow?: boolean, autoFocus?: boolean): Promise<void>;
    highlightUIControl(id, autoShow, autoFocus ) {
        return new Promise((resolve) => {
            const {zdlib} = this;
            const comp = zdlib.components.getItem(id);
            if (!comp) {
                console.warn(`Component id="${id}" not found`);
                return;
            }
            if (autoShow !== false) {
                zdlib.editor.activePageId = typeof comp.pageId === 'string' ? comp.pageId : comp.pageId[0];
            } else if (!zdlib.editor.activePageComponents.includes(comp)) {
                console.warn(`Component id="${id}" not on active page`);
                return;
            }
            setTimeout(() => {
                const el = document.querySelector(`[data-component-id="${id}"]`)
                    ?.querySelector('[data-ui-element="control"]');
                if (!el) {
                    console.warn(`Element [data-component-id="${id}"] not found`);
                    return;
                }
                const removeHighlight = () => {
                    setTimeout(() => {
                        el.classList.remove('zd-ui-highlight');
                        if (autoFocus !== false) {
                            const focusCtrl = el.querySelector('input, textarea, select, button');
                            if (focusCtrl) {
                                try {
                                    focusCtrl.focus?.call(focusCtrl);
                                    focusCtrl.selectionStart = focusCtrl.selectionEnd = focusCtrl.value?.length;
                                } catch (err) {
                                    // ignore
                                }
                            }
                        }
                        resolve();
                    }, 200);
                }
                el.addEventListener('transitionend', removeHighlight, {once: true});
                el.classList.add('zd-ui-highlight');
            });
        });
    }

    popupStack = [];

    updatePopup(popupId, closeFunction) {
        this.popupStack = this.popupStack.filter(p => p.popupId !== popupId);
        if (closeFunction) {
            this.popupStack.push({ popupId, closeFunction});
        }
    }

    closePopup() {
        this.popupStack.pop()?.closeFunction?.call(undefined);
    }

    history = [];
    missingAssets = [];

    assetMissing(name) {
        if (name && !this.missingAssets.includes(name)) this.missingAssets.push({ name });
    }

    resetMissingAssets() {
        this.missingAssets = [];
    }

    observeAll(enabled) {
        if (enabled) {
            if (!this.autorun)
                this.autorun = autorun(() => {
                    const activePageId = this.zdlib.editor?.activePageId;
                    const fileName = this.mediaItem?.name || this.mediaItem?.templateName || this.zdlib.spot?.name || '';
                    const page = activePageId && this.zdlib.pages?.getItem(activePageId);
                    const pageTitle = page?.title && (typeof page.title !== 'function')
                        ? ` / ${getLocalizedString(this.zdlib.currentLanguage, page.title)} - `
                        : `${fileName ? ' - ' : ''}`;

                    window.document.title = this.appTitle
                        ? `${fileName}${pageTitle}${this.appTitle}`
                        : 'Content Creator';
                });
            if (!this.sizeAutorun) this.sizeAutorun = autorun(this.resizeArea.bind(this));
        } else {
            if (this.autorun) {
                this.autorun();
                this.autorun = null;
            }
            if (this.sizeAutorun) {
                this.sizeAutorun();
                this.sizeAutorun = null;
            }
        }
        this.handlers.forEach((handler) => {
            if (handler.observe) handler.observe(enabled);
        });
    }



    urlParamsChanged(params) {
        this.handlers.forEach((handler) => {
            if (handler.urlParamsChanged) {
                handler.urlParamsChanged(params);
            }
        });
    }

    setLayoutConfig = (layoutConfig) => {
        this.layoutConfig = layoutConfig;
    };

    beginEditing = () => {
        if (this._editing) return;
        const { zdlib } = this;
        this._editing = true;
        zdlib.env.windowSize = {width: window.innerWidth, height: window.innerHeight};
    };

    endEditing = () => {
        if (!this._editing) return;
        const { zdlib } = this;
        this._editing = false;
        zdlib.env.zoomTo();
    };

    getViewArea() {
        const {layoutConfig, zdlib} = this;
        const pages = this._editing && layoutConfig && getSortedPages(zdlib);
        const controlWidth = pages?.length > 0 ? layoutConfig.controls.width : 0
        const ret = this._editing && layoutConfig
            ? {
                left: controlWidth + (zdlib.editor.margin.left || 0) + 10,
                top: layoutConfig.infobar.height + (zdlib.editor.margin.top || 0),
                width: zdlib.env.windowSize.width -
                        controlWidth -
                        (zdlib.editor.margin.left || 0) -
                        (zdlib.editor.margin.right || 0) - 20,
                height:
                    zdlib.env.windowSize.height -
                    layoutConfig.bottombar.height -
                    layoutConfig.controlbar.height -
                    layoutConfig.infobar.height -
                    (zdlib.editor.margin.top || 0) -
                    (zdlib.editor.margin.bottom || 0),
            }
            : { left: 10, top: 10, width: zdlib.env.windowSize.width - 20, height: zdlib.env.windowSize.height - 20};
        //console.log(ret)
        return ret;
    }

    resizeArea(){
        const {zdlib, layoutConfig, _editing} = this;
        const root = document.getElementById('zd_template_root');
        if (root) root.style.clipPath = `inset(${
            zdlib.env.viewArea.top}px ${
            _editing ? zdlib.editor.margin.right : 0}px ${
            _editing 
                ? layoutConfig?.bottombar.height + 
                    layoutConfig?.controlbar.height  + 
                    zdlib.editor.margin.bottom
                : 0}px ${
            zdlib.env.viewArea.left}px)`;
    }

    toggleEditing = async (forcedState) => {
        const { zdlib } = this;
        if (forcedState !== undefined && forcedState === this._editing) return;
        const open = forcedState === undefined ? !this._editing : forcedState;

        if (open) {
            if (!this._editing) {
                this.beginEditing();
                zdlib.beginEditing();
            }
        } else {
            const q = await zdlib.endEditing();
            if (q === false) {
                console.log('Template prevented exit');
                return;
            }
            this.endEditing();
        }
    };
}

export default EditorIntf;
