import React, { useCallback, useEffect, useRef, useState } from "react";
import { Info } from '@mui/icons-material';
import { Typography } from '@mui/material';
import type {
    LibraryModelsFolder,
    LibraryModelsMedia,
} from '@zetadisplay/engage-api-client';
import { LibraryModelsMediaTypes} from '@zetadisplay/engage-api-client';
import { isMediaFolder } from "@zetadisplay/engage-components/models";
import { useApi } from '@zetadisplay/engage-components/modules/api';
import type { LibraryItemType } from "@zetadisplay/engage-components/modules/library";
import { FilterFields } from "@zetadisplay/engage-components/modules/search";
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import {TextField} from '@zetadisplay/zeta-ui-components';

import { getMediaList, getMediaPublishedPlaylists } from './apiUtils';
import FileDialog from "./FileDialog";
import {FileDialogSidekick} from "./FileDialogSidekick";
import LibraryView from './LibraryView';


interface DialogMessage {
    type: string,
    text: string
}

const updateSelection = (mediaItems: LibraryModelsMedia[], fileName: string, setSelectedItem: (item?: LibraryModelsMedia) => void) => {
    const sel = mediaItems.find(item => item.type === LibraryModelsMediaTypes.TemplateZetaCast
                && item.name?.trim() === fileName.trim());
    setSelectedItem(sel);
};

const SaveDialog = (props) => {
    const { options, editorIntf, zdlib } = props;
    const [selectedItem, setSelectedItem] = useState<LibraryModelsMedia>();
    const [fileName, setFileName] = useState(
        editorIntf.mediaItem?.name || editorIntf.mediaItem?.templateName || zdlib.spot?.name || ''
    );

    const [error, setError] = useState<DialogMessage | null>(null);
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<DialogMessage | null>(null);
    const mediaItems = useRef<LibraryModelsMedia[]>([]);
    const api = useApi();
    const { workspace, workspaceSettings } = useWorkspace();

    const [folder, setFolder] = useState<{id?: string, isRoot?: boolean}>();

    const isSelectable = useCallback((item: LibraryItemType) => {
        return editorIntf.isGauddiTemplate
            ? false
            : (item as LibraryModelsMedia).type === LibraryModelsMediaTypes.TemplateZetaCast;
    }, [editorIntf.isGauddiTemplate]);


    const tHook = useTranslation();

    const handleFolderChange = useCallback(
        async (toFolder?: LibraryModelsFolder) => {
            if (!workspaceSettings) {
                return;
            }

            if (!folder || (!toFolder && !folder.isRoot) || (toFolder && toFolder.id !== folder.id)) {
                setLoading(true)
                const items = (await getMediaList(api, workspace.id, (toFolder?.id || workspaceSettings!.mediaRootFolder)!))
                                        .filter(item => item.type === LibraryModelsMediaTypes.TemplateZetaCast);
                mediaItems.current = items;
                setFolder(toFolder ? {id: toFolder.id } : {id: workspaceSettings!.mediaRootFolder, isRoot: true});
                updateSelection(items, fileName, setSelectedItem);
                setLoading(false);
            }
        },
        [folder, workspaceSettings, api, workspace.id, fileName]
    );

    const handleFilenameChange = useCallback((ev: { target: { value: string; }; }) => {
        const name = ev.target.value.replace(/[#%?|<>*&\\"/'{}[\]^:]/gi, '');
        updateSelection(mediaItems.current, name, setSelectedItem);
        //console.log('handleFilenameChange', name)
        setFileName(name);
    }, []);

    const handleSelect = useCallback((items: Record<string, LibraryItemType>) => {
            if (Object.keys(items).length === 0) {
                return;
            }
            const item = Object.values(items)[0];
            setSelectedItem(item as LibraryModelsMedia);
            if (item) {
                //console.log('handleSelect', item.name)
                setFileName(item.name);
            }
        }, []
    );

    useEffect(() => {
        let err: DialogMessage | null = null;
        setWarning(null);
        if (selectedItem && workspace) {
            getMediaPublishedPlaylists(api, workspace.id, selectedItem.id).then((playlists) => {
                setWarning(
                    playlists.length > 0
                        ? {
                            type: 'live',
                            text: `${tHook.trans('editor.warning.fileonplaylists.text1', [String(playlists.length)])}
                            ${tHook.trans('editor.warning.fileonplaylists.text2')}`, //`This file is on ${playlists.length} playlist(s).\n Updating the file will update all players showing it.`
                        }
                        : null
                );
            });
        }
        if (!fileName.trim()) {
            err = { type: 'no_name', text: tHook.trans('editor.error.nofilename.text') };
        }
        //else if (selectedItem) err = {type: 'exists', text: 'File already exists, please specify a new name'};
        if (selectedItem && !selectedItem.templateId) {
            err = { type: 'invalid', text: 'Invalid file: no templateId' };
        }
        setError(err);
    }, [fileName, selectedItem, api, workspace, tHook]);

    const getDialogResult = () => {
        return {
            ...{
                name: fileName ? `${fileName.trim()}` : '',
                folderId: folder?.id || workspaceSettings?.mediaRootFolder,
                templateId: editorIntf.mediaItem?.templateId,
            },
            ...(selectedItem || {}),
           }
    };

    const isFolderNavigable = useCallback((item: LibraryItemType) => isMediaFolder(item) && !item.sharedFrom,[])

    const renderContent = useCallback(() => {
        return (
            <div className={'file-list-container narrow-header'}>
                <LibraryView
                    contentFilters={{[FilterFields.MEDIA_TYPE]: [editorIntf.isGauddiTemplate ? LibraryModelsMediaTypes.TemplateHtml : LibraryModelsMediaTypes.TemplateZetaCast]}}
                    isFolderNavigable={isFolderNavigable}
                    selectable={isSelectable}
                    onSelectItemCallback={handleSelect}
                    onFolderChangeCallback={handleFolderChange}
                    />
                {
                !editorIntf.isGauddiTemplate
                && <FileDialogSidekick zdlib={zdlib}
                                    rootClass={'file-dialog-sidekick save-sidekick'}
                                    selectedItem={selectedItem}
                                    showSavePreview                                    
                    />
                }
            </div>
        )
    },[editorIntf.isGauddiTemplate, handleFolderChange, handleSelect, isFolderNavigable, isSelectable, selectedItem, zdlib]);

    const renderActions = () => {
        return (
            <>
                {error &&
                    <Typography color="error" className={'file-dialog-error-text'}>
                        {error.text}
                    </Typography>}
                {!error && warning && (
                    <div className={'file-dialog-warning'}>
                        <Info htmlColor={'#3574C2'} />
                        <Typography className={'file-dialog-warning-text'}>{warning.text}</Typography>
                    </div>
                )}
                <div className={'file-dialog-actions file-dialog-save-actions'}>
                    <TextField
                        label={tHook.trans('common.action.save_file_as')}
                        classes={{ root: 'save-dialog-textfield' }}
                        onChange={handleFilenameChange}
                        value={fileName}
                        error={!!error}
                    />
                </div>
            </>
        )
    }

    if (!options) {
        return null;
    }

    return (
        <FileDialog
            renderContent={renderContent}
            renderActions={renderActions}
            title={tHook.trans('common.action.save_file')}
            getDialogResult={getDialogResult}
            disabled={
                loading
                ||
                !!error || (editorIntf.isGauddiTemplate && !!selectedItem)
            }
            okAction={tHook.trans(selectedItem ? 'common.action.save_and_replace' : 'common.action.save_as_new')}
            {...props}
        />);
};

export default SaveDialog;
