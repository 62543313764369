import React from 'react';
import { GithubPicker, SketchPicker } from 'react-color';
import { Checkboard } from 'react-color/lib/components/common';
import Popover from '@mui/material/Popover';
import { decomposeColor } from '@mui/material/styles';
import { observer } from 'mobx-react';

import { getLocalizedString } from '../localization/localizationUtils';
import { changeValue } from './SE__Base';

const hexToRGB = (hex) => {
    const rgba = {
        r:
            hex.length > 5
                ? parseInt(hex.substring(1, 3), 16)
                : parseInt(`${hex.substring(1, 2)}${hex.substring(1, 3)}`, 16),
        g:
            hex.length > 5
                ? parseInt(hex.substring(3, 5), 16)
                : parseInt(`${hex.substring(2, 3)}${hex.substring(2, 3)}`, 16),
        b:
            hex.length > 5
                ? parseInt(hex.substring(5, 7), 16)
                : parseInt(`${hex.substring(3, 4)}${hex.substring(3, 4)}`, 16),
        a: 1,
    };
    if (hex.length === 5) rgba.a = parseInt(`${hex.substring(4, 5)}${hex.substring(4, 5)}`, 16) / 255;
    else if (hex.length >= 8) rgba.a = parseInt(hex.substring(7, 9), 16) / 255;
    return rgba;
};

const colorToHex = (color) => {
    const { hex } = color;
    const { a } = color.rgb;
    const aInt = Math.round(a * 255);
    return `${hex}${aInt === 255 ? '' : aInt.toString(16)}`;
};

class SE_ColorPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    componentDidMount() {
        this.setState({ color: this.getTemplateColor() });
    }

    getTemplateColor = () => {
        const { spotData } = this.props;
        let color;
        if (spotData.value) {
            if (
                (spotData.value.rgba && typeof spotData.value.rgba === 'string') ||
                ((spotData.valueAsString || spotData.ui.valueAsString) &&
                    spotData.value &&
                    typeof spotData.value === 'string' &&
                    spotData.value.startsWith('rgba('))
            ) {
                const col = decomposeColor(spotData.value.rgba || spotData.value);
                if (col.type === 'rgba') {
                    const [r, g, b, a] = col.values;
                    color = { r, g, b, a };
                }
            } else if (typeof spotData.value === 'string' && spotData.value.startsWith('#')) {
                color = hexToRGB(spotData.value);
            }
        }
        if (!color) {
            color = typeof spotData.value === 'string' ? { hex: spotData.value } : spotData.value;
        }
        return color || { hex: '#FFFFFF' };
    };

    onClick = (event) => {
        event.preventDefault();
        this.setState({ open: true, anchorEl: event.currentTarget });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    handleChange = (spotData, forceOnChangeCallback, color, event) => {
        this.setState({ color: color.rgb, hex: color.hex });
        const hex = colorToHex(color).toUpperCase();
        const newValue = (spotData.valueAsString || spotData.ui.valueAsString)
            ? hex
            : {
                  rgb: color.rgb,
                  hex,
                  hsl: color.hsl,
                  rgba: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`,
              };
        changeValue(event, spotData, newValue, forceOnChangeCallback);
    };

    render() {
        const { spotData, forceOnChangeCallback, zdlib } = this.props;
        const { ui } = spotData;

        const color = this.state.color || this.getTemplateColor();
        const hasAlpha = Object.prototype.hasOwnProperty.call(color, 'a');
        const bg = hasAlpha ? `rgba(${color.r},${color.g},${color.b},${color.a})` : color.hex;

        if (!this.changeHandler) this.changeHandler = this.handleChange.bind(this, spotData, forceOnChangeCallback);

        return (
            <>
                <div className={'zdsubtitle'}>{getLocalizedString(zdlib.currentLanguage, ui.label)}</div>
                <div
                    style={{
                        display: 'inline-block',
                        width: 'auto',
                        height: 'auto',
                        padding: 0,
                        cursor: ui.disabled ? 'default' : 'pointer',
                        borderStyle: 'solid',
                        borderColor: '#303030',
                        borderWidth: 1,
                    }}
                    onClick={!ui.disabled ? this.onClick : undefined}
                >
                    <div
                        style={{
                            backgroundColor: '#FFFFFF',
                            position: 'relative',
                            width: ui.width || 50,
                            height: ui.height || 26,
                        }}
                    >
                        {hasAlpha && color.a > 0 && color.a < 1 && <Checkboard white="#FFFFFF" grey="#909090" />}
                        <div style={{ backgroundColor: bg, position: 'relative', width: '100%', height: '100%' }} />
                    </div>
                </div>

                <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    onClose={this.handleRequestClose}
                >
                    {!ui.presetsOnly ? (
                        <SketchPicker
                            disableAlpha={ui.disableAlpha}
                            color={this.state.color}
                            onChange={this.changeHandler}
                            width={260}
                            presetColors={ui.presetColors?.slice() || []}
                        />
                    ) : (
                        <GithubPicker
                            width={238}
                            triangle="hide"
                            colors={ui.presetColors?.slice() || ['#FFFFFF', '#000000']}
                            onChange={this.changeHandler}
                        />
                    )}
                </Popover>
            </>
        );
    }
}

export default observer(SE_ColorPicker);
