import React from 'react';
import {useOidcAccessToken} from "@axa-fr/react-oidc";
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { AuthLoader, useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';

import apiConfig from "../config/apiConfig";
import {DEFAULT_OIDC_CONFIGURATION_NAME} from "../config/oidcConfiguration";
import {processEnv} from "../config/processEnv";
import LandingPage from '../Layout/LandingPage';
import {getMediaItemById, getTemplateItem} from "../LibraryView_engage/apiUtils";
import { setTemplateVisibility } from '../ZetaTemplate/utils/setTemplateVisibility';
import {getTemplateProps} from "./getTemplateProps";
import {loadTemplate} from "./loadTemplate";


let persistentStatus = 'none';




export const TemplateProvider = ({ zdlib, editorIntf, children }) => {
    //console.log('RENDER TemplateProvider')
    const [status, setStatus] = React.useState(persistentStatus);
    const { workspace } = useWorkspace();
    const { user, userLanguages } = useUserInformation();
    const api = useApi();
    const tHook = useTranslation();

    const queryParamsChanged = React.useCallback(async (ev, forceReload) => {
        if (!api || !workspace || !user || !userLanguages || !zdlib || !editorIntf
            || document.location.pathname.includes('signin-callback.html')) {
            return;
        }

        const templateProps = await getTemplateProps({
            api,
            workspaceId: workspace?.id,
            zdlib,
            editorIntf,
            forceReload,
        });
        if (!templateProps) return;

        setStatus(templateProps.status);
        if (templateProps.status.error) {
            return;
        }
        const status = await loadTemplate({zdlib, editorIntf, sourcePath: templateProps.templatePath});
        setStatus(status);
    }, [api, workspace, zdlib, editorIntf, user, userLanguages]);



    React.useEffect(() => {
        if (['none', 'forceReload'].includes(status) && queryParamsChanged) void queryParamsChanged(undefined, status === 'forceReload');
        return () => {
            // store status to prevent reloading when oidc client causes this to remount
            persistentStatus = status;
            };
        }, [status, queryParamsChanged]);


    React.useEffect(() => {
        if (api && workspace?.id) {
            editorIntf.api = api;
            editorIntf.workspaceId = workspace.id;
        }
        return () => {
            editorIntf.api = undefined;
            editorIntf.workspaceId = undefined;
        };
    }, [editorIntf, api, workspace?.id]);



    React.useEffect(() => {
        window.addEventListener('popstate', queryParamsChanged);
        return () => {
            window.removeEventListener('popstate', queryParamsChanged);
        };
    }, [queryParamsChanged]);



    const checkMissingAssets = React.useCallback(() => {
        const envName = processEnv.ENGAGE_ENV;
        const msg =
            envName === 'prod'
                ? ''
                : `<br/>If you don't want to check for missing assets on engage${envName}, include &ignoreMissingAssets in the URL`;
        const params = new URLSearchParams(document.location.search);

        return (envName !== 'prod' && params.has('ignoreMissingAssets')) ||
            !editorIntf.missingAssets?.length ||
            !zdlib.editor
            ? Promise.resolve()
            : zdlib.editor.messageDialog({
                  title: tHook.trans('editor.warning.assetsmissing.header', [editorIntf.missingAssets.length]), //`${editorIntf.missingAssets.length} file(s) missing`,
                  icon: 'warning',
                  text: `${tHook.trans('editor.warning.assetsmissing.text')}                 
                    <ul>${editorIntf.missingAssets.map((asset) => `<li>${asset.name}</li>`).join(', ')}</ul>${msg}`,
              });
    }, [editorIntf, zdlib.editor, tHook]);



    React.useEffect(() => {
        setTemplateVisibility(status === 'ok');
        if (status === 'ok') {
            checkMissingAssets().then(() => {
                editorIntf.resetMissingAssets();
            });
        }
    }, [editorIntf, zdlib, checkMissingAssets, status]);

    const { accessToken } = useOidcAccessToken(DEFAULT_OIDC_CONFIGURATION_NAME);

    React.useEffect(() => {
        zdlib.editor.getWorkspaceProperties = () => ({
            accessToken,
            workspace,
            apiBaseUrl: apiConfig.BASE_URL,
            apiVersion: apiConfig.API_VERSION,
        });
        zdlib.editor.getFileProperties = async ({mediaId, templateId}) => {
            let mediaItem, templateItem;
            if (mediaId) {
                if (mediaId === editorIntf.mediaItem?.id) {
                    mediaItem = editorIntf.mediaItem;
                }
                else {
                    const media = await getMediaItemById(api, workspace.id, mediaId);
                    mediaItem = media
                        ? {...media,
                            url: `${processEnv.API_ROOT}/workspaces/${workspace.id}/media/${media.id}/preview`,
                            thumbnailUrl: `${processEnv.API_ROOT}/workspaces/${workspace.id}/media/${media.id}/thumb`,
                            }
                        : undefined
                }
            }
            if (templateId) {
                if (templateId === editorIntf.templateItem?.id) {
                    templateItem = editorIntf.templateItem;
                }
                else {
                    templateItem = await getTemplateItem(api, workspace.id, templateId);
                }
            }
            return {mediaItem, templateItem}
        };
    }, [zdlib, accessToken, workspace, api, editorIntf.mediaItem, editorIntf.templateItem])

    editorIntf.forceReload = () => {
        if (new URLSearchParams(document.location.search).has('sourcePath'))
            setStatus('forceReload');
    }

    if (status === 'ok') {
        return <>{children}</>;
    }

    return status.error !== undefined ? (
        <LandingPage errorCode={status.error} message={status.message} zdlib={zdlib} editorIntf={editorIntf} />
    ) : (
        <AuthLoader />
    );
};
