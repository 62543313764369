import React from 'react';
import { observer } from 'mobx-react';

import { getLocalizedString } from '../localization/localizationUtils';
import HTMLParser from '../UIComponents/HTMLParser';

const SE_Html = observer((props) => {
    const { zdlib, spotData } = props;
    const id = spotData?.id;
    const html = spotData?.ui?.html;
    const [rendered, setRendered] = React.useState(null);
    React.useLayoutEffect(() => {
        if (id) {
            const parent = document.querySelector(`#SE_HTML_${id}`);
            setRendered(parent);
            if (typeof html === 'function') {
                const node = html({parent, data: spotData});
                if (node) {
                    parent.replaceChildren(node);
                }
            }
        }
    }, [id, html, spotData]);
    return html ? (
        <div {...props.events} id={`SE_HTML_${id}`}>
            {rendered && typeof html === 'string' && HTMLParser(getLocalizedString(zdlib.currentLanguage, html))}
        </div>
    ) : null;
});

export default SE_Html;

