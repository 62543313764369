import React from "react";
import {DropDownMenu} from "@zetadisplay/engage-components";
import {getLocalizedString} from "../localization/localizationUtils";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import {Button} from "@zetadisplay/zeta-ui-components";
import Popover from "@mui/material/Popover";
import {observer} from "mobx-react";

export const PreviewResolutionMenu = observer(({zdlib}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);


    if (!zdlib.responsive || !zdlib.editor.previewSizes || zdlib.editor.previewSizes.length < 2) return null;

    const sizeToString = ({label, width, height}, inclLabel) => label && inclLabel
        ? `${label} (${width} x ${height})`
        : `${width} x ${height}`;

    const isSizeCurrent = ({width, height}) => width === zdlib.env.previewSize?.width && height === zdlib.env.previewSize?.height;

    const btnClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleMenuItemClick = ({width, height}) => {
        zdlib.env.previewSize = {width, height};
        setAnchorEl(null);
    };



    const getMenuItem = (size, index) => {
        const label = sizeToString(size, true);

        return (
            <MenuItem
                key={`${label}${index}`}
                style={{ minWidth: 120 }}
                selected={isSizeCurrent(size)}
                onClick={() => handleMenuItemClick(size)}
            >
                <ScreenIcon {...size}/>
                {label}
            </MenuItem>
        );
    };


    const size = zdlib.editor.previewSizes.find(isSizeCurrent)
        || zdlib.env.previewSize
        || zdlib.env.viewArea;
    const selectedLabel = sizeToString(size, false)


    return (
        <>
            <Button onClick={btnClick}
                style={{ minWidth: 'unset' , color: 'white', position: 'relative', top: 3, left: 12}}
                >
                <span className="material-icons">
                    <ScreenIcon {...size}/>
                </span>
                {selectedLabel}

                <Icon style={{ marginLeft: 8, top: 1, position: 'relative' }}>{'expand_more'}</Icon>
            </Button>

            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                onClose={handleClose}
            >
                {zdlib.editor.previewSizes?.map(getMenuItem)}
            </Popover>
        </>
    );
})

const ScreenIcon = ({width, height}) => (
    <span className="material-icons" style={{marginRight: 4}}>
        {height > width ? 'crop_portrait' : 'crop_landscape'}
    </span>
);