import { Box, Icon, Typography } from '@mui/material';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { observer } from 'mobx-react';
import React from 'react';

import { getLocalizedString } from '../localization/localizationUtils';
import { addNewItem } from './EditorControls/List/listUtils';
import {SortableList} from "./EditorControls/List/SortableList";

const useStyles = makeStyles()(() => ({
    addButton: {
        marginTop: 8,
        marginLeft: 2,
        '& + button': {
            marginLeft: 2,
        },
    },
    menuIcon: {
        marginRight: '8px',
    },
}));


const SE_List = (props) => {
    const { spotData, tHook, zdlib } = props;
    const items = spotData.value;
    const { hideAdd, emptyListLabel } = spotData.ui;

    const { classes } = useStyles();

    const itemTypes = (spotData.ui.getItemTypes || spotData.getItemTypes)?.call(spotData);
    const isEmpty = !items || items.length === 0;

    const getAddButtons = () => {
        const types = itemTypes || [{ label: tHook.trans('common.action.add') }];
        const typesDefined = !!itemTypes;
        return types.map((itemType, index) => {
            return (
                <div key={itemType.id || 'btn_' + index}>
                    <Button
                        disabled={itemType.disabled}
                        className={classes.addButton}
                        onClick={() => {
                            void addNewItem(spotData, typesDefined ? itemType : undefined);
                        }}
                    >
                        <Icon fontSize="small">{'add'}</Icon>
                        {itemType.icon && <Icon fontSize="small">{itemType.icon}</Icon>}
                        {getLocalizedString(zdlib.currentLanguage, itemType.label)}
                    </Button>
                </div>
            );
        });
    };

    //console.log('.<.<.<.<.<.<', spotData.ui._selected)

    return (
        <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
            {isEmpty ? (
                <Typography
                    variant="subtitle2"
                    style={{ textTransform: 'none', marginLeft: '8px', marginTop: '16px', marginBottom: '8px' }}
                >
                    {getLocalizedString(zdlib.currentLanguage, emptyListLabel)}
                </Typography>
            ) : (
                <SortableList
                    zdlib={zdlib}
                    spotData={spotData}
                    tHook={tHook}
                    selectedId={spotData.ui._selected}
                />
            )}
            {!hideAdd && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    marginTop={2}
                >
                    {getAddButtons()}
                </Box>
            )}
        </Box>
    );
};

export default observer(SE_List);

/*
shouldCancelStart={() => {
                        const editOptions = getItemRestrictions(spotData);
                        return editOptions === false || editOptions.hideMove;
                    }}
                    updateBeforeSortStart={({ node, index }) => {
                        spotData.ui._selected = spotData.value[index]._itemId;
                        return setDrag(spotData.value[index]);
                    }}
                    onSortEnd={({ oldIndex, newIndex, collection, isKeySorting }) => {
                        setDrag(undefined).then(() => {
                            moveItem(spotData, oldIndex, { newIndex });
                        });
                    }}
 */