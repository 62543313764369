import {ArrowDownward, ArrowUpward, ContentCopy, Delete} from "@mui/icons-material";
import React from 'react';
import {deleteItem, duplicateItem, getItemRestrictions, moveItem} from "./listUtils";
import {SortableListItem} from "./SortableListItem.jsx";


export const getListItemComponent = (item, index,
                            {   spotData,
                                isDraggingItem,
                                isDragOverlay,
                                tHook,
                                selectedId,
                                zdlib,
                            }) => {
    const { hideEdit } = spotData.ui;
    const itemProps = (spotData.ui.getListItemProps || spotData.getListItemProps)?.call(spotData, item, index);
    
    const editOptions = getItemRestrictions(spotData, item);
    const actions = editOptions !== false && [];
    if (actions) {
        if (!editOptions.hideMove) {
            actions.push({
                name: 'move_u',
                label: tHook.trans('common.action.move_up'),
                isDisabled: () => spotData.value.length < 2,
                icon: ArrowUpward,
                handler: () => {
                    moveItem(spotData, index, { delta: -1 });
                },
            });
            actions.push({
                name: 'move_d',
                label: tHook.trans('common.action.move_down'),
                isDisabled: () => spotData.value.length < 2,
                icon: ArrowDownward,
                handler: () => {
                    moveItem(spotData, index, { delta: 1 });
                },
            });
        }
        if (!editOptions.hideCopy) {
            actions.push({
                name: 'copy',
                label: tHook.trans('common.action.copy'),
                icon: ContentCopy,
                handler: async () => {
                    const newItem = await duplicateItem(spotData, item);
                    if (newItem) spotData.ui._selected = newItem._itemId;
                },
            });
        }
        if (!editOptions.hideDelete) {
            actions.push({
                name: 'del',
                label: tHook.trans('common.action.delete'),
                icon: Delete,
                handler: () => {
                    void deleteItem(spotData, item);
                },
            });
        }
    }
    return (
         itemProps && (
            <SortableListItem
                key={item._itemId}
                id={item._itemId}
                index={index}
                isDragOverlay={isDragOverlay}
                isDraggingItem={isDraggingItem}
                item={item}
                spotData={spotData}
                selected={item._itemId === selectedId}
                hideEdit={hideEdit || itemProps.hideEdit}
                disableEdit={itemProps.disableEdit}
                onSelectClick={() => {
                    spotData.ui._selected = item._itemId;
                }}
                onEditClick={() => {
                    spotData.ui._editing = item._itemId;
                }}
                actions={actions}
                zdlib={zdlib}
                {...itemProps}
            />
        )
    );
}

