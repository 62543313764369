import { useTranslation } from '@zetadisplay/zeta-localization';
import React from 'react';

import { getLocalizedString } from '../localization/localizationUtils';
import HTMLParser from '../UIComponents/HTMLParser';
import { useDialog, usePopper, useSnackbar } from '../UIComponents/InfoHooks';

export default function InfoHandler({ zdlib, editorIntf }) {
    const showPopper = usePopper();
    const showDialog = useDialog();
    const showSnackbar = useSnackbar();
    const tHook = useTranslation();

    React.useEffect(() => {
        if (!zdlib || !showPopper || !showSnackbar || !showDialog) return;

        zdlib.editor.showInfo = (element, info) => {
            if (!info || !element) return;

            //let close;

            if (element.component) {
                const component = zdlib.components.getItem(element.component);
                if (component && component.ui && !component.ui.info) {
                    const onClose = info.onClose;
                    component.ui.info = {
                        ...info,
                        ...{
                            hideButton: true,
                            key: `_popc_${component.id}`,
                            onClose: () => {
                                //console.log('****close');
                                component.ui.info = null;
                                if (onClose) onClose(info);
                            },
                        },
                    };
                }

                return () => {
                    //close(findIndex(component && component.ui && component.ui.info));
                    if (component && component.ui) component.ui.info = null;
                };
            }
            const options = {
                ...info,
                ...{
                    element,
                    parseFunction: HTMLParser,
                    title: info.translate
                        ? tHook.trans(info.title)
                        : getLocalizedString(zdlib.currentLanguage, info.title),
                    text: info.translate
                        ? tHook.trans(info.text)
                        : getLocalizedString(zdlib.currentLanguage, info.text),
                    linkText: info.translate
                        ? tHook.trans(info.linkText)
                        : getLocalizedString(zdlib.currentLanguage, info.linkText),
                },
            };
            return showPopper(options);
        };

        zdlib.editor.showMessage = (
            message,
            timeout = 6,
            action = null,
            onAction = null,
            type = 'info',
            translate = false
        ) => {
            showSnackbar({
                text: translate ? tHook.trans(message) : getLocalizedString(zdlib.currentLanguage, message),
                parseFunction: HTMLParser,
                timeout: timeout * 1000,
                action,
                color: type,
            });
        };

        zdlib.editor.messageDialog = (options) => {
            const key = `msg${options.key || new Date().getTime()}`;
            const text = options.text || options;
            return new Promise((resolve, reject) => {
                try {
                    const close = showDialog({
                        key,
                        parseFunction: HTMLParser,
                        text: options.translate ? tHook.trans(text) : getLocalizedString(zdlib.currentLanguage, text),
                        icon: options.icon,
                        title: options.translate
                            ? tHook.trans(options.title)
                            : getLocalizedString(zdlib.currentLanguage, options.title),
                        //disableBackdropClick: true,
                        disableEscapeKeyDown: true,
                        buttons:
                            options.buttons &&
                            options.buttons.map((label, index) => ({
                                label: options.translate
                                    ? tHook.trans(label)
                                    : getLocalizedString(zdlib.currentLanguage, label),
                                onClick: (ev, reason) => {
                                    close();
                                    resolve(index);
                                },
                            })),
                        onShow: (info) => {
                            editorIntf.dialogs.push({ key: info.key, close: info.close });
                        },
                        onClose: (info, reason) => {
                            if (reason === 'backdropClick') {
                                resolve(0);
                            }
                            const index = editorIntf.dialogs.findIndex((item) => item && item.key === info.key);
                            if (index >= 0) {
                                editorIntf.dialogs.splice(index, 1);
                            }
                        },
                    });
                } catch (error) {
                    reject(error);
                }
            });
        };
    }, [zdlib, showPopper, showDialog, showSnackbar, editorIntf.dialogs, tHook]);

    return null;
}
