import React from 'react';
import { Error, Info } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button, Logo } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import CELogo from '../assets/images/Zeta-54-ContentEditor.svg';
import WorkspaceSelection from '../components/WorkspaceSelection/WorkspaceSelection';
import { errorCodeEnum } from '../templateContext/errorCodeEnum';
import { getVersionStr } from '../UIComponents/getVersionStr';
import UserControls from './UserControls';

const useStyles = makeStyles()(() => ({
    container: {
        height: '100%',
        backgroundColor: 'white',
        pointerEvents: 'auto',
    },
    main: {
        display: 'grid',
        gridTemplateColumns: '1fr 60px auto 1fr',
        alignItems: 'start',
        gridGap: '16px 24px',
        padding: 16,
    },
    label: {
        alignSelf: 'center',
        maxWidth: 220,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        color: '#999999', //theme.palette.secondary.disabledText,
        fontWeight: 600,
    },
    header: {
        marginBottom: 8,
    },
    ceHeader: {
        marginBottom: '32px',
    },
    icon: {
        gridColumnStart: 2,
        justifySelf: 'end',
    },
    text: {
        gridColumnStart: 3,
        justifySelf: 'start',
        maxWidth: '380px',
    },
    primaryButton: {
        marginTop: '48px',
    },
    version: {
        gridColumnStart: 4,
        justifySelf: 'start',
        color: '#999999',
        fontSize: 10,
        fontWeight: 400,
        letterSpacing: 2.2,
        position: 'relative',
        top: '18px',
        left: '4px',
    },
    error: {
        fontStyle: 'italic',
        color: '#999999',
        fontSize: 14,
    },
    viewHeader: {
        borderBottom: '1px solid #ccc',
        width: '80%',
        margin: '0 auto',
        display: 'flex',
        height: 60,
        alignItems: 'center',
        marginBottom: 12,
    },
}));

const ErrorIcon = ({ classes, errorCode }) => (
    <Error htmlColor={'#DC0000'} className={classes.icon} titleAccess={`ERROR: ${errorCodeEnum[errorCode]}`} />
);

const Header = ({ classes, label }) => (
    <Typography variant={'h4'} className={classes.header}>
        {label}
    </Typography>
);

const NoWorkspace = ({ invalidWorkspace, classes, errorCode, message }) => {
    const tHook = useTranslation();
    return (
        <>
            {invalidWorkspace ? (
                <>
                    <ErrorIcon classes={classes} errorCode={errorCode} message={message} />
                    <div className={classes.text}>
                        <Header label={tHook.trans('editor.error.workspace.header')} classes={classes} />
                        <Typography>{tHook.trans('editor.error.workspace.text')}</Typography>
                    </div>
                </>
            ) : (
                <>
                    <Info htmlColor={'#3574C2'} className={classes.icon} />
                    <Header label={tHook.trans('editor.error.noworkspace.header')} classes={classes} />
                </>
            )}
        </>
    );
};

const OpenButton = ({ classes, zdlib }) => {
    const tHook = useTranslation();
    return (
        <Button
            label={tHook.trans('editor.error.notemplate.action')}
            kind={'primaryAction'}
            className={classes.primaryButton}
            onClick={() => {
                zdlib.editor.open();
            }}
        />
    );
};

const NoSourcePath = ({ classes, zdlib }) => {
    const tHook = useTranslation();

    return (
        <>
            <Info htmlColor={'#3574C2'} className={classes.icon} />
            <div className={classes.text}>
                <Header label={tHook.trans('editor.error.notemplate.header')} classes={classes} />

                <OpenButton classes={classes} zdlib={zdlib} tHook={tHook} />
            </div>
        </>
    );
};

const InvalidMediaItem = ({ errorCode, message, zdlib, classes }) => {
    const tHook = useTranslation();
    return (
        <>
            <ErrorIcon classes={classes} errorCode={errorCode} message={message} />
            <div className={classes.text}>
                <Header label={tHook.trans('editor.error.invalidtemplate.header')} classes={classes} />
                {errorCode === errorCodeEnum.invalidMediaItem && (
                    <Typography>{tHook.trans('editor.error.invalidtemplate.text')}</Typography>
                )}
                {errorCode === errorCodeEnum.sourcePathNotFound && (
                    <Typography>{tHook.trans('editor.error.templatenotfound.text')}</Typography>
                )}
                {errorCode === errorCodeEnum.importError && (
                    <Typography>{tHook.trans('editor.error.import.text')}</Typography>
                )}
                {errorCode === errorCodeEnum.templateNotShared && (
                    <Typography>{`You don't have access to this template.`}</Typography>
                )}
                <br/>
                <Typography>{tHook.trans('editor.error.invalidtemplate.prompt')}</Typography>
                <OpenButton classes={classes} zdlib={zdlib} />
            </div>
        </>
    );
};

const getErrorComponent = (props) => {
    const { errorCode } = props;
    switch (errorCode) {
        case errorCodeEnum.noWorkspace:
            return <NoWorkspace {...props} />;
        case errorCodeEnum.invalidWorkspace:
            return <NoWorkspace {...props} invalidWorkspace />;
        case errorCodeEnum.noSourcePath:
            return <NoSourcePath {...props} />;
        default:
            return <InvalidMediaItem {...props} />;
    }
};

const LandingPage = ({ zdlib, errorCode, message }) => {
    const { classes } = useStyles();
    const tHook = useTranslation();
    const appTitle = tHook?.trans('editor.application.title');

    return (
        <div className={classes.container}>
            <div className={classes.viewHeader}>
                <Logo height={32} className={classes.icon} />

                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'flex-end' }}>
                    <WorkspaceSelection />

                    <UserControls />
                </Box>
            </div>

            <div className={classes.main}>
                <img src={CELogo} alt={'Content Creator'} width={44} className={classes.icon} />

                <Typography variant={'h1'} className={`${classes.text} ${classes.ceHeader}`}>
                    {appTitle || 'Content Creator'}
                </Typography>

                <Typography variant="caption" color="textPrimary" classes={{ caption: classes.version }}>
                    {getVersionStr()}
                </Typography>

                {getErrorComponent({ errorCode, zdlib, classes, message })}
            </div>
        </div>
    );
};

export default LandingPage;
