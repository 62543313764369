import type { LibraryModelsMedia} from '@zetadisplay/engage-api-client';
import { LibraryModelsMediaTypes } from '@zetadisplay/engage-api-client';
import type { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { getPreviewUrl, getThumbnailUrl, isVideo } from '@zetadisplay/engage-components/utils/media';

import { processEnv } from "../../config/processEnv";

export interface StudioPortalMediaItem {
    indexI: string;
    durationMs?: number;
    nameS: string;
    formatWidthI?: number | null;
    formatHeightI?: number | null;
}

interface CustomItemFields {
    duration: number;
    ext: string;
    imagePreviewUrl?: string;
    imageUrl?: string;
    mediaType: string;
    naturalHeight?: number | null;
    naturalWidth?: number | null;
    previewUrl: string;
    thumbnailUrl: string | undefined;
    type: string;
    url: string | undefined;
    videoHeight?: number | null;
    videoPreviewUrl?: string;
    videoUrl?: string;
    videoWidth?: number | null;
    _displayName: string;
    _ext: string;
    _isVideo: boolean;
    _url: string | undefined;
}

const getMediaType = (item: DiscriminatedEntity<LibraryModelsMedia>): string => {
    switch (item.type) {
        case LibraryModelsMediaTypes.Video:
            return 'video';
        case LibraryModelsMediaTypes.TemplateZetaCast:
            return 'template';
        case LibraryModelsMediaTypes.WebPage:
            return 'url';
        default:
            return 'image';
    }
};

/**
 * Adds portal compatible fields to an Engage media item
 * @param mediaFile Engage media item
 * @return The original Engage media item augmented with portal compatible fields,
 * that templates MAY use.
 * e.g. {width, mediaFile.width, formatWidthI: mediaFile.width, }
 * Some field have same names but different units or formats; those are converted in favor of portal
 */
export const createCompatibleImageObject = (
    mediaFile: DiscriminatedEntity<LibraryModelsMedia>
): DiscriminatedEntity<LibraryModelsMedia> & StudioPortalMediaItem & CustomItemFields => {
    const { width, height, duration, ext, id, name } = mediaFile;

    const extension = ext?.substring(1).toLowerCase() || ''; // portal returns file ext without dot

    const previewUrl = getPreviewUrl(mediaFile, undefined, processEnv.API_ROOT) || '';

    const portalObj: StudioPortalMediaItem & CustomItemFields = {
        _displayName: name,
        indexI: id,
        mediaType: getMediaType(mediaFile),
        nameS: name,
        previewUrl,
        type: 'Media',
        thumbnailUrl: getThumbnailUrl(mediaFile, undefined,  processEnv.API_ROOT) , //getThumbnailUrl(mediaFile),
        duration: duration * 1000, // zeta-api returns duration as ms
        formatWidthI: width,
        formatHeightI: height,
        _url: previewUrl,
        url: previewUrl,
        ext: extension,
        _ext: extension,
        _isVideo: mediaFile.type === LibraryModelsMediaTypes.Video,
        /*
        templates shouldn't use these --> omit..
         status: status.toLowerCase(),
        created: created.toISOString(),
        modified: created.toISOString(),
        lastWriteTime: created.toISOString(),*/
    };
    if (isVideo(mediaFile)) {
        portalObj.videoWidth = width;
        portalObj.videoHeight = height;
        portalObj.durationMs = duration * 1000; // portal returns duration as ms
        portalObj.videoUrl = previewUrl;
        portalObj.videoPreviewUrl = previewUrl;
    } else {
        portalObj.naturalWidth = width;
        portalObj.naturalHeight = height;
        portalObj.imagePreviewUrl = previewUrl;
        portalObj.imageUrl = previewUrl;
    }

    // @ts-expect-error - TS doesn't know about import meta
    return { ...mediaFile, ...portalObj };
};
