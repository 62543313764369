import { Icon } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { observer } from 'mobx-react';
import React from 'react';

import { getLocalizedString } from '../localization/localizationUtils';

const useStyles = makeStyles()(() => ({
    textButton: {
        fontSize: 12,
    },
    outlinedButton: {
        marginLeft: -4,
        marginTop: 4,
        marginBottom: 2,
    },
}));

const SE_Button = observer((props) => {
    const { spotData, events, zdlib } = props;
    const { classes } = useStyles();
    const tHook = useTranslation();
    const uiProps = { ...spotData.ui, ...(spotData.ui.props || {}) };

    // changed: color is now always primary; variant is either text or outlined
    if (uiProps.variant === 'contained') uiProps.variant = 'outlined';

    const { variant, label, iconAfter, disabled, icon, progress, enabledOnProgress, translate } = uiProps;

    const translatedLabel = translate ? tHook.trans(label) : getLocalizedString(zdlib.currentLanguage, label);

    const onClick = (event) => {
        if (events.onClick) events.onClick(event);
    };

    if (!label && !icon) {
        console.warn(`Button component id="${spotData.id}" doesn't have a label or icon: nothing to show`);
        return null;
    }

    return (
        <Button
            label={translatedLabel}
            busy={progress}
            color="primary"
            kind={variant === 'outlined' ? 'secondaryAction' : 'defaultAction'}
            startIcon={icon && !iconAfter ? icon : undefined}
            endIcon={icon && iconAfter ? icon : undefined}
            disabled={disabled}
            size={variant === 'outlined' ? 'medium' : 'small'}
            classes={label && { textPrimary: classes.textButton, outlinedPrimary: classes.outlinedButton }}
            {...events}
            onClick={onClick}
        >
            {spotData.popup && (spotData.popup.popupId || spotData.popup.id) ? (
                <Icon style={{ marginLeft: 8 }}>
                    {spotData.popup.inline || props.popup ? 'expand_more' : 'arrow_forward'}
                </Icon>
            ) : null}
        </Button>
    );
});

export default SE_Button;
