
// This file is used to connect to the local development server.


const devHost = 'http://localhost:5000';


export class LocalDevServerConnector {

    constructor(zdlib, editorIntf) {
        let connecting = false;
        const connect = () => {
            if (connecting) return Promise.resolve(false);

            connecting = true;
            return new Promise(resolve => {
                const evSource = new EventSource(`${process.env.NODE_ENV !== 'production' ? devHost : ''}/sse`);
                evSource.onopen = ev => {
                    console.log('Dev server connected');

                    const params = new URLSearchParams(window.location.search);
                    const ws = params.get('ws') || '';
                    console.log('Local workspace: ', ws || '(default)')
                    void fetch(`${process.env.NODE_ENV !== 'production' ? devHost : ''}/localws/${ws}`)

                    this.connected = true;
                    connecting = false;
                    evSource.onmessage = ev => {
                        const msg = JSON.parse(ev.data)
                        //console.log('>>', msg)
                        if (msg === 'reimport') {
                            editorIntf.forceReload();
                        } else  if (msg === 'refresh') {
                            document.location.reload();
                        }
                    }
                    resolve(true);
                }

                evSource.onerror = ev => {
                    connecting = false;
                    evSource.close();
                    let interval = this.connected && window.setInterval(async () => {
                        this.connected = false;
                        console.log('...reconnecting to dev server')
                        const reconnected = await connect();
                        if (reconnected) {
                            clearInterval(interval);
                            clearTimeout(timeout);
                            void this.send(JSON.stringify(zdlib.editor.getWorkspaceProperties()), 'workspace')
                        }
                    }, 1000);
                    let timeout = this.connected && window.setTimeout(() => {
                        this.connected = false;
                        console.log('%cDev server disconnected.', 'background-color:red;color:white',)
                        clearInterval(interval);
                        clearTimeout(timeout);
                    }, 15000);
                    resolve(false);
                }
            })
        }
        void connect();
    }

    connected = false;
    pending = undefined;

    send = (data, endpoint = 'templateData') => {
        const params = new URLSearchParams(document.location.search);
        if (!params.has('livePreview')) return Promise.resolve(false);
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        return fetch(`${devHost}/${endpoint}`, {
            body: data,
            method: 'POST',
            headers,
        });
    }

}