import {importTemplate} from "ZetaTemplate/importTemplate";

import {processEnv} from "../config/processEnv";
import {errorCodeEnum} from "./errorCodeEnum";

export const loadTemplate = async ({zdlib,
                              editorIntf,
                              sourcePath, }) => {
    let baseUrl;
    let devRemoteTemplate, loaded = false;
    const editor = zdlib.editor;
    try {
        if (zdlib.env.isLocal) {
            if (sourcePath?.includes('files/'))
                baseUrl = `${processEnv.CLIENT_ROOT}/${sourcePath}/`;
            else {
                devRemoteTemplate = true; //process.env.NODE_ENV !== 'production';
                baseUrl = `${processEnv.PORTAL}/templates/${sourcePath}/`;
            }
        } else {
            baseUrl = `${document.location.origin}/templates/${sourcePath}/`;
        }

        const params = new URLSearchParams(window.location.search);
        const workspaceId = params.get('workspace') || params.get('workspaceId') || editorIntf.workspaceId;

        loaded = await importTemplate({
            zdlib,
            baseUrl,
            devRemoteTemplate,
            editor,
            editorIntf,
            sourcePath,
            workspaceId,//: editorIntf.workspaceId,
            onBeginLoad: () => {
                zdlib.pause();
                editorIntf.history = [];
                editorIntf.observeAll(false);
                editorIntf.closeAllSubpages();
                editor.activePageId = null;
                editorIntf.resetMissingAssets();
                zdlib.currentLanguage = editor.user.locale.substring(0, 2);
                editorIntf.endEditing();
                editor.margin = {left: 0, top: 0, right: 0, bottom: 0};
                // Set editor temporarily to undefined
                // Needed for some legacy templates that expect editor to be set only after the template itself has finished loading
                // Change: set it to undefined in importTemplate, only when legacy template is detected
                //zdlib.editor = undefined;
            }
        });
        // Restore zdlib.editor if it has been set to undefined when loading template
        // (Needed for some legacy templates that expect editor to be set only after the template itself has finished loading)
        zdlib.editor = editor;
        if (!loaded) {
            return 'ok';
        }
        editorIntf.resetChanges();
        editorIntf.observeAll(true);
        zdlib.env.zoomTo(null, {duration: 0});
        if (!editorIntf.isGauddiTemplate) void editorIntf.loadChangesFromLocal();

        // << moved from here

        editorIntf.beginEditing();
        zdlib.editorLoaded();
        zdlib.beginEditing();

        // >> to here
        const pageId = params.get('page');
        if (pageId && zdlib.pages.find((page) => page.id === pageId)) {
            zdlib.editor.activePageId = pageId;
        } else {
            zdlib.editor.activePageId = zdlib.pages?.length === 1 ? zdlib.pages[0].id : null;
        }

        console.log(`** Editor version ${zdlib.editor.version} ${
            Number(processEnv.PATCH) > 0 ? `(patch ${processEnv.PATCH})` : ''
        }`);
    }
        catch (err) {
                console.error(err);
                zdlib.editor = editor;
                return { error: errorCodeEnum.importError, message: err.message };
            }
        return 'ok';
}
