import { ChevronRight } from '@mui/icons-material';
import { Box, Drawer, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { observer } from 'mobx-react';
import React from 'react';

import { getLocalizedString } from '../localization/localizationUtils';
import { getSortedPages } from '../State/PageHandler';
import HTMLParser from '../UIComponents/HTMLParser';
import { Use_mq_Narrow } from './mediaQueries';

const useStyles = makeStyles()((theme) => ({
    primary: {
        paddingTop: 4,
        color: theme.palette.background.text,
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: 1.4,
        letterSpacing: '0.47px',
        overflow: 'hidden',
        //whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        userSelect: 'none',
        cursor: 'pointer',
        maxWidth: theme.controls.width - 72,
    },
    drawerPaper: {
        pointerEvents: 'auto',
        backgroundColor: theme.palette.background.main,
        overflowX: 'hidden',
    },
    listItem: {
        width: 'auto',
        paddingTop: 14,
        paddingBottom: 14,
        marginRight: 0,
    },
    listItemSecondaryAction: {
        pointerEvents: 'auto',
        cursor: 'pointer',
        userSelect: 'none',
        //marginRight: -8,
    },
}));

const PageNavigation = observer((props) => {
    const theme = useTheme();
    const { classes } = useStyles();
    const { zdlib, editorIntf } = props;
    const narrow = Use_mq_Narrow();

    function handleClick(id) {
        zdlib.editor.activePageId = id;
    }

    const pages = getSortedPages(zdlib);

    if (pages.length < 2) {
        if (pages.length === 1) zdlib.editor.activePageId = pages[0].id;
        return null;
    }

    function getPageMenuItems() {
        return pages.map((page) => {
            //const click = () => handleClick(page.id);
            const click = handleClick.bind(null, page.id);
            return (
                <ListItem key={page.id} classes={{ button: classes.listItem }} onClick={click}>
                    <ListItemText
                        classes={{ primary: classes.primary }}
                        primary={HTMLParser(getLocalizedString(zdlib.currentLanguage, page.title || page.id))}
                    />
                    <ListItemSecondaryAction classes={{ root: classes.listItemSecondaryAction }} onClick={click}>
                        <ChevronRight color="primary" fontSize="large" />
                    </ListItemSecondaryAction>
                </ListItem>
            );
        });
    }

    return (
        <Drawer
            variant="persistent"
            anchor="left"
            classes={{ paper: classes.drawerPaper }}
            open={!zdlib.editor.activePageId && !zdlib.editor.activeSubpage}
        >
            <Box
                minWidth={narrow ? undefined : editorIntf.layoutConfig.controls.width}
                maxWidth={editorIntf.layoutConfig.controls.width}
                height={1}
                bgcolor={theme.palette.background.main}
            >
                <Box height={editorIntf.layoutConfig.infobar.height}></Box>
                <List>{getPageMenuItems(pages)}</List>
            </Box>
        </Drawer>
    );
});

export default PageNavigation;
