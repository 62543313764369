export const setEditorUser = (zdlib, user) => {
    const handler = {
        get(target, p, receiver) {
            const prop = target[p];
            return prop && typeof prop === 'object' ? new Proxy(prop, handler) : prop;
        },
        set(target, p, newValue, receiver) {
            return true
        },
        apply(target, thisArg, argArray) {
        }
    }
    if (!zdlib.editor.user) {
        Object.defineProperty(zdlib.editor, 'user', {
            configurable: false,
            get() {
                return new Proxy(user, handler)
            }
        })
    }
}