import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { observer } from 'mobx-react';
import React from 'react';

import { getLocalizedString } from '../localization/localizationUtils';
import { changeValue, eventNames } from './SE__Base';

class SE_RadioButtons extends React.Component {
    getBtns = (button, index) => {
        const { events, zdlib } = this.props;
        const label = getLocalizedString(zdlib.currentLanguage, button.label);
        return (
            <RadioButton
                key={`rbtn_${button.value ? JSON.stringify(button.value) : label}`}
                index={index}
                value={button.value}
                label={label}
                disabled={button.disabled}
                events={events}
            />
        );
    };

    handleChange = (spotData, forceOnChangeCallback, event, newValue) => {
        changeValue(event, spotData, newValue, forceOnChangeCallback);
    };

    render() {
        const { spotData, forceOnChangeCallback, zdlib } = this.props;
        const buttons = spotData.ui.buttons || spotData.buttons;

        if (!buttons || buttons.length === 0) {
            console.warn(`RadioButtons component id="${spotData.id}" doesn't have any buttons: nothing to show`);
            return null;
        }

        if (!this.changeHandler) this.changeHandler = this.handleChange.bind(this, spotData, forceOnChangeCallback);

        return (
            <>
                <div style={{ marginBottom: 8, fontSize: 14 }}>
                    {getLocalizedString(zdlib.currentLanguage, spotData.ui.label)}
                </div>
                <FormControl component="fieldset">
                    <RadioGroup
                        value={spotData.value}
                        onChange={(ev) => {
                            this.changeHandler(ev, ev.target.value);
                        }}
                    >
                        {buttons.map(this.getBtns)}
                    </RadioGroup>
                </FormControl>
            </>
        );
    }
}

const useStyles = makeStyles()(() => ({
    label: {
        color: '#999 !important',
    },
}));

const RadioButton = ({ index, value, disabled, label, events }) => {
    const { classes } = useStyles();
    const eventHandlers = {};
    eventNames.forEach((name) => {
        if (events[name]) eventHandlers[name] = events[name];
    });
    return (
        <FormControlLabel
            key={`radio_${index}`}
            value={value}
            classes={{ label: classes.label }}
            control={
                <Radio color="primary" disabled={disabled} size="small" style={{ paddingTop: 2, paddingBottom: 4 }} />
            }
            label={label}
            {...eventHandlers}
        />
    );
};

export default observer(SE_RadioButtons);
