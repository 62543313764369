import './App.css';

import { OidcProvider } from '@axa-fr/react-oidc';
import { CssBaseline } from '@mui/material';
import { AuthInfo, AuthLoader } from '@zetadisplay/engage-components/modules/auth';
import { TranslationProvider } from '@zetadisplay/zeta-localization';
import { Theme } from '@zetadisplay/zeta-ui-components';
import { configure, toJS } from 'mobx';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import oidcConfiguration, { DEFAULT_OIDC_CONFIGURATION_NAME } from 'config/oidcConfiguration';
import EditorRoot from './EditorRoot';
import EditorTheme from './EditorTheme';
import { locales } from 'locales';
import ProtectedDependencyInjectionProvider from '../modules/ProtectedDependencyInjectionProvider/ProtectedDependencyInjectionProvider';
import EditorIntf from 'State/EditorIntf';
import { createZDLib } from 'ZetaTemplate/ZDLib';
import { setTemplateVisibility } from '../ZetaTemplate/utils/setTemplateVisibility';
import {processEnv} from "./../config/processEnv";


window.mobx = { toJS };

configure({
    enforceActions: 'never',

});

const AuthAndHideTemplate = (authInfoProps) => {
    React.useEffect(() => {
        setTemplateVisibility(false);
    }, []);
    return <AuthInfo {...authInfoProps} />;
};

const AuthenticatingComponent = () => <AuthAndHideTemplate text="engage.auth.redirect" />;

const CallbackErrorComponent = () => (
    <Theme>
        <AuthAndHideTemplate
            action={() => window.location.reload()}
            actionText="common.action.refresh"
            text="engage.auth.login.error"
        />
    </Theme>
);
const CallbackSuccessComponent = () => <AuthAndHideTemplate text="engage.auth.login.success" />;
const SessionLostComponent = () => (
    <Theme>
        <AuthAndHideTemplate
            action={() => window.location.reload()}
            actionText="common.action.refresh"
            text="engage.auth.session.lost"
        />
    </Theme>
);


const App = () => {
    const [globals, setGlobals] = React.useState({});

    React.useEffect(() => {
        if (!globals.zdlib) {
            const zdlib = createZDLib(processEnv.SE_VERSION);
            const editorIntf = new EditorIntf(zdlib);
            if (zdlib.env.isLocal) {
                window.editorIntf = editorIntf;
                window.zdlib = zdlib;
            }
            setGlobals({ editorIntf, zdlib });
        }
    }, [globals.zdlib]);
    

    return (
        <>
            <CssBaseline />
            <Router>
                <TranslationProvider defaultLocale="en-US" translations={locales}>
                    <OidcProvider
                        authenticatingComponent={AuthenticatingComponent}
                        authenticatingErrorComponent={CallbackErrorComponent}
                        callbackErrorComponent={CallbackErrorComponent}
                        callbackSuccessComponent={CallbackSuccessComponent}
                        configuration={oidcConfiguration}
                        configurationName={DEFAULT_OIDC_CONFIGURATION_NAME}
                        loadingComponent={AuthLoader}
                        sessionLostComponent={SessionLostComponent}
                    >
                        <EditorTheme editorIntf={globals.editorIntf} nested={false} dark={false}>
                            <ProtectedDependencyInjectionProvider>
                                <EditorRoot zdlib={globals.zdlib} editorIntf={globals.editorIntf} />
                            </ProtectedDependencyInjectionProvider>
                        </EditorTheme>
                    </OidcProvider>
                </TranslationProvider>
            </Router>
        </>
    );
};

export default App;
