import React from 'react';
import {Typography} from "@mui/material";
import { LibraryModelsMediaTypes } from '@zetadisplay/engage-api-client';
import { isMediaFile } from '@zetadisplay/engage-components/models';
import {FilterFields} from "@zetadisplay/engage-components/modules/search";
import {getPreviewUrl} from "@zetadisplay/engage-components/utils/media/index";
import { Button } from '@zetadisplay/zeta-ui-components';
import { observer } from 'mobx-react';

import {processEnv} from "../config/processEnv";
import MediaPicker from '../LibraryView_engage/MediaPicker';
import {MediaPickerControls} from "../LibraryView_engage/MediaPickerControls";
import {changeValue} from "./SE__Base";


const getDisplayStr = (audioFile, tHook) => {
    if (!audioFile?.url) return tHook.trans('common.notification.nothing_selected');
    return `${audioFile.name} • ${audioFile.duration} s`;
}

export const SE_Audio = observer((props) => {
    const { spotData, editorIntf, tHook } = props;
    const [pickerOpen, setPickerOpen] = React.useState(false);
    const status = React.useRef({});
    const changeEvent = React.useRef(spotData.value ? 'init' : null);

    const close = () => {
        if (status.current.pickerOpen) {
            setPickerOpen(false);
        }
    };

    React.useEffect(() => {
        if (status.current.pickerOpen === pickerOpen) {
            return;
        }

        const index = editorIntf.dialogs.findIndex((item) => item && item.key === 'selectfile');

        if (pickerOpen) {
            if (index < 0) editorIntf.dialogs.push({ key: 'selectfile', close });
        } else {
            if (index >= 0) editorIntf.dialogs.splice(index, 1);
        }
        status.current.pickerOpen = pickerOpen;
    }, [pickerOpen, editorIntf.dialogs]);



    const clear = () => {
        changeValue({ type: 'AudioCleared' }, spotData, null);
    };

    const handleSelectClick = React.useCallback(
        (item) => {
            if (item.id === spotData?.value?.id) {
                //console.log('>>>>>>>>>same:', item.name)
                return;
            }
            changeEvent.current = 'AudioSelected';
            const url = getPreviewUrl(item, undefined, processEnv.API_ROOT) || '';
            changeValue({ type: changeEvent.current }, spotData,
                    {...item, ...{
                        url,
                        // remove dot from ext,
                        // just to make it consistent with image and video
                        // for backwards compatibility (StudioPortal returned ext without dot)
                        ext: item.ext.substring(1),
                        }});
        },
        [spotData]
    );

    return (
        <>
            <MediaPicker
                open={pickerOpen}
                editorIntf={editorIntf}
                onClose={() => {
                    close();
                }}
                title={tHook.trans('common.action.select.file')}
                LibraryViewProps={{
                    // isItemVisible,
                    // selectedItem: fileInfo,
                    contentFilters: {[FilterFields.MEDIA_TYPE]: [LibraryModelsMediaTypes.AUDIO ]},
                    renderPrimaryAction: (item) => {
                        return isMediaFile(item) ? (
                            <Button
                                label={tHook.trans('common.action.select')}
                                onClick={() => handleSelectClick(item)}
                            />
                        ) : null;
                    },
                    uploadAction: true,
                    urlAction: false,
                }}
            />
            <Typography variant="subtitle2" style={{margin: '8px 0'}}>
                {getDisplayStr(spotData.value, tHook)}
            </Typography>
            <audio controls src={spotData.value?.url || ''}/>
            <MediaPickerControls
                onOpenPicker={() => {setPickerOpen(true)}}
                clearEnabled={!!spotData.value}
                onClear={clear}
                trans={tHook.trans}
            />
        </>
    );
});
