import React from "react";
import {Typography} from "@mui/material";
import type {LibraryModelsMedia, LibraryModelsTemplateRelations} from "@zetadisplay/engage-api-client";
import {LibraryModelsTemplateTypesEnum} from "@zetadisplay/engage-api-client/models/library-models-template-types-enum";
import {Button} from "@zetadisplay/zeta-ui-components";

import {LivePreview} from "../templatePreview/LivePreview";

interface FileDialogSidekickProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zdlib: any;
    rootClass: string;
    selectedItem?: LibraryModelsMedia | LibraryModelsTemplateRelations;
    showSavePreview?: boolean;
}

export const FileDialogSidekick = ({zdlib, rootClass, selectedItem, showSavePreview}: FileDialogSidekickProps) => {

    const handleLogFileButtonClick = React.useCallback(() => {
        if (!selectedItem) return;
        const {id, modified} = selectedItem;
        if (id && modified) {
            const date = new Date(modified);
            const delimiter = date.getTime() < new Date('2024-03-28T12:55:00.000Z').getTime() ? ' ' : '-'
            const url = `https://api.contentcreator.zetadisplay.dev/templates/logs/${modified.substring(0, 10)}${delimiter}${id}.txt`;
            // open url to new browser tab:
            window.open(url, '_blank')
        }
    }, [selectedItem]);

    /*const params = new URLSearchParams(window.location.search);
    if (!zdlib.env.isDev && !params.has('test-preview')) {
        return null;
    }*/

    const isGauddiTemplate = (templateItem: LibraryModelsMedia | LibraryModelsTemplateRelations) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const item = templateItem as any;
        return (item.discriminatorType === 'MediaFile' && item.template?.type === LibraryModelsTemplateTypesEnum.Html)
                || (item.discriminatorType === 'Template' &&  item.type === LibraryModelsTemplateTypesEnum.Html)
    }

    return (
        <div className={rootClass}>
            <div>
                {
                    showSavePreview &&
                    <Typography variant={'h5'} sx={{marginBottom: '10px'}}>
                        {'File to replace:'}
                    </Typography>
                }
                <Typography variant={selectedItem ? 'h5' : 'h6'} sx={{marginBottom: '10px'}}>
                    {selectedItem ? selectedItem.name : 'No file selected'}
                </Typography>
                { selectedItem &&
                    <LivePreview zdlib={zdlib}
                                 selectedItem={selectedItem}
                                 size={200}
                    />
                }
                {zdlib.editor?.user?.roles.includes('ENGAGE_LIBRARY_TEMPLATE_CREATE') &&
                    <Button
                        kind="defaultAction"
                        disabled={!selectedItem || isGauddiTemplate(selectedItem)}
                        sx={{marginTop: '10px'}}
                        onClick={() => {
                            handleLogFileButtonClick();
                        }}
                        label={'Open last transcode log'}
                    />
                }
            </div>
        </div>
    )
}