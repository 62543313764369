
const debug = new URLSearchParams(window.location.search).has('debugAssets') ;

export const AssetLoader = (zdlib) => {
    let map, resolver, zdlibEditorIntf, counter;
    const checkDone = () => {
        if (resolver) {
            const assets = {loading: 0, complete: 0, error: 0};
            map.forEach(({status}) => {
                assets[status] = assets[status] + 1
            });
            if (debug && assets.loading === 0) console.log(`%c${assets.complete + assets.error} / ${assets.complete + assets.error + assets.loading} assets loaded`, 'color:cyan;background-color:blue')
            if (assets.loading === 0) resolver();
        }
    }

    const getElementStr = (element) => `[${element.tagName.toLowerCase()}${element.id ? ` id="${element.id}"` : ''}${element.class ? ` class="${element.class}"` : ''}]`


    const notify = (element, callbackName, event) => {
        const cid = element.getAttribute('data-component-id')
        const component = cid && zdlib.components.getItem(cid);
        if (component) zdlib.spotCB(component, callbackName, false, event);
        return component;
    }
    return {
        begin: (editorIntf) => {
            counter = 0;
            if (debug) console.log(`%cBEGIN ASSETS`, 'color: blue; background-color: cyan');
            zdlibEditorIntf = editorIntf;
            map = new Map();
            resolver = undefined;
        },
        onClear: async (element) => {
            if (map?.has(element)) {
                if (debug) {
                    console.log(`%cCLEARED: ${getElementStr(element)}`, 'color: white; background-color: gray', element.getAttribute('src'))
                }
                map.delete(element);
                if (element.tagName === 'VIDEO') {
                    await zdlib.timeline.removeVideo(element);
                }
            }
        },
        onLoading: async (element, src) => {
            if (map && !map.has(element)) {
                counter++;
                if (debug) {
                    console.log(`%cLOADING ${counter}: ${getElementStr(element)}`, 'color: yellow; background-color: green', src)
                }
                map.set(element, {status: 'loading', counter});
            }
            if (element.tagName === 'VIDEO') {
                element.autoPlay = false;
                //await zdlib.timeline.removeVideo(element);
            }
        },
        onLoaded: async (element, event) => {
            //if (debug) console.log(`%cLOADED:  ${getElementStr(element)}`, 'color: blue; background-color: cyan', element.src || element.data);
            if (element.tagName === 'VIDEO') await zdlib.timeline.addVideo(element);
            notify(element, 'onLoaded', event);
            if (map) {
                if (debug && map.has(element)) {
                    const obj = map.get(element);
                    if (obj.status !== 'complete')
                        console.log(`%cLOADED ${obj.counter}:  ${getElementStr(element)}`, 'color: blue; background-color: cyan', element.getAttribute('src') || element.data);
                }
                map.set(element, {status: 'complete'});
                checkDone();
            }
        },
        onError: async (element, event) => {
            const component = notify(element, 'onLoadError', event);
            if (map) {
                // notify editor for missing assets only on initial load
                if (zdlibEditorIntf && component) {
                    const src = element.src || element.data;
                    console.log(`%c Error loading asset for ${getElementStr(element)}`, 'background-color: orange', src);
                    zdlibEditorIntf.assetMissing(src.substring(src.lastIndexOf('/') + 1));
                    }
                if (map.has(element)) {
                    const obj = map.get(element);
                    if (obj.status !== 'error')
                        console.log(`%cERROR ${obj.counter}:  ${getElementStr(element)}`, 'color: black; background-color: orange', element.src || element.data);
                }
                map.set(element, {status: 'error'});
                checkDone();
            }
            if (element.tagName === 'VIDEO') {
                await zdlib.timeline.removeVideo(element);
            }
        },
        cancel: () => {
            if (map) {
                if (resolver) resolver();
                else {
                    map = undefined;
                }
            }
        },
        isReady: () => {
            if (!map) return Promise.resolve();
            const promise = new Promise((resolve, reject) => {
                const timeoutId = setTimeout(reject, 20000, new Error('Loading assets is taking more than 20s'));
                resolver = () => {
                    clearTimeout(timeoutId);
                    map = undefined;
                    resolver = undefined;
                    resolve();
                }
            });
            checkDone();
            return promise;
        }
    }
};