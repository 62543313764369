import React from "react";
import {Icon} from "@mui/material";
import {Button} from "@zetadisplay/zeta-ui-components";

interface MediaPickerControlProps {
    onOpenPicker: () => void;
    clearEnabled: boolean;
    onClear: () => void;
    trans: (term: string, params?: string[], plural?: boolean) => string;
}

export const MediaPickerControls = ({onOpenPicker, clearEnabled, onClear, trans}: MediaPickerControlProps) => {
    return (
        <div style={{marginTop: 10}}>
            <Button
                onClick={() => {
                    onOpenPicker();
                }}
                style={{ marginRight: 24 }}
            >
                <Icon style={{ marginRight: 8 }} fontSize="small">
                    folder
                </Icon>
                {trans('common.action.select')}
            </Button>
            <Button disabled={!clearEnabled} onClick={() => {
                onClear();
                }}>
                <Icon style={{ marginRight: 8 }} fontSize="small">
                    clear
                </Icon>
                {trans('common.action.clear')}
            </Button>
        </div>
    )
}