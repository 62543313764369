import React from 'react';
import { Slider } from '@mui/material';
import { observer } from 'mobx-react';

import { getLocalizedString } from '../localization/localizationUtils';
import HTMLParser from "../UIComponents/HTMLParser";
import { changeValue } from './SE__Base';

const SE_Slider = observer((props) => {
    const { zdlib, spotData, events, forceOnChangeCallback } = props;

    const handleChange = React.useCallback(
        (event, newValue) => {
            changeValue(event, spotData, newValue, forceOnChangeCallback);
        },
        [spotData, forceOnChangeCallback]
    );

    const dragStop = React.useCallback(() => {
        //if (spotData) zdlib.spotCB(spotData, 'onSliderRelease');
        (spotData.ui.onSliderRelease || spotData.onSliderRelease)?.call(undefined, spotData)
    }, [spotData]);

    const formatValue = React.useCallback(
        (value) => {
            //const formatted = spotData.onFormatValue && spotData.onFormatValue(spotData);
            const formatted = (spotData.ui.onFormatValue || spotData.onFormatValue)?.call(undefined, spotData)
            return formatted !== undefined
                ? HTMLParser(getLocalizedString(zdlib.currentLanguage, formatted), {data: spotData})
                : value;
        },
        [spotData, zdlib.currentLanguage]
    );

    const uiProps = { ...spotData.ui, ...(spotData.ui.props || {}) };
    if (!uiProps.vertical && uiProps.axis === 'y') uiProps.vertical = true;
    if (uiProps.step === undefined) uiProps.step = 1;

    const { label, min, max, step, vertical, disabled, valueLabelDisplay, width, height } = uiProps;
    const ranged = spotData.value && typeof spotData.value !== 'string' && typeof spotData.value.slice === 'function';

    let value = ranged ? spotData.value.slice() : Number(spotData.value);
    if (!ranged && isNaN(value)) value = Number(min);

    let { marks } = uiProps;
    if (marks && marks.slice && typeof marks.slice === 'function')
        marks = marks.map((mark) => ({
            value: Number(mark.value),
            label: HTMLParser(getLocalizedString(zdlib.currentLanguage, mark.label)),
            text: HTMLParser(getLocalizedString(zdlib.currentLanguage, mark.text)),
        }));

    return (
        <>
            <div className={'zdsubtitle'}
                 style={{position: 'relative',
                        top: valueLabelDisplay === 'on' ? 0 : 10,
                        marginBottom: valueLabelDisplay === 'on' ? 26 : 0}}
                >
                {HTMLParser(getLocalizedString(zdlib.currentLanguage, label))}
            </div>
            <div style={{ width: !vertical && (width || '90%'), height: vertical && (height || '80px'), position: 'relative', left: 8 }}>
                <Slider
                    value={value}
                    min={min || 0}
                    max={max || 100}
                    step={step}
                    disabled={disabled}
                    {...events}
                    onChange={handleChange}
                    onChangeCommitted={dragStop}
                    valueLabelFormat={formatValue}
                    valueLabelDisplay={valueLabelDisplay}
                    marks={marks}
                    orientation={vertical ? 'vertical' : 'horizontal'}
                />
            </div>
        </>
    );
});

export default SE_Slider;
