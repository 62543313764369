import React from 'react';
import { Close } from '@mui/icons-material';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import { getLocalizedString } from 'localization/localizationUtils';
import {observer} from "mobx-react";
import {getSortedComponents} from "State/PageHandler";

import {getControl} from "../components";


const Popup = observer((props) => {
    const { close, theme, transitionDuration, spotData, zdlib, anchorPos, popupId, ownerProps} = props;
    const { left, top } = anchorPos;
    const title = getLocalizedString(zdlib.currentLanguage, spotData.popup.title);
    const controls = React.useMemo(() => {
        const newProps = { ...ownerProps, ...{ popup: { ...spotData.popup } } };
        return getSortedComponents(zdlib, popupId)
            .map((comp, index) => {
                return getControl(newProps, comp, index);
            })
    }, [ownerProps, popupId, spotData.popup, zdlib]);

    return (
        <Popover
            open={true}
            transitionDuration={transitionDuration}
            anchorPosition={{ left: left, top: top }}
            anchorReference="anchorPosition"
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            onClose={close}
            style={{
                maxHeight: spotData.popup.maxHeight || '90%',
                maxWidth: window.innerWidth * 0.9,
                width: spotData.popup.width || 370,
                minWidth: spotData.popup.width || 370,
            }}
            slotProps={{ paper: {
                style: {
                    padding: '8px 32px 24px 16px',
                    backgroundColor: theme.palette.background.default,
                    //overflow: 'hidden',
                    border: '1px solid ' + theme.palette.background.disabled,
                    borderRadius: 0,
                }
            }}}

        >
            { title &&
                <Box display="flex" justifyContent="flex-end" alignItems="center" width={1} marginBottom={1}>
                    <Box flexGrow={1}>
                        <Typography color="secondary" variant="h5">
                            {title}
                        </Typography>
                    </Box>

                    <IconButton onClick={close} color="primary" style={{ color: theme.palette.background.text }}>
                        <Close fontSize="medium" />
                    </IconButton>
                </Box>
            }
            {controls}
        </Popover>
    );
});

export default Popup;
