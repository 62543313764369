import { Fullscreen, Pause, PlayArrow } from '@mui/icons-material';
import { Box, Slider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {Use_mq_MobileWidth, Use_mq_Narrow, Use_mq_NarrowContent} from './mediaQueries';
import {PreviewResolutionMenu} from "./PreviewResolutionMenu";

const useStyles = makeStyles()((theme) => ({
    button: {
        color: theme.palette.text.primary,
        minWidth: 32,
        minHeight: 32,
    },
    root: {
        height: 6,
        //marginTop: 7,
    },
    thumb: {
        height: 0,
        width: 8,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},

    track: {
        height: 6,
        borderRadius: 4,
        color: theme.palette.primary.main,
        transition: 'none',
    },
    rail: {
        height: 6,
        borderRadius: 4,
        color: theme.palette.background.disabled,
    },
}));

const timeToStr = (sec) => {
    if (isNaN(Number(sec))) return sec;

    function leftPad(s) {
        s += '';
        if (s.length < 2) {
            s = `0${s}`;
        }
        return s;
    }

    let m = Math.floor(sec / 60);
    let h = 0;
    if (m > 60) {
        h = Math.floor(m / 60);
        m = m % 60;
    }
    let s = Math.trunc ? Math.trunc(sec % 60) : Math.floor(sec % 60);

    let val = `${leftPad(m)}:${leftPad(s)}`;
    if (h > 0) val = `${leftPad(h)}:${val}`;

    //console.log('**************', sec, h, m, s, typeof sec, Number(sec))

    return val; // + dec;
};

const ViewControls = observer((props) => {
    const theme = useTheme();
    const { classes } = useStyles();

    const narrowContent = Use_mq_NarrowContent();
    const mobile = Use_mq_MobileWidth();
    const narrow = Use_mq_Narrow();

    const { zdlib, editorIntf } = props;
    const [dragging, setDragging] = useState(false);
    const [playing, setPlaying] = useState(zdlib.playing);

    function playClick() {
        if (zdlib.playing) zdlib.pause();
        else zdlib.play();
        setDragging(false);
    }

    function fullScreenClick() {
        zdlib.editor.toggleEditing();
    }

    function dragStart() {
        if (!dragging) {
            setPlaying(zdlib.playing);
            setDragging(true);
        }
        zdlib.pause();
    }

    function dragStop() {
        if (playing) zdlib.play();
        setPlaying(playing);
        setDragging(false);
    }

    function onSliderChange(event, newValue) {
        if (!dragging) {
            setPlaying(zdlib.playing);
            setDragging(true);
        }
        zdlib.pause(newValue);
    }

    function formatValue(value) {
        return timeToStr(value); // value.toFixed(1)
    }

    return mobile || narrow ? (
        <Box alignSelf="center"
             style={{ pointerEvents: 'auto', right: '82px', bottom: '23px', position: narrow ? 'fixed' : 'static' }}>
            <PlayBtn zdlib={zdlib} playClick={playClick} classes={classes} />
            <PreviewResolutionMenu zdlib={zdlib} />
            <FullScreenBtn fullScreenClick={fullScreenClick} classes={classes} />
        </Box>
    ) : (
        <Box
            display={'flex'}
            width={1}
            pl={narrowContent ? '25%' : '30%'}
            pr={narrowContent ? '25%' : '30%'}
            justifyContent="center"
            alignItems="center"
            height={editorIntf.layoutConfig.controlbar.height}
            bgcolor={theme.palette.background.content}
            color={theme.palette.text.primary}
            style={{ pointerEvents: 'auto'}}
        >
            {!editorIntf.isGauddiTemplate && zdlib.hasTimeline && (
                <>
                    <PlayBtn zdlib={zdlib} playClick={playClick} classes={classes} />

                    {!mobile && (
                        <Slider
                            value={zdlib.time}
                            min={0}
                            max={Number(zdlib.duration)}
                            step={0.04}
                            valueLabelDisplay={zdlib.playing ? 'off' : 'on'}
                            onDragStart={dragStart}
                            onDragEnd={dragStop}
                            onChange={onSliderChange}
                            valueLabelFormat={formatValue}
                            classes={{
                                root: classes.root,
                                thumb: classes.thumb,
                                active: classes.active,
                                track: classes.track,
                                rail: classes.rail,
                                /*valueLabel: classes.valueLabel*/
                            }}
                        />
                    )}
                </>
            )}
            <PreviewResolutionMenu zdlib={zdlib} />
            <FullScreenBtn fullScreenClick={fullScreenClick} classes={classes} />
        </Box>
    );
});

const PlayBtn = ({zdlib, playClick, classes}) => (
    <Button
        onClick={playClick}
        color="primary"
        classes={{ colorPrimary: classes.button }}
        startIcon={zdlib.playing ? <Pause fontSize="large" /> : <PlayArrow fontSize="large" />}
        />
)

const FullScreenBtn = ({fullScreenClick, classes}) => (
    <Button
        onClick={fullScreenClick}
        color="primary"
        classes={{ colorPrimary: classes.button }}
        startIcon={<Fullscreen fontSize="large" />}
    />
)



export default ViewControls;
