import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import React from "react";
import {SEListItem} from "./SE_ListItem.jsx";

export const SortableListItem = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.item._itemId});
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <SEListItem ref={setNodeRef} style={style} {...attributes} {...listeners} {...props}/>
    );
}