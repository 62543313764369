class NavigationHandler {
    constructor(editorIntf, zdlib) {
        this.editorIntf = editorIntf;
        this.zdlib = zdlib;

        editorIntf.changeBrowserUrl = this.changeBrowserUrl.bind(this);
        editorIntf.getQueryParams = this.getQueryParams.bind(this);
        this.onUrlChange = this.urlChanged.bind(this);
    }

    observe(enabled) {
        if (enabled) {
            window.addEventListener('popstate', this.onUrlChange);
        } else {
            window.removeEventListener('popstate', this.onUrlChange);
        }
    }

    changeBrowserUrl(params, replace, suppressEvent) {
        const searchParams = new URLSearchParams(document.location.search);
        let changed = false;
        Object.keys(params).forEach((key) => {
            const value = params[key];
            if (value === undefined) {
                if (searchParams.has(key)) changed = true;
                searchParams.delete(key);
            }
            else {
                if (!searchParams.has(key) || searchParams.get(key) !== value) changed = true;
                searchParams.set(key, value);
            }
        });
        if (!changed) return;

        if (replace) {
            window.history.replaceState({}, '', `?${searchParams.toString()}`);
        } else {
            window.history.pushState({}, '', `?${searchParams.toString()}`);
        }
        if (!suppressEvent) {
            window.dispatchEvent(new Event('popstate'));
        }
    }

    getQueryParams() {
        const ret = {};
        const params = new URLSearchParams(document.location.search);
        for (const [key, value] of params) {
            ret[key] = value;
        }
        return ret;
    }

    urlChanged() {
        if (document.location.pathname.includes('signin-callback.html')) {
            return;
        }
        this.editorIntf.urlParamsChanged(this.getQueryParams());
    }

}

export default NavigationHandler;
