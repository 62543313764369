import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {List} from '@mui/material';
import React from "react";

import {getListItemComponent} from './getListItemComponent';
import {getItemRestrictions, moveItem} from "./listUtils";




export const SortableList = ({spotData, selectedId, tHook, zdlib}) => {
    const [draggingItem, setDraggingItem] = React.useState(null);
    
    const sensors = useSensors(
        useSensor(PointerSensor, {activationConstraint: {distance: 10}}),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const isDisabled = () => {
        const editOptions = getItemRestrictions(spotData);
        return editOptions === false || editOptions.hideMove;
    }

    function handleDragStart(event) {
        const {active} = event;
        const activeItem = spotData.value?.find(item => item._itemId === active.id);
        if (activeItem) {
            setDraggingItem(activeItem);
        }
    }

    function handleDragEnd(event) {
        const {active, over} = event;

        if (active?.id !== over?.id) {
            const oldIndex = spotData.value?.findIndex(item => item._itemId === active?.id);
            const newIndex = spotData.value?.findIndex(item => item._itemId === over?.id);
            moveItem(spotData, oldIndex, {newIndex})
        }

        setDraggingItem(null);
    }




    return (
        <DndContext
            sensors={sensors}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
                <List style={{ marginTop: '8px', display: 'block' }} disablePadding>
                    <SortableContext
                        items={spotData.value.map(item => ({id: item._itemId}))}
                        strategy={verticalListSortingStrategy}
                        disabled={isDisabled()}
                    >
                        {spotData.value?.map((item, index) => {
                            const isDraggingItem = draggingItem && draggingItem._itemId === item._itemId;
                            return getListItemComponent(item, index, {
                                                        spotData,
                                                        isDraggingItem,
                                                        tHook,
                                                        selectedId,
                                                        zdlib,
                                                        })
                            })
                        }
                    </SortableContext>
                    <DragOverlay>
                        {/*draggingItem ? <SEListItemText item={draggingItem} zdlib={zdlib} spotData={spotData}/> : null*/}
                        {draggingItem
                            ? <span style={{cursor: 'ns-resize'}}>{
                                getListItemComponent(draggingItem, (spotData.value || [])
                                                    .findIndex(item => item._itemId === draggingItem._itemId), {
                                                        spotData,
                                                        isDragOverlay: true,
                                                        tHook,
                                                        selectedId,
                                                        zdlib})
                            }
                            </span>
                            : null
                        }
                    </DragOverlay>
                </List>
        </DndContext>
    );
}