import { Badge, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { observer } from 'mobx-react';
import React from 'react';

import { Use_mq_MobileWidth, Use_mq_NarrowContent } from './mediaQueries';

const useStyles = makeStyles()(() => ({
    title: {
        textTransform: 'capitalize',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        //maxWidth: 'calc(100vw - 660px)',
        pointerEvents: 'auto',
    },
    badge: {
        minWidth: 12,
        minHeight: 12,
        right: -6,
        top: 4,
        borderRadius: '50%',
    },
}));

function FileHeader(props) {
    const theme = useTheme();
    const { classes } = useStyles();
    const narrowContent = Use_mq_NarrowContent();
    const mobileWidth = Use_mq_MobileWidth();

    if (mobileWidth) return null;

    const { zdlib } = props;
    const fileName =
        props.editorIntf.mediaItem?.name || props.editorIntf.mediaItem?.templateName || zdlib.spot?.name || '';


    const { length } = zdlib.editor.changes;

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginRight="8px"
            bgcolor={theme.palette.background.content}
            color={theme.palette.text.primary}
        >
            <Badge invisible={length === 0} variant="dot" color="primary" classes={{ dot: classes.badge }}>
                <Typography
                    variant="h2"
                    color="textPrimary"
                    classes={{ colorTextPrimary: classes.title }}
                    style={{ maxWidth: `calc(100vw - ${narrowContent ? 520 : 660}px)` }}
                    title={fileName}
                >
                    {fileName}
                </Typography>
            </Badge>
        </Box>
    );
}

export default observer(FileHeader);
