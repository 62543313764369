import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ClickAwayListener, Icon, Link, Popper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { SEMI_BOLD } from '@zetadisplay/zeta-ui-components/utils/theme/theme-options';

import type { InfoFunc, PopperOptions } from './InfoProvider';

const Z_BASE = 1205;

const useStyles = makeStyles()((theme) => ({
    popper: {
        backgroundColor: theme.palette.info.main, // '#fff',
        color: 'white !important', //theme.palette.info.text,
        padding: '28px 32px 24px 22px',
        boxShadow: '2px 8px 12px 0 rgba(0,0,0,0.6)',
        maxWidth: 261,
        /*marginLeft: 16,
        marginRight: 16,*/
        zIndex: Z_BASE,
        '&[data-popper-placement*="top"]': {
            marginBottom: 20,
        },
    },
    info: {
        backgroundColor: theme.palette.info.main,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
    },
    content: {},
    close: {
        cursor: 'pointer',
        position: 'absolute',
        right: 3,
        top: 3,
        fontSize: 16,
    },
    'close--info': {
        color: theme.palette.info.text,
    },
    'close--warning': {
        color: theme.palette.warning.text,
    },
    title: {
        fontWeight: SEMI_BOLD,
        color: `${theme.palette.info.text} !important`,
        lineHeight: '18px',
        size: 14,
        marginBottom: 10,
    },
    'title--info': {
        color: `${theme.palette.info.text} !important`,
    },
    'title--warning': {
        color: theme.palette.warning.text,
    },
    text: {
        color: `${theme.palette.info.text} !important`,
        marginBottom: 10,
    },
    'text--info': {
        color: theme.palette.info.text,
    },
    'text--warning': {
        color: theme.palette.warning.text,
    },
    link: {
        color: `${theme.palette.info.text} !important`,
        textDecoration: 'underline',
    },
}));

interface PopperProviderProps {
    setShowPopper: (showPopper: InfoFunc<PopperOptions>) => void;
}

interface PopperInfo extends PopperOptions {
    key?: string;
    htmlParams?: object;
    domElement?: HTMLElement;
}

export default function PopperProvider({ setShowPopper }: PopperProviderProps): JSX.Element {
    const [poppers, setPoppers] = useState<PopperInfo[]>([]);
    const poppersRef = useRef<PopperInfo[]>([]);

    const refresh = useCallback(() => {
        setPoppers(poppersRef.current.concat());
    }, []);

    const findIndex = useCallback((item?: PopperInfo) => {
        return poppersRef.current.findIndex((popper) => popper?.key === item?.key);
    }, []);

    const close = useCallback(
        (info: PopperInfo) => {
            const index = findIndex(info);
            if (index < 0) return;
            poppersRef.current.splice(index, 1);
            refresh();
            if (info.onClose) info.onClose(info);
        },
        [findIndex, refresh]
    );

    useEffect(() => {
        setShowPopper((options: PopperOptions) => {
            const info: PopperInfo = { ...options };
            if (typeof info.element === 'string') {
                info.domElement = document.getElementById(info.element) || undefined;
                info.key = info.element;
            } else if (Object.prototype.hasOwnProperty.call(info.element, 'tagName')) {
                const domElement = info.element as HTMLElement;
                info.domElement = domElement;
                info.key = domElement.id;
            }

            if (!info.domElement) {
                // console.error('showPopper(): info.element now found');
                return () => {
                    close(info);
                };
            }

            if (findIndex(info) >= 0) {
                // console.log('key exists:', info)
                return () => {
                    close(info);
                };
            }

            poppersRef.current.push(info);
            refresh();

            if (info.onShow) info.onShow(info);

            return () => {
                close(info);
            };
        });
    }, [setShowPopper, close, findIndex, refresh]);

    return (
        <>
            {poppers.map(
                (info, index) =>
                    info &&
                    info.domElement && (
                        <InfoPopper
                            zPos={index}
                            key={info.key}
                            info={info}
                            anchorEl={info.domElement}
                            onMoveToTop={() => {
                                const pos = findIndex(info);
                                if (pos >= 0) poppersRef.current.push(poppersRef.current.splice(pos, 1)[0]);
                                refresh();
                            }}
                            closePopperCallback={(popper) => {
                                const pos = findIndex(popper);
                                if (pos >= 0) poppersRef.current.splice(pos, 1);
                                refresh();
                                if (popper.onClose) popper.onClose(popper);
                            }}
                        />
                    )
            )}
        </>
    );
}

interface InfoPopperProps {
    anchorEl: HTMLElement;
    closePopperCallback: (info: PopperInfo) => void;
    info: PopperInfo;
    onMoveToTop: () => void;
    zPos: number;
}

const InfoPopper = ({
    anchorEl,
    closePopperCallback,
    info,
    onMoveToTop,
    zPos,
}: InfoPopperProps): JSX.Element | null => {
    const theme = useTheme();

    const { classes, cx } = useStyles();
    const [open, setOpen] = useState(false);
    //const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);
    useEffect(() => {
        setOpen(Boolean(anchorEl));
    }, [anchorEl]);

    if (!theme) {
        throw new Error("Component doesn't have a theme. Wrap components inside a <Theme>");
    }

    const popperClasses = cx({
        [classes.popper]: true,
        [classes.warning]: info.type === 'warning',
        [classes.info]: info.type === 'info',
    });

    const titleClasses = cx({
        [classes.title]: true,
        [classes['title--warning']]: info.type === 'warning',
        [classes['title--info']]: info.type === 'info',
    });

    const textClasses = cx({
        [classes.text]: true,
        [classes['text--warning']]: info.type === 'warning',
        [classes['text--info']]: info.type === 'info',
    });

    const closeClasses = cx({
        [classes.close]: true,
        [classes['close--warning']]: info.type === 'warning',
        [classes['close--info']]: info.type === 'info',
    });

    function internalClose() {
        setOpen(false);
        if (closePopperCallback) closePopperCallback(info);
    }

    const { text, title, parseFunction, position, link, linkText, onClickLink, htmlParams } = info;

    if (!anchorEl) return null;

    return (
        <ClickAwayListener onClickAway={internalClose}>
            <Popper
                anchorEl={anchorEl}
                className={popperClasses}
                open={open}
                placement={position || 'right'}
                disablePortal={false}
                modifiers={[
                    { name: 'flip', enabled: true },
                    //{ name: 'arrow', enabled: true, options: { element: arrowRef } },
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 16],
                        },
                    },
                ]}
                style={{ zIndex: Z_BASE + zPos }}
                onClick={() => {
                    onMoveToTop();
                }}
            >
                <div>
                    {/*<span ref={setArrowRef} />*/}
                    <Icon
                        className={closeClasses}
                        onClick={(ev) => {
                            ev.preventDefault();
                            internalClose();
                        }}
                    >
                        close
                    </Icon>
                    {title && (
                        <Typography variant="body1" className={titleClasses} component={'div'}>
                            {parseFunction ? parseFunction(title, htmlParams) : title}
                        </Typography>
                    )}

                    {text && (
                        <Typography variant="body1" className={textClasses} component={'div'}>
                            {parseFunction ? parseFunction(text, htmlParams) : text}
                        </Typography>
                    )}

                    {link && (
                        <Link
                            href={link}
                            target="_blank"
                            rel="noopener"
                            className={classes.link}
                            onClick={(ev: React.MouseEvent) => {
                                if (link === '#') ev.preventDefault();
                                if (onClickLink) onClickLink(info);
                            }}
                        >
                            {linkText || link}
                        </Link>
                    )}
                </div>
            </Popper>
        </ClickAwayListener>
    );
};
