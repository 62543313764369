//import {portalUrl} from "../index";

export const EventLoader = (zdlib) => {
    let _baseUrl;
    const state = {};
    function _initBaseUrl() {
            const url = zdlib.env.isLocal
                ? process.env.PORTAL
                :  document.location.origin;
            _baseUrl = `${url}${url.endsWith('/') ? '' : '/'}api3/event.aspx`
    }


    return {
        cancelAll: function() {
            Object.keys(state).forEach(ID => {
                state[ID].controller.abort();
                clearTimeout(state[ID].timeoutId);
                delete state[ID];
            })
        },
        getBaseUrl: function() {
            if (!_baseUrl) _initBaseUrl();
            return _baseUrl;
        },
        getEvent: async function ({eventPath, ID, timeout = 20000, log = true, addRoot = true}) {
            if (!_baseUrl) _initBaseUrl();
            const url = `${_baseUrl}?path=${eventPath}&ID=${ID || zdlib.evID}${addRoot ? '&addroot=1' : ''}&force=1`;
            if (log) console.log(`Load template data from ${url}`);
            const controller = new AbortController();
            let timeoutMsg;
            state[ID] = { controller, timeoutId: setTimeout(() => {
                    timeoutMsg = `Loading event server data is taking more than ${Math.round(timeout / 1000)}s`;
                    if (state[ID]) {
                        state[ID].controller.abort();
                        delete state[ID];
                    }
                }, timeout),
            };
            let response;
            try {
                response = await fetch(url, {signal: controller.signal});
            }
            catch (err) {
                if (err.name === 'AbortError') {
                    if (timeoutMsg) throw new Error(timeoutMsg);
                }
                else throw new Error(err.message);
            }
            if (state[ID]) {
                clearTimeout(state[ID].timeoutId);
                delete state[ID];
            }
            return await response?.text();
        },
        parseEventServerData: (responseText, fromJSON = true) => {
            if (!responseText) return {eventServerData: null}
            let evdataList;
            let xml;
            try {
                const parser = new DOMParser();
                xml = parser.parseFromString(responseText, 'text/xml');
                const timeouts = xml.getElementsByTagName('timeout');
                if (timeouts && timeouts.length > 0) {
                    // '..poll timeout..: '
                    return {timeout: true};
                }
                evdataList = xml.getElementsByTagName('event');
            }
            catch (err) {
                throw new Error(`parsing error: ${err.message}`);
            }
            const errors = xml.getElementsByTagName('parsererror');
            if (errors && errors.length > 0) {
                throw new Error(`error in xml: ${errors[0].textContent}`);
            }
            const txt = document.createElement("textarea");
            let eventServerData = null;
            Array.from(evdataList).forEach(evdata => {
                let data = '';
                try {
                    data = evdata.getAttribute('data');
                    txt.innerText = data;
                    data = txt.value;
                }
                catch (err) {
                    data = '';
                }
                if (data.length > 0) {
                    if (fromJSON) {
                        data = data.substring(data.indexOf('{'));
                        eventServerData = JSON.parse(data);
                    } else {
                        eventServerData = data;
                    }
                }
            });
            txt.remove();
            return fromJSON ? {eventServerData} : eventServerData;
        },
        /* sendEvent: function(eventPath, data) {
             if (!_baseUrl) _initBaseUrl();
             ZDDataLoader.loadUrl( { url: _baseUrl + '?path=' + eventPath,
                                     method: 'POST',
                                     data: _esc(data),
                                     } )
             },*/
    }
};