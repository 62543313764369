/* date-fns has known issues with their typings which seems to be bothering the whole internet */
/* eslint-disable import/no-duplicates */

import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fiLocale from 'date-fns/locale/fi';
import svLocale from 'date-fns/locale/sv';
import { observer } from 'mobx-react';

import { getLocalizedString } from '../localization/localizationUtils';
import { changeValue } from './SE__Base';

const locales = { fi: fiLocale, sv: svLocale };

export const SE_DatePicker = observer( ({ spotData, forceOnChangeCallback, zdlib }) => {

        const handleChange = (newValue) => {
            changeValue({ type: 'DatePickerChange' }, spotData, newValue, forceOnChangeCallback);
        };

        const locale = locales[navigator.language.substring(0, 2)];
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                <div style={{ paddingBottom: 4, fontSize: 16 }}>
                    {getLocalizedString(zdlib.currentLanguage, spotData.ui.label)}
                </div>

                <DatePicker
                    inputFormat={'dd.MM.yyyy'}
                    mask={'__.__.____'}
                    onChange={handleChange}
                    slotProps={{ textField: { variant: 'outlined', InputLabelProps: { shrink: true },
                            InputProps: {classes: {}}}}}
                    value={typeof spotData.value === "string" ? new Date(spotData.value) : spotData.value}
                />
            </LocalizationProvider>
        );
})


