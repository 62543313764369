import React, {useMemo} from "react";
import {Typography} from "@mui/material";
import type {LibraryModelsTemplateRelations} from "@zetadisplay/engage-api-client";
import {ItemsView, SkeletonItemsView} from "@zetadisplay/engage-components";
import type {DiscriminatedEntity} from '@zetadisplay/engage-components/models';
import {useApiConfiguration} from "@zetadisplay/engage-components/modules/api";
import {getIconType} from "@zetadisplay/engage-components/modules/view/utils";
import {getThumbnailUrl} from "@zetadisplay/engage-components/utils/media";
import {useTranslation} from "@zetadisplay/zeta-localization";
import type {ItemProps} from "@zetadisplay/zeta-ui-components";
import {themeOptions} from "@zetadisplay/zeta-ui-components/utils/theme";

import TemplateLibraryListItemSubtitle from "./TemplateLibraryListItemSubtitle";
import useTemplates from "./useTemplates";


interface TemplateLibraryViewProps {
    onTemplateSelected: (template:  DiscriminatedEntity<LibraryModelsTemplateRelations>) => void;
}



const TemplateLibraryView = (props: TemplateLibraryViewProps) => {
    const content = useTemplates();
    const apiConfig = useApiConfiguration();
    const t = useTranslation();
    //const { workspace } = useWorkspace();
    const infiniteScrolling= !content.total || content?.total > content.data.length;
    const loading = content.isLoading;
    const nodes= content.data;
    const [selected, setSelected] = React.useState<DiscriminatedEntity<LibraryModelsTemplateRelations> | undefined>();

    const selectItem = React.useCallback((item: DiscriminatedEntity<LibraryModelsTemplateRelations>, isSelected: boolean = true) => {
        if (isSelected && selected?.id !== item.id) {
            setSelected(item);
            props.onTemplateSelected(item);
        }
    }, [props, selected?.id]);
    
    const itemProps: ItemProps<DiscriminatedEntity<LibraryModelsTemplateRelations>> = useMemo(
        () => {
            return {
                getItemIconType: getIconType,
                getThumbnailUrl: (item) => getThumbnailUrl(item, false, apiConfig.basePath) || '',
                inViewThreshold: 0.3,
                onItemClick: selectItem,
                renderSubtitle: (item) => <TemplateLibraryListItemSubtitle item={item} />,
                showThumbnail: true,
                selectable: true,
                onSelectItem: selectItem,
            }
        },
        [apiConfig.basePath, selectItem]
    );
    
    const renderItemsView = () => {
        if (loading && nodes.length === 0) {
            return <SkeletonItemsView viewMode="GRID" />;
        }

        if (!nodes || nodes.length === 0) {
            return (
                <Typography align="center" style={{color: themeOptions.colors.BLACK, padding: 48,}}>
                    {t.trans('engage.library.content.no_content')}
                </Typography>
            );
        }

        return (
            <ItemsView
                selectedItems={selected && {[selected.id]: selected}}
                infiniteScrolling={infiniteScrolling}
                isLoadingMore={loading && nodes.length > 0}
                ItemProps={itemProps}
                items={nodes}
                onInfiniteScroll={content.getNextResultPage}
                viewMode="GRID"
            />
        );
    };
    
    
    return (
        <div className={'template-library-view'}>{renderItemsView()}</div>
    )
}

export default TemplateLibraryView;