import DOMPurify from 'dompurify';
import React from 'react';

const DynamicHTML = ({ html , params = {}}) => {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current && typeof html === 'function') {
            const node = html({...params, ...{parent: ref.current}});
            if (node) {
                ref.current.replaceChildren(node)
            }
        }
    }, [html, params]);
    return <span ref={ref} />;
};

// TODO: DOMPurify.sanitize is probably redundant below,
//  because it gets called in set innerHTML (in importTemplate.js)
// => check

const HTMLParser = (html, params) => {
    if (!html) return null;
    if (typeof html === 'string') {
        return html.includes('<') ? <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} /> : html;
    }
    return typeof html === 'function' ? <DynamicHTML html={html} params={params} /> : null;
};

export default HTMLParser;
