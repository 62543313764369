import React from 'react';
import {TextField} from '@zetadisplay/zeta-ui-components'
import {observer} from 'mobx-react';

import {getLocalizedString} from "../localization/localizationUtils";
import {changeValue} from './SE__Base'

const SE_TextField = observer((props) => {
    const { spotData, forceOnChangeCallback, zdlib, events } = props;
    const uiProps = { ...spotData.ui, ...(spotData.ui.props || {}) };
    if (
        !uiProps.minWidth &&
        !uiProps.maxWidth &&
        !uiProps.width &&
        !Object.prototype.hasOwnProperty.call(uiProps, 'fullWidth')
        //&& !popup
    )
        uiProps.fullWidth = true;

    if (uiProps.maxWidth || uiProps.width) uiProps.fullWidth = false;
    if (uiProps.width) uiProps.maxWidth = uiProps.width;

    const label = getLocalizedString(zdlib.currentLanguage, uiProps.label);
    if (Object.prototype.hasOwnProperty.call(spotData, 'errorText')) {
        uiProps.error = !!spotData.errorText;
    }
    const { multiline, disabled, error, placeholder, fullWidth, minWidth, maxWidth, required, number, min, max, step } =
        uiProps;

    let { rows, rowsMax } = uiProps;
    if (multiline && (rows || rowsMax)) {
        if (!rowsMax || rowsMax < rows) rowsMax = rows;
    }

        const value = /*spotData.defaultValue ? getLocalizedString(zdlib.currentLanguage, spotData.defaultValue) :*/ spotData.value;


        const handleChange = (event) => {
            //delete spotData.defaultValue;
            if (!uiProps.number || !isNaN(event.target.value))
                changeValue(event, spotData, event.target.value, forceOnChangeCallback )
        }


            
        return (

            <TextField value={value || ''}
                    multiline={multiline}
                    disabled={disabled}
                    error={error}
                    placeholder={getLocalizedString(zdlib.currentLanguage, placeholder)}
                    fullWidth={fullWidth}
                    label={label}
                    minRows={rows}
                    maxRows={rowsMax}
                    required={required}
                    type={number ? 'number' : 'text'}
                    style={{minWidth: minWidth, maxWidth: maxWidth, marginTop: label ? 12 : 2}}
                    onChange={ handleChange }
                    min={min}
                    max={max}
                    step={step}
                    {...events}
             />

        );

});

export default SE_TextField;
