import React from 'react';
import { Typography } from '@mui/material';
import type { LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import type { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
    },
    text: {
        color: theme.palette.background.disabledText,
        whiteSpace: 'unset',
        display: 'inline-block',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
        '&:not(:last-of-type)::after': {
            content: '" • "',
            marginRight: 4,
        },
    },
}));

type TemplateLibraryListItemSubtitleProps = {
    item: DiscriminatedEntity<LibraryModelsTemplateRelations>;
    selected?: boolean;
};

const TemplateLibraryListItemSubtitle = ({ item, selected }: TemplateLibraryListItemSubtitleProps) => {
    const { classes, cx } = useStyles();
    const t = useTranslation();

    return (
        <div className={cx(classes.root)}>
            <Typography
                variant="subtitle1"
                className={cx(classes.text)}
                data-testid={`template-subtitle-modified-${item.id}`}
                noWrap
            >
                {[
                    t.trans('common.detail.updated'),
                    new Date(item.modified).toLocaleDateString(),
                    new Date(item.modified).toLocaleTimeString(),
                ].join(' ')}
            </Typography>
        </div>
    );
};

export default TemplateLibraryListItemSubtitle;
