/*
This is legacy from portal zdlib. It's still here to support zdlib.loadUrl() ; should be refactored to use fetch
+ review templates loading external data using loadUrl()
 */
export const DataLoader = (zdlib) => {
    const state = new LoaderState();
    const loadUrl = (params) => {
        let promise;
        if (Array.isArray(params)) {
            let rejectOnError;
            params.forEach( function(c) {
                if (c.rejectOnError) rejectOnError = true
            })
            const p = params.map( function(c) {
                if (rejectOnError) c.rejectOnError = true
                return loadUrl(c)
            })
            promise = new Promise(function(resolve){
                Promise.all(p).then( function(rets) {
                    resolve(rets)
                } )
            })
            return promise
        }
        const stateID = params.stateID || params.url;
        promise = state.loading(stateID)
        if (promise) {
            console.log('-already loading-- ', params.url)
            return promise
        }
        promise = new Promise(function(resolve, reject){
            params.error = undefined
            if (!params.polling) console.log('Loading data from', params.url, zdlib.timer.log());
            state.begin(stateID,
                loadXHR(params, function( fail ) {
                    state.loaded(stateID, fail);
                    params.error = fail
                    if (params.onLoaded) params.onLoaded(params);
                    if (fail && params.rejectOnError) {
                        reject(params)
                    }
                    else
                        resolve(params)
                })
            )
        });
        return promise;
    };
    return {
        state,
        cancelAll: function() {
            state.cancelAll();
        },
        loadUrl,
    }
};

function LoaderState() {
    const store = {}
    function _cancel(storeId) {
        if ( store[storeId] && store[storeId].xhr) {
            store[storeId].xhr.abort();
            delete store[storeId].xhr
        }
        delete store[storeId];
    }
    return {
        loading: function(storeId, promise) {
            const b = store[storeId] && store[storeId].pending
            if (!b) store[storeId] = { pending: !!promise || true }
            return b
        },
        loaded: function(storeId, error) {
            delete store[storeId].pending
            delete store[storeId].xhr
            store[storeId].complete = true
            store[storeId].error = error
            store[storeId].ok = !error
        },
        begin: function(storeId, xhr) {
            store[storeId].xhr = xhr;
        },
        cancel: _cancel,
        cancelAll: function() {
            Object.keys(store).forEach( function(key) {
                _cancel(key);
            } );

        },
    }

}


function loadXHR(params, onComplete) {
    let timeoutId;
    const xhr = new XMLHttpRequest();
    xhr.open(params.method || 'GET', params.url, true);
    params.request = xhr;
    xhr.onload = function() {
        if (timeoutId) clearTimeout(timeoutId)
        params.responseText = xhr.responseText;
        onComplete(xhr.status !== 200 ? 'status:'+xhr.status : undefined);
    };
    xhr.onerror = function() {
        if (timeoutId) clearTimeout(timeoutId)
        onComplete('Load error')
    };
    if (params.withCredentials) xhr.withCredentials = true;
    try
    {
        if (params.timeout)
            timeoutId = setTimeout( function() {
                    timeoutId = null;
                    if (xhr) xhr.abort();
                    onComplete('Timeout loading from ' + params.url);
                }
                , params.timeout);
        if (params.data)
            xhr.send(params.data)
        else xhr.send();
    }
    catch (error) {
        if (timeoutId) clearTimeout(timeoutId)
        onComplete(error.message)
    }
    return xhr;
}



