import React from 'react';
import { OidcSecure, useOidcAccessToken } from '@axa-fr/react-oidc';
import { UserInformationProvider } from '@zetadisplay/engage-components/modules/auth';
import { DependencyInjectionProvider } from '@zetadisplay/engage-components/modules/dependency-injection';
import { WorkspaceProvider } from '@zetadisplay/engage-components/modules/workspaces';

import { DEFAULT_OIDC_CONFIGURATION_NAME } from '../../config/oidcConfiguration';
import { processEnv } from "../../config/processEnv";

type Props = React.PropsWithChildren;

const ProtectedDependencyInjectionProvider = ({ children }: Props) => {
    const { accessToken } = useOidcAccessToken(DEFAULT_OIDC_CONFIGURATION_NAME);

    return (
        <OidcSecure configurationName={DEFAULT_OIDC_CONFIGURATION_NAME}>
            <DependencyInjectionProvider accessToken={accessToken} apiBasePath={processEnv.API_ROOT}>
                <UserInformationProvider>
                    <WorkspaceProvider>{children}</WorkspaceProvider>
                </UserInformationProvider>
            </DependencyInjectionProvider>
        </OidcSecure>
    );
};

export default ProtectedDependencyInjectionProvider;
