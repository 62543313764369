import type { ReactNode } from 'react';
import React from 'react';

import DialogProvider from './DialogProvider';
import PopperProvider from './PopperProvider';
import SnackbarProvider from './SnackbarProvider';

export const InfoContext: React.Context<infoContext> = React.createContext({});

export type InfoCallback<T> = (options: T) => void;
export type DialogCallback = (options: DialogOptions, reason?: string) => void;
export type InfoFunc<T> = (options: T) => InfoCallback<T>;

export interface DialogButton {
    label: string;
    onClick: () => void;
}

export interface DialogOptions {
    /**
     * Dialog title
     */
    title?: string;
    /**
     * Icon to show next to title
     */
    icon?: string;
    /**
     * Dialog text
     */
    text: string;
    /**
     * if specified, called when text is rendered. Implement e.g. to parse HTML text
     * @param text
     */
    parseFunction?: (text: string) => string;
    disableEscapeKeyDown?: boolean;
    disableBackdropClick?: boolean;
    /**
     * React component that is rendered as a child (content) of the Dialog
     */
    content: ReactNode;
    /**
     *  If true, renders only the content, and none of te MUI dialog parts\ (title, actions, etc)
     */
    contentOnly?: boolean;
    /**
     * React component that is rendered in the bottom of the Dialog
     */
    actions: ReactNode;
    /**
     * Creates buttons, with properties from the array.
     * If there is no onClick handler, the button just closes the dialog.
     * The last button, which should be the primary action ("OK") , is rendered as kind="primaryAction"
     * and others as kind="secondaryAction" , if not specified.\ If there are no buttons or actions, an OK button is rendered.
     */
    buttons: Array<DialogButton>;
    /**
     * To be used as key prop for the dialog
     */
    key?: string;
    /**
     * called when dialog is shown.
     */
    onShow?: InfoCallback<DialogOptions>;
    /**
     * called when dialog is closed.
     */
    onClose?: DialogCallback;
}

export interface PopperOptions {
    /**
     * Anchor element; either DOM element or element.id.
     */
    element: string | HTMLElement;
    /**
     * Text shown on top of popper
     */
    title?: string;
    /**
     * Main text
     */
    text?: string;
    /**
     * URL to e.g. help page
     */
    link?: string;
    /**
     * text to show instead of url
     */
    linkText?: string;
    /**
     * info sets background colour to blue (default), warning is yellow
     */
    type?: 'info' | 'warning';
    /**
     * Popper position realtive to anchor element; default=right
     */
    position: 'right' | 'top' | 'bottom' | 'left';
    /**
     * if specified, called when text is rendered. Implement e.g. to parse HTML text
     * @param text
     */
    parseFunction?: (text: string, htmlParams?: object) => string;
    /**
     * called when popper is shown.
     */
    onShow?: InfoCallback<PopperOptions>;
    /**
     * called when user clicks the link
     */
    onClickLink?: InfoCallback<PopperOptions>;
    /**
     * called when popper is closed.
     */
    onClose?: InfoCallback<PopperOptions>;
}

export interface SnackbarOptions {
    /**
     * Text to show
     */
    text: string;
    /**
     * if specified, called when text is rendered. Implement e.g. to parse HTML text
     * @param text
     */
    parseFunction?: (text: string) => string;
    /**
     * Hide timeout in ms, default=6000
     */
    timeout?: number;
    /**
     * Message background color. Default is "info" (blue)
     */
    color?: 'info' | 'warning' | 'error' | 'success';
    /**
     * Shows a button with text. If there's an action, timeout is ignored (user has to dismiss the message)
     */
    action?: string;
    /**
     * Called when user clicks the action button
     */
    onAction?: InfoCallback<SnackbarOptions>;
    /**
     * Horizontal position of the message. Default is "right"
     */
    horizontal?: 'left' | 'center' | 'right';
    /**
     * Vertical position of the message. Default is "bottom"
     */
    vertical?: 'top' | 'bottom';
}

export interface InfoProviderProps {
    children: ReactNode;
}

interface infoContext {
    showDialog?: InfoFunc<DialogOptions>;
    showPopper?: InfoFunc<PopperOptions>;
    showSnackbar?: InfoFunc<SnackbarOptions>;
}

/**
 * Provider component for popper and dialog hook functions.
 * (snackbar messages to be added)
 * Wrap components that need to use the hooks inside a `<InfoProvider>` component.
 * The `<InfoProvider>` should usually be near the top of the app hierarchy, below a `<Theme>` component.
 * See [info hooks](#/Utils/Info%20hooks) for more info.
 * @param {*} props
 */
export default function InfoProvider({ children }: InfoProviderProps): JSX.Element {
    const [context, setContext] = React.useState<infoContext>({});

    const setShowPopper = (showPopper: InfoFunc<PopperOptions>) => {
        if (!context.showPopper) setContext({ ...context, ...{ showPopper } });
    };

    const setShowDialog = (showDialog: InfoFunc<DialogOptions>) => {
        if (!context.showDialog) setContext({ ...context, ...{ showDialog } });
    };

    const setShowSnackbar = (showSnackbar: InfoFunc<SnackbarOptions>) => {
        if (!context.showSnackbar) setContext({ ...context, ...{ showSnackbar } });
    };

    /*const [showDialog, setShowDialog] = React.useState<InfoFunc<DialogOptions>>();
    const [showPopper, setShowPopper] = React.useState<InfoFunc<PopperOptions>>();
    const [showSnackbar, setShowSnackbar] = React.useState<InfoFunc<SnackbarOptions>>();

    const contextValue = React.useMemo(() => ({showPopper, showDialog, showSnackbar}), [showPopper, showDialog, showSnackbar])*/

    return (
        <>
            <InfoContext.Provider value={context}>{children}</InfoContext.Provider>
            <DialogProvider setShowDialog={setShowDialog} />
            <PopperProvider setShowPopper={setShowPopper} />
            <SnackbarProvider setShowSnackbar={setShowSnackbar} />
        </>
    );
}
