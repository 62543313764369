import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { observer } from 'mobx-react';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { FeedProxyHandler } from '../FeedProxy/FeedProxyHandler';
import FileHandler from '../LibraryView_engage/FileHandler';
import { TemplateProvider } from '../templateContext/TemplateProvider';
import InfoProvider from '../UIComponents/InfoProvider';
import InfoHandler from './InfoHandler';
import { Use_mq_MobileWidth, Use_mq_NarrowContent } from './mediaQueries';
import SpotEditor from './SpotEditor';
import {setEditorUser} from "../State/setEditorUser";

const EditorRoot = observer((props) => {
    const { zdlib, editorIntf } = props;
    //console.log('RENDER EditorRoot')
    //const narrow = Use_mq_Narrow();
    const narrowContent = Use_mq_NarrowContent();
    const mobile = Use_mq_MobileWidth();
    const tHook = useTranslation();
    editorIntf.tHook = tHook;
    const { user, userLanguages } = useUserInformation();

    React.useEffect(() => {
        editorIntf.appTitle = tHook.trans('editor.application.title');
        const controlWidth = /* narrow ? window.innerWidth - 70 :*/ 368;
        let bottombarHeight = 64;
        if (mobile) bottombarHeight += 32;
        else if (narrowContent) bottombarHeight += 108;
        editorIntf.setLayoutConfig({
            bottombar: { height: bottombarHeight },
            controlbar: { height: mobile ? 60 : 80 },
            infobar: { height: 60 },
            controls: { width: controlWidth },
        });
    }, [tHook, editorIntf, mobile, narrowContent]);

    React.useEffect(() => {
        if (user.profile && userLanguages) {
            const language = userLanguages.find((language) => language.id === user.profile.languageId);
            //changeInterfaceLanguage(user.profile.languageId)
            const locale = language?.code || 'en-US';
            const fullName =
                user.profile.firstName || user.profile.lastName
                    ? `${user.profile.firstName} ${user.profile.lastName}`
                    : '';
            document.cookie = `username=${fullName || user.profile.userName}`;
            setEditorUser(zdlib, {
                ...user,
                ...{
                    fullName,
                    userName: user.profile.userName,
                    id: user.profile.userIdentifier,
                    locale,
                    nameExtS: '',
                    maskAccessEnum: 0,
                },
            })
        }
    }, [user, zdlib, userLanguages]);

    return (
        <SnackbarProvider maxSnack={5}>
            <NiceModal.Provider>
                <InfoProvider>
                    <InfoHandler zdlib={zdlib} editorIntf={editorIntf} />
                    <FileHandler editorIntf={editorIntf} zdlib={zdlib} />
                    <FeedProxyHandler editorIntf={editorIntf} zdlib={zdlib} />
                    <TemplateProvider editorIntf={editorIntf} zdlib={zdlib}>
                        <SpotEditor editorIntf={editorIntf} zdlib={zdlib} />
                        {!zdlib.editor?.editing && (
                            <div className="zdsebtn material-icons" onClick={() => zdlib.editor.toggleEditing()}>
                                edit
                            </div>
                        )}
                    </TemplateProvider>
                </InfoProvider>
            </NiceModal.Provider>
        </SnackbarProvider>
    );
});

export default EditorRoot;
