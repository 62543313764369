import React from 'react';
import { CloudUpload } from '@mui/icons-material';
import { Badge, Box, Popover, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { observer } from 'mobx-react';

import ContentEditorLogo from './ContentEditorLogo';
import {Use_mq_MobileWidth, Use_mq_Narrow, Use_mq_NarrowContent} from './mediaQueries';

const useStyles = makeStyles()(() => ({
    text: {
        //color: theme.palette.text.contrast,
        textAlign: 'center',
    },
    button: {
        minWidth: 180,
        marginRight: 8,
        marginBottom: 8,
        marginLeft: '2px !important',
        /* '& + button': {
            marginLeft: 2,
        },*/
    },
    popupBtn: {
        marginRight: 8,
        marginBottom: 16,
        maxWidth: 56,
    },
    badge: {
        minWidth: 12,
        minHeight: 12,
        //right: 8,
        //top: 6,
        borderRadius: '50%',
    },
}));

function Buttons(props, classes, setAnchorElement, tHook) {
    const { zdlib, editorIntf } = props;

    function open() {
        if (setAnchorElement) setAnchorElement(null);
        zdlib.editor.open();
    }

    function save() {
        if (setAnchorElement) setAnchorElement(null);
        zdlib.editor.save(false);
    }

    function saveAs() {
        if (setAnchorElement) setAnchorElement(null);
        zdlib.editor.save(true);
    }
    const config = zdlib.editor.uiConfig;
    const saving = zdlib.editor.saving;

    const saveDisabled = !editorIntf.mediaItem?.templateId && process.env.NODE_ENV === 'production';

    return (
        <>
            {config.openButton.visible && (
                <Button
                    disabled={config.openButton.disabled}
                    className={classes.button}
                    kind="secondaryAction"
                    onClick={open}
                    label={tHook.trans('common.action.open')}
                ></Button>
            )}
            {config.saveButton.visible && (
                <Button
                    kind="secondaryAction"
                    className={classes.button}
                    disabled={config.saveButton.disabled || saveDisabled}
                    busy={saving}
                    onClick={save}
                    label={tHook.trans('common.action.save')}
                ></Button>
            )}
            {config.saveAsButton.visible && (
                <Button
                    kind="secondaryAction"
                    className={classes.button}
                    disabled={config.saveAsButton.disabled || saveDisabled}
                    label={tHook.trans('common.action.save_as')}
                    busy={saving}
                    onClick={saveAs}
                ></Button>
            )}
        </>
    );
}

/*const getPlainFileName = filename => {
    const fn = Items.getItemName(filename);
    return fn.substring(0, fn.lastIndexOf('.'));
}*/

function FileControls(props) {
    const theme = useTheme();
    const { classes } = useStyles();
    const { zdlib, tHook, editorIntf } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const menuOpen = Boolean(anchorEl);

    const narrowContent = Use_mq_NarrowContent();
    const mobile = Use_mq_MobileWidth();
    const narrow = Use_mq_Narrow();

    const { length } = zdlib.editor.changes;
    //console.log('contrs**ccccc***', isObservableProp(zdlib.editor, 'changes'), length)

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <Box
            display="flex"
            flexDirection={narrowContent ? 'column' : 'row'}
            justifyContent="center"
            alignItems="center"
            height={editorIntf.layoutConfig.bottombar.height}
            bgcolor={theme.palette.background.content}
            style={{ pointerEvents: 'auto' }}
            color={theme.palette.text.primary}
        >
            {!narrowContent && <ContentEditorLogo {...props} />}
            <Box flexGrow={1} display="flex" flexDirection={narrowContent ? 'column' : 'row'} justifyContent="center">
                {!mobile && Buttons(props, classes, undefined, tHook)}
            </Box>
            {mobile && (
                <>
                    <Box alignSelf="center" style={narrow ? {position: 'fixed', right: '12px'} : undefined}>
                        <Badge invisible={length === 0} variant="dot" color="primary" classes={{ dot: classes.badge }}>
                            <Button kind="secondaryAction" onClick={handleClick} style={{ transform: 'scale(0.85)' }}>
                                <CloudUpload />
                            </Button>
                        </Badge>
                    </Box>
                    <Popover
                        open={menuOpen}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                        PaperProps={{
                            square: true,
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            paddingTop={2}
                            paddingBottom={2}
                            paddingLeft={4}
                            paddingRight={4}
                        >
                            {mobile && (
                                <Typography
                                    variant="h5"
                                    color="textPrimary"
                                    classes={{ colorTextPrimary: classes.text }}
                                    style={{ marginBottom: 32 }}
                                >
                                    {editorIntf.mediaItem?.name ||
                                        editorIntf.mediaItem?.templateName ||
                                        zdlib.spot?.name ||
                                        ''}
                                </Typography>
                            )}
                            {Buttons(props, classes, setAnchorEl, tHook)}
                        </Box>
                    </Popover>
                </>
            )}
            {narrowContent && <ContentEditorLogo {...props} />}
        </Box>
    );
}

export default observer(FileControls);
