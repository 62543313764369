import React from "react";
import {ListItemSecondaryAction, ListItemText} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import {useTranslation} from "@zetadisplay/zeta-localization";
import {Button, ContextMenu} from "@zetadisplay/zeta-ui-components";
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import {getLocalizedString} from "../../../localization/localizationUtils";
import HTMLParser from "../../../UIComponents/HTMLParser.jsx";

const useStyles = makeStyles()((theme) => ({
    radio: {
        color: theme.palette.background.disabledText,
    },
    hidden: {
        opacity: 0,
    },
    listItem: {
        padding: '8px 8px',
        backgroundColor: theme.palette.background.main,
        '&$listItemSelected': {
            '&:hover': {
                backgroundColor: theme.palette.background.content,
            },
            backgroundColor: theme.palette.background.content,
        },
    },
    borders: {
        borderBottom: '1px solid ' + theme.palette.background.disabled,
        '&:first-of-type': {
            borderTop: '1px solid ' + theme.palette.background.disabled,
        },
    },
    listItemSelected: {},
    ListItemIcon: {
        marginRight: -24,
    },
    listItemPrimaryText: {
        display: 'inline-block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: 'calc(100% - 92px)',
        fontWeight: 400,
        fontSize: 14,
        userSelect: 'none',
        textTransform: 'none',
        letterSpacing: 0.4,
        '&:only-of-type': {
            paddingTop: '3px',
            //paddingBottom: '8px',
        },
    },
    listItemSecondaryText: {
        display: 'block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: 'calc(100% - 84px)',
        fontWeight: 400,
        fontSize: 12,
        textTransform: 'none',
        letterSpacing: 0.4,
        userSelect: 'none',
    },
    listItemSecondaryAction: {
        right: '8px',
        '& button': {
            minWidth: '40px',
        }
    }
}));

export const SEListItem = React.forwardRef(({dragItem, ...props}, ref) => {
    const {
        item,
        spotData,
        hideEdit,
        text,
        subText,
        selected,
        onSelectClick,
        onEditClick,
        disableEdit,
        actions,
        isDragOverlay,
        isDraggingItem,
        zdlib,
        style,
        ...other
    } = props;
    const { classes } = useStyles();
    const tHook = useTranslation();
    return (
        <ListItem {...other}
                  ref={ref}
                  dense
                  classes={{
                      root: `${classes.listItem}${!isDragOverlay ? ' ' + classes.borders : ''}`,
                  }}
                  selected={selected}
                  onClick={onSelectClick}
                  style={style}
        >
            {/*
                <ListItemIcon classes={{ root: isDraggingItem || isDragOverlay ? classes.hidden : classes.ListItemIcon }}>
                    <Radio
                        edge="start"
                        color="primary"
                        checked={selected}
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        classes={{ colorPrimary: classes.radio }}
                    />
                </ListItemIcon>
                */}
            <ListItemText
                classes={{ primary: isDraggingItem ? classes.hidden : classes.listItemPrimaryText,
                    secondary: isDraggingItem ? classes.hidden :classes.listItemSecondaryText }}
                primary={HTMLParser(getLocalizedString(zdlib.currentLanguage, text), { data: spotData, item })}
                secondary={
                    subText &&
                    HTMLParser(getLocalizedString(zdlib.currentLanguage, subText), { data: spotData, item })
                }
            />
            <span className={`${isDraggingItem || isDragOverlay ? classes.hidden : ''}`}>
                <ListItemSecondaryAction classes={{root: classes.listItemSecondaryAction}}>
                    {!hideEdit && (
                        <span>
                            <Button disabled={disableEdit} onClick={onEditClick}>
                                {tHook.trans('common.action.edit')}
                            </Button>
                        </span>
                    )}
                    {actions?.length > 0 && (
                        <ContextMenu actions={[{ name: 'item-actions', actions }]} item={item} />
                    )}
                </ListItemSecondaryAction>
                </span>
        </ListItem>
    )
});