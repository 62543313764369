import { autorun, extendObservable, isObservableProp, reaction } from 'mobx';
import { parse, stringify } from 'query-string';

class PageHandler {
    constructor(editorIntf, zdlib) {
        this.editorIntf = editorIntf;
        this.zdlib = zdlib;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const THIS = this;
        extendObservable(this, {
            subpages: [],
            _activePageId: null,
        });

        extendObservable(zdlib.editor, {
            get activePageId() {
                return THIS._activePageId;
            },
            get activeSubpage() {
                return THIS.subpages.length > 0 ? THIS.subpages[0] : null;
            },
            activePageComponents: [],
        });

        Object.defineProperty(zdlib.editor, 'activePageId', {
            set(value) {
                THIS.pageChanging( value );
            },
        });
        zdlib.editor.showSubpage = this.showSubpage.bind(this);
        zdlib.editor.closeAllSubpages = this.closeAllSubpages.bind(this);
        zdlib.editor.getSubpage = this.getSubpage.bind(this);

        editorIntf.closeAllSubpages = this.closeAllSubpages.bind(this);
        editorIntf.getSubpage = this.getSubpage.bind(this);

    }

    observe(enabled) {
        if (enabled) {
            this.setupReactions();
        } else {
            this.disposeReactions();
        }
    }

    setupReactions() {
        const { zdlib } = this;
        this.reactions = [
            reaction(
                () => zdlib.editor.activePageId,
                this.pageChanged.bind(this)),
            reaction(
                () => zdlib.editor.editing,
                this.editingChanged.bind(this)),
            autorun(this.componentsChanged.bind(this)),
            autorun(
                //() => this.subpages.length,
                this.subPagesChanged.bind(this)),
        ]
    }

    disposeReactions() {
        if (this.reactions) this.reactions.forEach(disposer => disposer());
    }


    pageChanging(newPageId) {
        // runs when editor page is about to change
        const { zdlib } = this;
        if (this._activePageId === newPageId) return;
        let nextId = newPageId;

        //console.log('....changing page:', this._activePageId, '==>', nextId);

        const exists = (zdlib.pages.getItem && zdlib.pages.getItem(nextId)) || nextId === null;
        if (!exists) {
            //console.log(`!!!!!! Page (id="${change.newValue}") doesn't exist, showing menu instead`);
            const pages = getSortedPages(zdlib);
            const id = pages.length === 1 ? pages[0].id : null;
            this.editorIntf.changeBrowserUrl({ page: id || undefined }, true, true);
            nextId = id;
        } else {
            const currentPage = this._activePageId && zdlib.pages.getItem(this._activePageId);

            const toPage = nextId && zdlib.pages.getItem(nextId);
            if (
                (toPage && zdlib.onPageChange(toPage) === false) ||
                (currentPage && zdlib.spotCB(currentPage, 'onLeaving', false, toPage) === false)
            ) {
                console.log('Changing page prevented by template');
                return;
            }
        }
        this._activePageId = nextId;
    }

    pageChanged(activePage, previousPage) {
        // runs after editor page has changed
        //console.log('>>>>> Changed page:',previousPage, '==>', activePage );
        const { zdlib } = this;
        // make sure all sub-pages are hidden when showing a page
        this.closeAllSubpages();
        this.editorIntf.changeBrowserUrl({ page: activePage || undefined}, false, true);
        const currentPage = activePage && zdlib.pages.getItem(activePage);
        const fromPage = previousPage && zdlib.pages.getItem(previousPage);
        if (currentPage) zdlib.spotCB(currentPage, 'onEntered', false, fromPage);
    }

    editingChanged() {
        // runs when editing vs full screen mode changes
        const { zdlib } = this;
        if (zdlib.editor.editing && zdlib.editor.activePageId) {
            const currentPage = zdlib.pages.getItem(zdlib.editor.activePageId);
            if (currentPage) zdlib.spotCB(currentPage, 'onEntered', false, null);
        }
    }

    closeSubpage(options) {
        this.showSubpage(options, true);
    }

    showSubpage(options, close) {
        const { editorIntf, zdlib } = this;
        if (!options) {
            // options = undefined => close current subpage
            let closed;
            if (this.subpages.length > 0) {
                closed = this.subpages.shift();
                if (closed.onClose) closed.onClose(closed);
                //console.log('XXX', 'closed:', closed?.id);
            }
            return closed;
        }
        // don't show sub-pages that have no components
        if (!close && getComponentsOnPage(zdlib, options.id).length === 0) {
            //console.log(`Can't show sub-page (id="${options.id}") : no components on it`);
            const subpages = editorIntf.getQueryParams().subpages;
            if (subpages && this.subpages.length === 0) editorIntf.changeBrowserUrl({ subpages: undefined }, true);
            return options;
        }
        const existing = this.subpages.findIndex((page) => {
            return page.id === options.id;
        });
        if (existing >= 0) {
            if (options.single) return options;
            // remove existing subpage from array
            const closed = this.subpages.splice(existing, 1)[0];
            if (close) {
                if (closed.onClose) closed.onClose(closed);
                //console.log('XXX', 'closed existing:', closed.id);
                return closed;
            }
            options.onClose = closed.onClose;
        }
        if (close) return;
        if (options.single) {
            this.subpages.forEach(subPage => {
                if (subPage.onClose) subPage.onClose(subPage);
            })
            this.subpages = [options];
        }
            // add page to array as first item = show it
            else this.subpages.unshift(options);
    }

    getSubpage(options) {
        if (!this.subpages || this.subpages.length === 0) return null;
        if (!options) {
            return this.subpages[0];
        }
        return this.subpages.find((page) => {
            return page.id === options.id;
        });
    }

    closeAllSubpages() {
        // eslint-disable-next-line no-empty
        //while (this.showSubpage()) {}
        this.subpages.forEach(subPage => {
            if (subPage.onClose) subPage.onClose(subPage);
        })
        this.subpages = [];
    }

    subPagesChanged() {
        const sArray = this.subpages.map(page => stringify({ id: page.id }));
        this.editorIntf.changeBrowserUrl({
            subpages: sArray.length > 0 ? stringify(sArray, { sort: false, arrayFormat: 'comma' }) : undefined,
        }, false, true);
    }

    urlParamsChanged(params) {
        this.observe(false);
        try {
            const {page, subpages} = params;
            this.zdlib.editor.activePageId = page || null;
            if (subpages) {
                const objs = parse(subpages, {sort: false, arrayFormat: 'comma'});
                const sArray = Object.values(objs).map((elem) => {
                    return parse(elem);
                });
                //console.log('...sub', sArray, sArray.length, sArray[0]);

                let page = sArray.pop();
                while (page) {
                    if (!this.getSubpage(page)) this.showSubpage(page);
                    page = sArray.pop();
                }
            } else {
                this.closeAllSubpages();
            }
        }
        finally {
            this.observe(true);
        }
    }

    componentsChanged() {
        const subpage = this.getSubpage();
        const {zdlib} = this;
        const pageId = (subpage && subpage.id) || zdlib.editor?.activePageId;
        zdlib.editor.activePageComponents = getSortedComponents(zdlib, pageId)
    }
}

export const getComponentsOnPage = (zdlib, pageId) => {
    if (!zdlib.components || !pageId) return [];
    return zdlib.components.filter((component) => {
        return !component.hidden
            && (component.pageId === pageId
                || (Array.isArray(component.pageId) && component.pageId.includes(pageId)))
    });
}

export const getSortedComponents = (zdlib, pageId) => {
    if (!zdlib.components || !pageId) return [];
    return getComponentsOnPage(zdlib, pageId)
            .sort((a, b) => {
                const x = a.sortIndex || 0;
                const y = b.sortIndex || 0;
                if (x < y) return -1;
                if (x > y) return 1;
                return 0;
            });
}

let _pages = null;

export const getSortedPages = (zdlib) => {
    if (!_pages) {
        _pages = {};
        //zdlib = zdlib || window.zdlib;
        if (!isObservableProp(_pages, 'sorted'))
            extendObservable(_pages, {
                get sorted() {
                    return zdlib.pages
                        .filter((p) => {
                            return !p.hidden;
                        })
                        .sort((a, b) => {
                            const x = a.sortIndex || 0;
                            const y = b.sortIndex || 0;
                            if (x < y) return -1;
                            if (x > y) return 1;
                            return 0;
                        });
                },
            });
    }
    return _pages.sorted;
}

export default PageHandler;
