const isLocalAsset = (value) => {
    return isNaN(value.indexI) && !value.id;
};

const isPortalAsset = (value) => {
    return !isNaN(value.indexI);
};

const getPortalSrcUrl = (component) => {
    const { value } = component;
    let urlField = component.video ? 'videoUrl' : 'url';
    if (component.video) {
        if (Number(value.formatHeightI) > Number(value.formatWidthI) && value.videoPortraitUrl)
            urlField = 'videoPortraitUrl';
        else if (!component.video.useHD) urlField = 'videoPreviewUrl';
    }
    return value[urlField];
};

const getEngageSrcUrl = (component) => {
    const { value } = component;
    const url =
        !component.video && value.mediaType && value.mediaType.toLowerCase() === 'video'
            ? value.thumbnailUrl
            : value.previewUrl;
    return url || value.url;
};

export const getAssetSrcUrl = (component) => {
    const { value } = component;
    if (!value || value.error) return '';
    let srcUrl = '';
    if (typeof value === 'string') srcUrl = component.value;
    else {
        if (isLocalAsset(value)) {
            srcUrl = value.url || value.videoUrl || value.videoPreviewUrl || value.videoPortraitUrl;
        } else {
            srcUrl = isPortalAsset(value) ? getPortalSrcUrl(component) : getEngageSrcUrl(component);
        }
    }
    if (component.onSelectSourceUrl) srcUrl = component.onSelectSourceUrl(component) || '';
    return srcUrl;
};
