import { Typography } from '@mui/material';
import Icon from '@mui/material/Icon';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { Button } from '@zetadisplay/zeta-ui-components';
import React from 'react';

const DebugFileSelect = ({ editorIntf, onSelect }) => {
    const [anchor, setAnchor] = React.useState();
    const [items, setItems] = React.useState([]);
    React.useEffect(() => {
        //fetch(`files/files.json`).then((r) => {
        fetch(`/filelist`).then((r) => {
            r.json()
                .then((json) => setItems(json))
                .catch(() => {
                    console.warn('Could not load filelist');
                });
        });
    }, []);

    const MenuItems = React.useMemo(() => {
        if (items.length === 0) {
            return (
                <Typography variant={'body1'} color={'error'}>
                    To use local templates, put them in subfolders inside (root)/files , then add files.json that has
                    names of these folders in an Array.
                </Typography>
            );
        }
        return (
            <>
                {items
                    .sort((a, b) => a.localeCompare(b))
                    .map((item) => {
                        return (
                            <MenuItem
                                key={item}
                                style={{ minWidth: 120 }}
                                onClick={() => {
                                    onSelect();
                                    editorIntf.mediaItem = null;
                                    editorIntf.changeBrowserUrl({
                                        sourcePath: `files/${item}`,
                                        templateId: undefined,
                                        mediaId: undefined,
                                    });
                                }}
                            >
                                {item}
                            </MenuItem>
                        );
                    })}
            </>
        );
    }, [onSelect, editorIntf, items]);

    const handleClick = (ev) => {
        setAnchor(ev.target);
    };

    const handleRequestClose = () => {
        setAnchor(undefined);
    };

    return (
        <div style={{ position: 'relative', top: '10px', left: '30px' }}>
            <Button onClick={handleClick}>
                {'Local templates'}
                <Icon style={{ marginLeft: 8, top: -2, position: 'relative' }}>{'expand_more'}</Icon>
            </Button>

            <Popover
                open={!!anchor}
                anchorEl={anchor}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                slotProps={{ paper: {style: { borderRadius: 0 } }}}
                onClose={handleRequestClose}
            >
                {MenuItems}
            </Popover>
        </div>
    );
};

export default DebugFileSelect;
