
import { mediaFileMapper } from '@zetadisplay/engage-components/modules/library/utils';

import { createCompatibleImageObject } from '../ZetaTemplate/utils/createCompatibleImageObject';
import {processEnv} from "../config/processEnv";

const getEngageAsset = async (target, field, zdlib) => {
    const value = target[field];
    const portalUrl = value?.videoUrl || value?.url;
    const indexI = Number(portalUrl?.startsWith(processEnv.PORTAL) && value?.indexI);
    if (isNaN(indexI) || indexI === 0) return null;
    const name = value.name || value.nameS;
    let envName = processEnv.ENGAGE_ENV;
    if (envName === 'prod') envName = '';
    const evData = await zdlib.eventLoader.getEvent({
        ID: Date.now(),
        eventPath: `d2463794-cd51-4fdf-9148-0face4a3da8f\\private\\${
            envName ? `${envName}\\` : ''
        }indexToMediaId\\${indexI}`,
        log: false,
    });
    return { mediaId: zdlib.eventLoader.parseEventServerData(evData, false), target, field, indexI, name };
};

const getMediaItem = async (api, workspaceid, mediaid) => {
    return (
        api &&
        workspaceid &&
        mediaid &&
        (await api.media().getMediaById({
            mediaid,
            workspaceid,
        }))
    );
};

export const convertPortalAssetUrls = async ({ loadedData, zdlib, editorIntf }) => {
    const promises = [];
    const { api, workspaceId } = editorIntf;
    const params = new URLSearchParams(document.location.search);
    const envName = processEnv.ENGAGE_ENV;
    const ignore = envName !== 'prod' && params.has('ignoreMissingAssets');
    if (ignore) return;
    for (const comp of loadedData.components) {
        // get asset from image/video component value
        if (comp.ui?.name === 'SE_Image') {
            promises.push(getEngageAsset(comp, 'value', zdlib));
        }
        // get asset from component customValue: probably nothing there, but possible
        promises.push(getEngageAsset(comp, 'customValue', zdlib));
        // Each item is an object, whose fields may contain image/video assets
        if (comp.ui?.name === 'SE_List')
            comp.value?.forEach((listItem) =>
                Object.keys(listItem).forEach((key) => promises.push(getEngageAsset(listItem, key, zdlib)))
            );
    }
    const assets = (await Promise.all(promises)).filter((v) => v);
    for (const { mediaId, target, field, indexI, name } of assets) {
        let found = false;
        if (mediaId) {
            const mediaItem = await getMediaItem(api, workspaceId, mediaId);
            if (mediaItem) {
                const mediaFile = mediaFileMapper(mediaItem);
                target[field] = createCompatibleImageObject(mediaFile);
                found = true;
                //console.log('%c Found in engage:', 'background-color: cyan', mediaFile.name);
            }
        }
        if (!found) {
            console.log(
                `%c Portal asset not found in Engage: name = ${name} ; idx = ${indexI}`,
                'background-color: orange'
            );
            target[field] = { error: true, name };
            editorIntf.assetMissing(name);
        }
    }
};
