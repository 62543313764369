import React from 'react';

import SE_Base from './SE__Base';
import {SE_Accordion} from './SE_Accordion';
import {SE_Audio} from './SE_Audio';
import SE_Button from './SE_Button';
import SE_Checkbox from './SE_Checkbox';
import SE_ColorPicker from './SE_ColorPicker';
import {SE_Container} from './SE_Container';
import {SE_DatePicker} from './SE_DatePicker';
import SE_Dropdown from './SE_Dropdown';
import SE_Html from './SE_Html';
import SE_Image from './SE_Image';
import SE_List from './SE_List';
import SE_NumberEdit from './SE_NumberEdit';
import SE_RadioButtons from './SE_RadioButtons';
import SE_RichText from './SE_RichText';
import SE_Slider from './SE_Slider';
import SE_Switch from './SE_Switch';
import SE_TextField from './SE_TextField';
import {SE_TimePicker} from './SE_TimePicker';

export const components = {
    SE_Button: SE_Button,
    SE_TextField: SE_TextField,
    SE_Checkbox: SE_Checkbox,
    SE_Switch: SE_Switch,
    SE_Slider: SE_Slider,
    SE_TimePicker: SE_TimePicker,
    SE_DatePicker: SE_DatePicker,
    SE_RadioButtons: SE_RadioButtons,
    SE_Image: SE_Image,
    SE_Dropdown: SE_Dropdown,
    SE_ColorPicker: SE_ColorPicker,
    SE_List: SE_List,
    SE_HTML: SE_Html,
    SE_NumberEdit: SE_NumberEdit,
    SE_RichText: SE_RichText,
    SE_Audio: SE_Audio,
    SE_Accordion: SE_Accordion,
    SE_Container: SE_Container,
};

export function getControl(props, spotData, index) {
    if (!spotData.ui || spotData.ui.name === 'SE_Transform') return null;
    const componentProps = { ...props, ...{ spotData } };
    return <SE_Base key={spotData.id ? spotData.id : 'se_' + index} {...componentProps} />;
}
