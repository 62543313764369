import {useOidcAccessToken} from "@axa-fr/react-oidc";
import React from 'react';

import {DEFAULT_OIDC_CONFIGURATION_NAME} from "../config/oidcConfiguration";
import {loadFeedData} from "./loadFeedData";

export const FeedProxyHandler = (props) => {
    const { zdlib } = props;
    const { accessToken } = useOidcAccessToken(DEFAULT_OIDC_CONFIGURATION_NAME);
    React.useEffect(() => {
        zdlib.editor.loadFeedData = ({feedDataFile}) => loadFeedData(zdlib, {feedDataFile, accessToken})
    }, [zdlib, accessToken])
    return null;
}