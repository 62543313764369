export const zoomTo = (zdlib, target, options) => {
    //const zdlib = this;
    const duration = !isNaN(options?.duration) ? options.duration : 1;

    if (!target) {
        zoomExtents(zdlib, duration);
        return;
    }
    let elRect;
    if (target.left !== undefined && target.top !== undefined && target.width && target.height) {
        elRect = target;
    } else {
        const element = typeof target === 'string'
            ? document.getElementById(target) || document.querySelector(target)
            : target;
        if (!element) {
            console.warn('zoomTo:', target, 'not found');
            return;
        }
        elRect = element.getBoundingClientRect && element.getBoundingClientRect();
        if (!elRect || elRect.width === 0 || elRect.height === 0) {
            zoomExtents(zdlib, duration);
            return;
        }
    }

    const scaling = zdlib.env.viewScale * zdlib.env.zoom;
    let padding = options?.padding || 0;
    if (typeof padding === 'string' && padding.match(/%/g)) {
        padding =
            Number(padding.replace(/%/g, '')) *
            0.01 *
            Math.min(zdlib.env.viewArea.width, zdlib.env.viewArea.height);
    }
    let unscaledW = elRect.width / scaling;
    let unscaledH = elRect.height / scaling;
    unscaledW += padding * 2;
    unscaledH += padding * 2;

    const zoomW = zdlib.env.viewArea.width / unscaledW / zdlib.env.viewScale;
    const zoomH = zdlib.env.viewArea.height / unscaledH / zdlib.env.viewScale;
    const zoom2 = Math.min(zoomW, zoomH);
    const pan2 = { x: 0.5, y: 0.5 };

    if (zoom2 > 1) {
        const size = zdlib.templateSize;
        const templateRect = document.getElementById('zd_se_spot').getBoundingClientRect();
        const canMoveX = size.width - unscaledW;
        const canMoveY = size.height - unscaledH;
        const unscaledX = (elRect.left - templateRect.left) / scaling - padding;
        const unscaledY = (elRect.top - templateRect.top) / scaling - padding;
        pan2.x = Math.max(0, Math.min(1, unscaledX / canMoveX));
        pan2.y = Math.max(0, Math.min(1, unscaledY / canMoveY)) ;
    }
    panAndZoom(zdlib, zoom2, pan2, duration);
}

export const getTransform = (zdlib, zoom, pan) => {
    const viewArea = zdlib.env.viewArea;
    const size = zdlib.templateSize;
    const viewAspect = viewArea.width / viewArea.height;
    const templateAspect = size.width / size.height;
    const hMax = viewAspect < templateAspect;
    const viewScale = hMax ? viewArea.width / size.width : viewArea.height / size.height;
    const scaling = viewScale * zoom;
    const x = viewArea.left + (viewArea.width - size.width * scaling) * pan.x;
    const y = viewArea.top + (viewArea.height - size.height * scaling) * pan.y;
    return {scaling, x, y, hMax, viewScale}
}

const panAndZoom = (zdlib, zoom, pan, duration) => {
    const {scaling, x, y, viewScale} = getTransform(zdlib, zoom, pan);
    const template = document.getElementById('zd_se_spot');
    if (duration > 0) template.style.transition = `transform ${duration}s`;
    template.style.transform = `matrix(${scaling}, 0, 0, ${scaling}, ${x}, ${y})`;
    setTimeout(() => {
        template.style.transition = ''
        zdlib.env.zoom = zoom;
        zdlib.env.pan = pan;
        zdlib.env.viewScale = viewScale;
    }, 5 + duration * 1000)
}

const zoomExtents = (zdlib, duration) => {
    panAndZoom(zdlib, 1, {x: 0.5, y: 0.5}, duration);
}