import React from 'react';
import {Dialog, Typography} from '@mui/material';
import type {
    LibraryModelsMedia,
    LibraryModelsTemplateRelations
} from '@zetadisplay/engage-api-client';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';

import './FileDialog.css';
import { Use_mq_MobileWidth } from '../Layout/mediaQueries';

type FileDialogResult = LibraryModelsMedia | LibraryModelsTemplateRelations;

export interface FileDialogProps {
    options: {
        close?: () => void;
        resolve: (result: FileDialogResult | false) => void;
    };
    title: string;
    renderContent: () => React.ReactElement;
    renderActions?: () => React.ReactElement;
    getDialogResult: () => FileDialogResult;
    disabled: boolean;
    okAction: string;
    footerHeight?: string;
    zdlib: any;
}

const FileDialog = (props: FileDialogProps) => {
    const { options,
            title ,
            renderContent,
            renderActions,
            getDialogResult,
            disabled,
            okAction,
            footerHeight = '80px',
            zdlib,
    } = props;
    const mobile = Use_mq_MobileWidth();
    const tHook = useTranslation();

    // TODO: handle current folder ID
    // TODO: navigate to current template file folder
    // TODO: persist folder between invocations

    const close = (ev?: object, reason?:  "backdropClick" | "escapeKeyDown") => {
        if (reason !== 'backdropClick' && options.close) options.close();
    };



    const handleOKClick = () => {
        //console.log('-*_*_*_*_*_*_*-----', selectedItem)
        options.resolve(getDialogResult());
        close();
    };

    if (!options) return null;

    const style = {'--footer-height': footerHeight} as React.CSSProperties;

    return (
        <Dialog
            open={!!options}
            onClose={close}
            disableEscapeKeyDown
            fullScreen={mobile}
        >
            <div className={'file-dialog-container'}>
                <div className={'file-dialog-root'}>
                    <div className={'file-dialog-title'}>
                        <Typography variant={'h2'}>
                            {title}
                        </Typography>
                    </div>

                    <div className={'file-dialog-content'} style={style}>
                        {renderContent()}
                    </div>

                    <div className={'file-dialog-footer'}>
                        {renderActions && renderActions()}

                        <div className={'file-dialog-actions'}>
                            <Button
                                kind="secondaryAction"
                                onClick={() => {
                                    options.resolve(false);
                                    close();
                                }}
                                label={tHook.trans('common.action.cancel')}
                            />
                            <Button
                                kind="primaryAction"
                                label={ okAction }
                                disabled={disabled}
                                onClick={handleOKClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default FileDialog;
