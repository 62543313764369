import {observer} from 'mobx-react';
import React from 'react';

import {getLocalizedString} from "../localization/localizationUtils";
import {NumberEdit} from "../UIComponents/NumberEdit";
import {changeValue} from './SE__Base'

const SE_NumberEdit = observer((props) => {



    const {spotData, zdlib, events} = props;
    const {ui, value} = spotData;
    const {label, disabled, error, placeholder, required, disableDecimals, precision,
        unit, useUnitInValue, min, max, step, unitBefore} = ui;

    const handleChange = React.useCallback((newValue) => {
        changeValue({ type: 'NumberEditChange' }, spotData, newValue);
    }, [spotData]);

    return (

        <NumberEdit
            label={getLocalizedString(zdlib.currentLanguage, label)}
            value={value || ''}
            disabled={disabled}
            error={error}
            placeholder={getLocalizedString(zdlib.currentLanguage, placeholder)}
            required={required}
            disableDecimals={disableDecimals}
            precision={precision}
            unit={getLocalizedString(zdlib.currentLanguage, unit)}
            useUnitInValue={useUnitInValue}
            unitBefore={unitBefore}
            min={min}
            max={max}
            step={step}
            style={{ marginTop: label ? 12 : 2}}
            onChange={ handleChange }
            {...events}
        />

    );

});

export default SE_NumberEdit;