import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {observer} from 'mobx-react';
import React from 'react';

import {getLocalizedString} from "../localization/localizationUtils";
import {changeValue} from './SE__Base';

class SE_Switch extends React.Component {

    handleChange = (spotData, forceOnChangeCallback, event, newValue) => {        
        changeValue(event, spotData, newValue, forceOnChangeCallback )
     }

    render() {

        const { spotData , events , forceOnChangeCallback, zdlib} = this.props;
        const uiProps = {...spotData.ui, ...(spotData.ui.props || {})}
        if (!uiProps.labelPlacement) uiProps.labelPlacement = 'end';
        const {labelPlacement, label, disabled} = uiProps

        if (!this.changeHandler) 
            this.changeHandler = this.handleChange.bind(this, spotData, forceOnChangeCallback)

        return (
            <div style={labelPlacement === 'end'? {marginLeft :-8} : undefined}>
            <FormControlLabel onChange={this.changeHandler}
                style={{marginLeft: 0, textAlign: labelPlacement === 'top' || labelPlacement === 'bottom' ? 'center': 'inherit'}}
                label={getLocalizedString(zdlib.currentLanguage, label)}
                labelPlacement={labelPlacement || 'end'}
                control={<Switch  
                    
                        checked={spotData.value || false}
                        color='primary'
                        
                        disabled={disabled}
                        {...events}
                        /> }             
             />
             </div>
        )

    }

}

export default observer(SE_Switch);