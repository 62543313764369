import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, Box, Icon, Typography } from '@mui/material';
import { LibraryModelsMediaTypes } from '@zetadisplay/engage-api-client';
import { isMediaFile } from '@zetadisplay/engage-components/models';
import {FilterFields} from "@zetadisplay/engage-components/modules/search";
import { Button, TextField } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { observer } from 'mobx-react';

import {processEnv} from "../config/processEnv";
import MediaPicker from '../LibraryView_engage/MediaPicker';
import {MediaPickerControls} from "../LibraryView_engage/MediaPickerControls";
import { createCompatibleImageObject } from '../ZetaTemplate/utils/createCompatibleImageObject';
import { changeValue } from './SE__Base';

const useStyles = makeStyles()((theme) => ({
    thumbParent: {
        width: 'parent',
        height: 102,
        border: `1px solid ${theme.palette.background.disabled}`,
        backgroundColor: theme.palette.background.content,
        padding: 0,
        overflow: 'hidden',
        display: 'block',
    },
    thumbHidden: {
        display: 'none',
    },
    thumbnail: {
        maxHeight: 100,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
    },
    icon: {
        color: theme.palette.background.disabled,
    },
    expansionPanel: {
        backgroundColor: theme.palette.background.main,
        color: theme.palette.background.disabled,
        overflow: 'hidden',
        padding: 0,
        margin: 0,
    },
}));

const SAFE_URL_PATTERN = /^(?:https?:|[^&:/?#]*(?:[/?#]|$))/gi;
/** A pattern that matches safe data URLs. It only matches image, video */
//const DATA_URL_PATTERN = /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm));base64,[a-z0-9+]+=*$/i;

function sanitizeUrl(url) {
    url = String(url);
    if (url === 'null' || url.length === 0 || url === 'about:blank') return '';
    return url.match(SAFE_URL_PATTERN) /*|| url.match(DATA_URL_PATTERN)*/ ? url : '';
}

const getDisplayName = (fileInfo, tHook) => {
    if (!fileInfo) return tHook.trans('common.notification.nothing_selected');
    if (fileInfo.error) return tHook.trans('common.notification.not_found');
    return fileInfo._displayName || '';
};

const getDimensionsAsString = (fileInfo) => {
    if (!fileInfo.width && !fileInfo.videoWidth && !fileInfo.naturalWidth) return '';
    return `${fileInfo.width || fileInfo.videoWidth || fileInfo.naturalWidth} x ${
        fileInfo.height || fileInfo.videoHeight || fileInfo.naturalHeight
    } px`;
};

const getFilename = (url) => {
    if (!url) return '';
    const n = url.lastIndexOf('/');
    return url.substring(n >= 0 ? n + 1 : 0);
};

const doneLoading = (img, vid) => {
    if (img) {
        img.onload = null;
        img.onerror = null;
    }
    if (vid) {
        vid.onloadeddata = null;
        vid.onerror = null;
    }
};

const handleNewValue = (setFileInfo, selectedItem, newValue) => {
    setFileInfo({
        ...newValue,
        ...{
            _url:
                (!selectedItem._isVideo && selectedItem.imagePreviewUrl) ||
                (selectedItem._isVideo && (selectedItem.videoPreviewUrl || selectedItem.videoUrl)) ||
                selectedItem._url,
        },
    });
    delete newValue._url;
    delete newValue._isVideo;
    delete newValue._displayName;
};

const isVideoFilename = (url) => {
    if (!url) return false;
    const name = url.toLowerCase();
    return name.includes('.mp4') || name.includes('.webm') || name.includes('.mpeg');
};

const SE_Image = (props) => {
    const { classes, cx } = useStyles();
    const { spotData, editorIntf, tHook } = props;
    const [pickerOpen, setPickerOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(spotData.ui.urlEdit === 'visible');
    const [visibleMediaTypes, setVisibleMediaTypes] = React.useState([
        LibraryModelsMediaTypes.Image,
        LibraryModelsMediaTypes.Video,
        LibraryModelsMediaTypes.Unknown,
    ]);

    const init = React.useCallback(() => {
        if (!spotData.value) return null;
        const url = spotData.value.url || !spotData.value.id
                    ? ''
                    : `${processEnv.API_ROOT}/workspaces/${editorIntf.workspaceId}/media/${spotData.value.id}/preview`
        const ret = createCompatibleImageObject(spotData.value);
        return {...ret,
                ...{
                    _isVideo:
                        spotData.value.mediaType === 'video' ||
                        spotData.value.videoUrl ||
                        spotData.value.videoPreviewUrl ||
                        isVideoFilename(spotData.value.url || url),
                    _displayName:
                        spotData.value.name ||
                        spotData.value.nameS ||
                        getFilename(spotData.value.videoUrl) ||
                        getFilename(spotData.value.videoPreviewUrl) ||
                        getFilename(spotData.value.imagePreviewUrl) ||
                        getFilename(spotData.value.url || url),
                    _url:
                        spotData.value.engage?.previewUrl ||
                        spotData.value.videoUrl ||
                        spotData.value.videoPreviewUrl ||
                        spotData.value.imagePreviewUrl ||
                        spotData.value.url ||
                        url
                },
            }
    }, [spotData.value, editorIntf.workspaceId ]);

    const [fileInfo, setFileInfo] = React.useState(init());
    const [selectedItem, setSelectedItem] = React.useState(spotData.value ? init() : {});
    const imgRef = React.useRef();
    const videoRef = React.useRef();
    const changeEvent = React.useRef(spotData.value ? 'init' : null);
    const status = React.useRef({});

    React.useEffect(() => {
        status.current.mounted = true;
        const stat = status.current;
        const vid = videoRef.current;
        const img = imgRef.current;
        return () => {
            stat.mounted = false;
            if (vid) {
                vid.onloadeddata = undefined;
                vid.onerror = undefined;
            }
            if (img) {
                img.onload = undefined;
                img.onerror = undefined;
            }
        };
    }, []);

    const close = () => {
        if (status.current.pickerOpen) {
            setPickerOpen(false);
        }
    };

    React.useEffect(() => {
        if (status.current.pickerOpen === pickerOpen) {
            return;
        }

        const index = editorIntf.dialogs.findIndex((item) => item && item.key === 'selectfile');

        if (pickerOpen) {
            if (index < 0) editorIntf.dialogs.push({ key: 'selectfile', close });
        } else {
            if (index >= 0) editorIntf.dialogs.splice(index, 1);
        }
        status.current.pickerOpen = pickerOpen;
    }, [pickerOpen, editorIntf.dialogs]);



    const clear = () => {
        setSelectedItem({});
        setFileInfo(null);
        changeValue({ type: 'ImageCleared' }, spotData, null);
    };

    const handleSelectClick = React.useCallback(
        (item) => {
            if (item.id === selectedItem?.id || item.id === spotData?.value?.id) {
                //console.log('>>>>>>>>>same:', item.name)
                return;
            }
            changeEvent.current = 'ImageSelected';

            setSelectedItem(createCompatibleImageObject(item));
        },
        [selectedItem, spotData?.value]
    );

    const onUrlChange = (ev) => {
        const newValue = sanitizeUrl(ev.target.value);
        if (newValue === '') {
            clear(ev);
        } else {
            changeEvent.current = 'ImageUrlChanged';
            setSelectedItem({
                _url: newValue,
                _isVideo: isVideoFilename(newValue),
                _displayName: getFilename(newValue),
            });
        }
    };

    const handleLoaded = React.useCallback(
        (ev) => {
            // console.log('LOADED:::', selectedItem?.name, ev.target.tagName);
            doneLoading(imgRef.current, videoRef.current);
            if (!changeEvent.current) return;

            const init = changeEvent.current === 'init';
            changeEvent.current = '';
            const newValue = {
                ...selectedItem,
                ...{ url: selectedItem.imagePreviewUrl || selectedItem._url },
            };
            if (ev.type === 'load') {
                newValue.naturalWidth = ev.target.naturalWidth;
                newValue.naturalHeight = ev.target.naturalHeight;
            } else {
                newValue.videoWidth = ev.target.videoWidth;
                newValue.videoHeight = ev.target.videoHeight;
                newValue.durationMs = ev.target.duration;// * 1000;
                if (!newValue.videoUrl) newValue.videoUrl = selectedItem._url;
                if (!newValue.videoPreviewUrl) newValue.videoPreviewUrl = selectedItem._url;
                /* if (!init && (selectedItem.id || selectedItem.indexI))
                await api.linkAsset({assetId: selectedItem.id || selectedItem.indexI});*/
            }
            handleNewValue(setFileInfo, selectedItem, newValue);
            if (!init) {
                //console.log('>>>changeValue:', newValue);
                changeValue({ type: changeEvent.current }, spotData, newValue);
            }
            setSelectedItem({});
        },
        [selectedItem, spotData]
    );

    const handleError = React.useCallback(
        (ev) => {
            // console.log('!!!load error:', selectedItem?.name, ev.target.tagName);
            if (!changeEvent.current || changeEvent.current === 'init') return;

            doneLoading(imgRef.current, videoRef.current);
            changeEvent.current = '';
            const newValue = {
                ...selectedItem,
                ...{ url: selectedItem.imagePreviewUrl || selectedItem._url, error: true },
            };
            handleNewValue(setFileInfo, selectedItem, newValue);
            changeValue({ type: changeEvent.current }, spotData, newValue);
            setSelectedItem({});
        },
        [selectedItem, spotData]
    );

    React.useEffect(() => {
        if (!videoRef?.current || !imgRef?.current || !changeEvent.current || !status.current.mounted) {
            return;
        }

        const isVideo = selectedItem?._isVideo;

        const url = selectedItem?._url;

        if (!url) {
            return;
        }

        const ref = isVideo ? videoRef.current : imgRef.current;
        // console.log('...........load:', selectedItem?.name, ref.tagName, url);

        if (ref.hasAttribute('src')) {
            ref.removeAttribute('src');
            if (isVideo) ref.load();
        }

        if (isVideo) {
            ref.onloadeddata = handleLoaded;
            ref.onerror = handleError;
            const poster = selectedItem.engage?.thumbnailUrl || selectedItem.imagePreviewUrl;
            if (poster) ref.poster = poster;
        } else {
            ref.onload = handleLoaded;
            ref.onerror = handleError;
        }
        ref.src = url;
    }, [handleError, handleLoaded, selectedItem]);



    React.useEffect(() => {
        if (spotData.ui?.isFileVisible) {
            const mediaTypes = [];
            if (spotData.ui.isFileVisible({ mediaType: 'image' })) mediaTypes.push(LibraryModelsMediaTypes.Image);
            if (spotData.ui.isFileVisible({ mediaType: 'video' })) mediaTypes.push(LibraryModelsMediaTypes.Video);
            setVisibleMediaTypes(mediaTypes);
        }
    }, [spotData.ui]);

    const thumbParent = cx({
        [classes.thumbParent]: true,
        [classes.thumbHidden]: !fileInfo,
    });

    //console.log('>>>>>>>>>>>>>>>>>>>', spotData.id, visibleMediaTypes.join(','));

    return (
        <>
            <MediaPicker
                open={pickerOpen}
                editorIntf={editorIntf}
                onClose={() => {
                    close();
                }}
                title={tHook.trans('common.action.select.file')}
                LibraryViewProps={{
                    // isItemVisible,
                    // selectedItem: fileInfo,
                    contentFilters: {[FilterFields.MEDIA_TYPE]: visibleMediaTypes},
                    renderPrimaryAction: (item) => {
                        return isMediaFile(item) ? (
                            <Button
                                label={tHook.trans('common.action.select')}
                                busy={selectedItem?.id === item.id}
                                onClick={() => handleSelectClick(item)}
                            />
                        ) : null;
                    },
                    uploadAction: true,
                    urlAction: false,
                }}
            />
            <Accordion
                classes={{ root: classes.expansionPanel }}
                expanded={expanded}
                onChange={(event, isExpanded) => {
                    setExpanded(isExpanded);
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore classes={{ root: classes.icon }} />}
                    classes={{ root: classes.expansionPanel, content: classes.expansionPanel }}
                >
                    <Icon classes={{ root: classes.icon }} style={{ marginLeft: -2 }}>
                        {fileInfo && fileInfo.error ? 'warning' : 'info'}
                    </Icon>
                    <Typography
                        variant="subtitle2"
                        style={{ marginLeft: 4, marginTop: 2 }}
                        title={fileInfo && !fileInfo.error ? getDisplayName(fileInfo, tHook) : undefined}
                    >
                        {getDisplayName(fileInfo, tHook)}
                    </Typography>
                </AccordionSummary>

                <Box display="flex" flexDirection="column" alignItems="stretch">
                    {fileInfo && !fileInfo.error && (
                        <Typography variant="subtitle2" style={{ paddingBottom: 12 }}>
                            {`${getDimensionsAsString(fileInfo)} ${
                                fileInfo._isVideo && (fileInfo.duration || fileInfo.durationMs)
                                    ? ` • ${((fileInfo.duration || fileInfo.durationMs) / 1000).toFixed(1)} s`
                                    : ''
                            }`}
                        </Typography>
                    )}
                    {spotData.ui.urlEdit === 'info' || spotData.ui.urlEdit === 'visible' ? (
                        <TextField
                            label="URL"
                            fullWidth
                            multiline
                            disabled={spotData.ui.urlReadOnly}
                            placeholder={tHook.trans('common.notification.type_url')}
                            value={fileInfo ? fileInfo._url || (fileInfo.error && fileInfo.name) : ''}
                            onChange={onUrlChange}
                        />
                    ) : null}
                </Box>
            </Accordion>

            <div className={thumbParent} id={`SE_ImageThumbnail_${spotData.id}`}>
                <video
                    controls
                    className={classes.thumbnail}
                    style={{ display: !fileInfo || !fileInfo._isVideo ? 'none' : 'block' }}
                    loop
                    muted
                    autoPlay
                    ref={videoRef}
                />
                <img
                    alt=""
                    className={classes.thumbnail}
                    style={{ display: !fileInfo || fileInfo._isVideo ? 'none' : 'block' }}
                    ref={imgRef}
                />
            </div>
            <MediaPickerControls trans={tHook.trans}
                                 clearEnabled={!!fileInfo}
                                 onClear={clear}
                                 onOpenPicker={() => setPickerOpen(true)}
                />
        </>
    );
};

export default observer(SE_Image);
