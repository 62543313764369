
export const loadFeedData = (zdlib, {feedDataFile, accessToken}) => {
    const headers = new Headers();
    headers.append('Accept', 'application/xml');
    if (accessToken) {
        headers.append('Authorization', `Bearer ${accessToken}`);
        headers.append('Content-Type', 'application/json');
    }
    const request = {
            method: 'GET',
            redirect: 'follow',
            headers,
        }
    let url = `https://feedproxy.zetadisplay.${zdlib.env.isDev ? 'dev' : 'io'}/api/proxy?proxieurl=${feedDataFile}`;
    // EN-3144
    if (zdlib?.env.isTranscoding) url = `${document.location.protocol}//${document.location.host}/Service/Relay.aspx?url=${encodeURI(url)}`;
    zdlib.feedLoader.onLoading(feedDataFile);
    return new Promise((resolve, reject) => {
        fetch(url, request).then(response => {
            zdlib.feedLoader.onLoaded(feedDataFile);
            resolve(response);
        }).catch(error => {
                zdlib.feedLoader.onError(feedDataFile);
                reject(error);
            })
    })

}