import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { observer } from 'mobx-react';
import React from 'react';

import { getLocalizedString } from '../localization/localizationUtils';
import { changeValue } from './SE__Base';

class SE_Checkbox extends React.Component {
    handleChange = (spotData, forceOnChangeCallback, event, newValue) => {
        changeValue(event, spotData, newValue, forceOnChangeCallback);
    };

    render() {
        const { spotData, events, forceOnChangeCallback, zdlib } = this.props;
        const uiProps = { ...spotData.ui, ...(spotData.ui.props || {}) };
        if (!uiProps.labelPlacement) uiProps.labelPlacement = 'end';
        const { labelPlacement, label, disabled } = uiProps;

        if (!this.changeHandler) this.changeHandler = this.handleChange.bind(this, spotData, forceOnChangeCallback);

        return (
            <FormControlLabel
                onChange={this.changeHandler}
                style={{
                    marginLeft: 0,
                    textAlign: labelPlacement === 'top' || labelPlacement === 'bottom' ? 'center' : 'inherit',
                }}
                label={getLocalizedString(zdlib.currentLanguage, label)}
                labelPlacement={labelPlacement}
                control={
                    <Checkbox
                        style={labelPlacement === 'end' ? { marginLeft: -10 } : undefined}
                        checked={spotData.value || false}
                        color="primary"
                        disabled={disabled}
                        {...events}
                    />
                }
            />
        );
    }
}

export default observer(SE_Checkbox);
