import React from 'react';

import type { DialogOptions, InfoFunc, PopperOptions, SnackbarOptions } from './InfoProvider';
import { InfoContext } from './InfoProvider';

/**
 * This hook returns a function, that shows a modal dialog.
 * The function returns a function, that can be used to close the dialog.
 * @function
 * @return
 * Options param is an object with fields
 * - title : `string` ; Dialog title
 * - text :  `string` ; Dialog text
 * - parseFunction : `text => string` ; if specified, called when text is rendered. Implement e.g. to parse HTML text
 * - disableEscapeKeyDown : `boolean` ; if true dialog isn't closed by pressing ESC
 * - disableBackdropClick : `boolean`  ; if true dialog isn't closed by clicking outside
 * - content : React component that is rendered as a child (content) of the Dialog
 * - actions : React component that is rendered in the bottom of the Dialog
 * - buttons : `Array<Object>` ; creates [buttons]{@link #/Components/Button}, with properties from the array.
 * If there is no `onClick` handler, the button just closes the dialog.
 * The last button, which should be the primary action ("OK") , is rendered as `kind="primaryAction"`
 * and others as `kind="secondaryAction"` , if not specified.
 * If there are no buttons or actions, an OK button is rendered.
 * - onShow : `options => void` ; called when dialog is shown.
 * - onClose : `options => void` ; called when dialog is closed.
 * - contentOnly : `boolean` ; If true, renders only the content, and none of te MUI dialog parts
 * (title, actions, etc)
 *
 * See [InfoProvider component]{@link #/Components/InfoProvider} for examples
 *
 ```js static
 import { useDialog } from '@zetadisplay/zeta-ui-components';

 // call the hook
 const showDialog = useDialog();
 // show your component in a modal
 const close = showDialog( { content: <MyComponent/> } );

 ```

 */
export const useDialog: () => InfoFunc<DialogOptions> | undefined = () => React.useContext(InfoContext).showDialog;

/**
 * This hook returns a function, that shows an info popper.
 * The function returns a function, that can be used to close the popper.
 * @function
 * @return
 * The info param is an object with fields
 * - element : `string | element | ref` ; DOM element or element.id, or React ref. Show popper next to element. **Required**.
 * - title : `string` ; Text shown on top of popper
 * - text : `string` ; main text
 * - link  : `string` ; URL to e.g. help page
 * - linkText : `string` ; text to show instead of url
 * - type : ´"info" | "warning" ;  info sets background colour to blue (default), warning is yellow
 * - position : `“right” | “top” | “bottom” | “left”` ; popper position; default=right
 * - parseFunction : `text => string` ; if specified, called when title/text is rendered. Implement e.g. to parse HTML text
 * - onShow : `info => void` ; called when the popup is shown. The info parameter is the info object itself
 * - onClickLink : `info => void` ; called when user clicks the link
 * - onClose : `info => void` ; called when the popper is closed
 *
 * See [InfoProvider component]{@link #/Components/InfoProvider} for examples.
 ```js static
 import { usePopper } from '@zetadisplay/zeta-ui-components';

 // call the hook
 const showPopper = usePopper();
 // show your message next to some DOM element
 const close = showPopper({ element: 'elementId', text: 'Important info here!'});
 // close it after 10 sec
 setTimeout( close, 10000 );
 ```
 */
export const usePopper: () => InfoFunc<PopperOptions> | undefined = () => React.useContext(InfoContext).showPopper;

/**
 * This hook returns a function, that shows a snackbar message.
 * The function returns a function, that can be used to close the snackbar

 * @function
 * @return
 *  The info param is an object with fields
 * - text: `string` ; message to show
 * - timeout: `number` ; time (ms) after which snackbar auto-hides.
 * - color: `"info" | "warning" | "error" | "success"` ; Message background color. Default is "info" (blue)
 * - action: `string` ; Shows a button with text. If there's an action, timeout is ignored (user has to dismiss the message)
 * - onAction: `options => void` ; Called when user clicks the button
 * - parseFunction : `text => string` ; if specified, called when text is rendered. Implement e.g. to parse HTML text
 * - horizontal: `"left" | "center" | "right"` ; Horizontal position of the message. Default is "right"
 * - vertical: `"top" | "bottom" ; Vertical position of the message. Default is "bottom"
 *
 * See [InfoProvider component]{@link #/Components/InfoProvider} for examples.
 ```js static
 import { useSnackbar } from '@zetadisplay/zeta-ui-components';

 // call the hook
 const showSnackbar = useSnackbar();
 // show the message
 showSnackbar({text: 'This is a message'});
 ```
 */
export const useSnackbar: () => InfoFunc<SnackbarOptions> | undefined = () =>
    React.useContext(InfoContext).showSnackbar;
