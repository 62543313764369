import React from 'react';
import { observer } from 'mobx-react';
import {getSortedComponents} from "State/PageHandler";

import {getControl} from "./components";

export const SE_Container = observer((props) => {
    const {zdlib, spotData} = props;
    const id = spotData?.id;

    return React.createElement(React.Fragment,
        undefined,
                getSortedComponents(zdlib, id).map((component, index) => {
                    return getControl(props, component, index)
                    })
            )
})


