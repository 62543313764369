import React from 'react';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';

import apiConfig from '../config/apiConfig';
import { DEFAULT_OIDC_CONFIGURATION_NAME } from '../config/oidcConfiguration';
import { processEnv } from "../config/processEnv";
import { deleteTempListItemFields } from "../Editors/EditorControls/List/listUtils";
import { MediaBaseUrl } from '../models/MediaFile';
import {sendLogLine} from "../ZetaTemplate/utils/logger";
import { getMediaPublishedPlaylists, getTemplateItem } from './apiUtils';
import OpenDialog from "./OpenDialog";
import SaveDialog from "./SaveDialog";


const deleteTempItemFields = (component) => {
    const comp = { ...component };
    if (Object.prototype.hasOwnProperty.call(comp, 'event')) {
        delete comp.event;
    }
    if (!comp.ui) return comp;
    if (comp.ui.info || comp.ui.info === null) delete comp.ui.info;
    Object.keys(comp.ui).forEach((key) => {
        if (comp.ui[key] instanceof Function) delete comp.ui[key];
    });
    const handleHeader = (hdr) => {
        if (!hdr) return;
        if (!hdr.info) delete hdr.info;
        else {
            delete hdr.info.element;
            delete hdr.info._element;
            delete hdr.info.parseFunction;
            delete hdr.info.onClose;
        }
    };
    handleHeader(comp.ui.header);
    handleHeader(comp.ui.largeHeader);
    handleHeader(comp.ui.footer);
    if (comp.value === null || comp.value === undefined) delete comp.value;
    if (comp.customValue === null || comp.customValue === undefined) delete comp.customValue;
    deleteTempListItemFields(comp);
    return comp;
};

class InternalHandler {
    constructor(props) {
        const { editorIntf, zdlib, workspaceId, tHook, token, api } = props;
        this.editorIntf = editorIntf;
        this.workspaceId = workspaceId;
        this.token = token;
        this.zdlib = zdlib;
        this.api = api;
        this.tHook = tHook;
        zdlib.editor.save = action(this.save.bind(this));
        zdlib.editor.open = action(this.open.bind(this));
    }

    fileDialog = (purpose) => {
        if (this.dialogPromise) return this.dialogPromise;
        const { editorIntf } = this;
        const key = `${purpose}file`;
        const close = () => {
            this.dialogPromise = null;
            let index = editorIntf.dialogs.findIndex((item) => item?.key === 'openfile' || item?.key === 'savefile');
            while (index >= 0) {
                //console.log('>>>>> close');
                editorIntf.dialogs.splice(index, 1);
                index = editorIntf.dialogs.findIndex((item) => item?.key === 'openfile' || item?.key === 'savefile');
            }
        };
        this.dialogPromise = new Promise((resolve) => {
            editorIntf.dialogs.push({
                key,
                close,
                options: {
                    purpose,
                    resolve,
                    close,
                },
            });
            //console.log('############', key)
        });
        return this.dialogPromise;
    };

    async open() {
        const { zdlib, tHook } = this;

        const fileProps = await this.fileDialog('open');
        if (!fileProps?.id) return false;


        if (zdlib.editor.changes.length > 0) {
            const res = await zdlib.editor.messageDialog({
                title: tHook.trans('editor.warning.unsavedchanges.header'),
                text: `${tHook.trans('editor.warning.unsavedchanges.text1')}<BR/>${tHook.trans(
                    'editor.warning.unsavedchanges.text2'
                )}`,
                buttons: [tHook.trans('common.action.cancel'), tHook.trans('common.action.open_file')],
            });
            if (res === 0) {
                //console.log('-----navigation cancelled------');
                return;
            }
        }

        const params = new URLSearchParams(document.location.search);
        this.editorIntf.mediaItem = null;

        // console.log('****', fileProps);

        if (fileProps.discriminatorType === 'Template') {
            if (fileProps.id === params.get('templateId')) {
                return;
            }
            zdlib.sid = ''
            this.editorIntf.changeBrowserUrl({ mediaId: undefined, sourcePath: undefined, templateId: fileProps.id });
        }
        else {
            if (fileProps.id === params.get('mediaId')) {
                return;
            }
            zdlib.sid = fileProps.id;
            this.editorIntf.changeBrowserUrl({ mediaId: fileProps.id, sourcePath: undefined, templateId: undefined });
        }

        return fileProps;
    }

    getSavedJSONData(fileProps) {
        //const pad = (n) => String(n).length < 2 ? `0${n}` : `${n}`;
        const { zdlib } = this;
        let templateData = {
            edits: zdlib.editor.changes,
            history: zdlib.editor.getHistory({
                fileName: fileProps.newName || fileProps.name,
            }),
            isDev: zdlib.env.isDev,
            template: {
                hasTimeline: !!zdlib.hasTimeline,
                duration: Number(zdlib.duration).toFixed(1),
                width: zdlib.templateSize.width,
                height: zdlib.templateSize.height,
                originalName: zdlib.spot?.name || '',
                language: zdlib.currentLanguage || 'en',
            },
            fileProps,
            versions: {
                editor: zdlib.editor.version,
            },
            pages: toJS(zdlib.pages).filter((page) => {
                return page && !page.disableSave;
            }),
            components: toJS(zdlib.components)
                .filter((comp) => {
                    return comp && !comp.disableSave;
                })
                .map((comp) => {
                    const ret = deleteTempItemFields(comp);
                    const value = zdlib.spotCB(ret, 'onStringify');
                    if (value !== undefined) ret.value = value;
                    if (ret.value?.getHours && (ret.ui?.name === 'SE_DatePicker' || ret.ui?.name === 'SE_TimePicker')) {
                        // save times as local time (with timezone offset)
                        // when parsing, timezone offset is removed -> date/time have their original values without timezone offset
                        ret.value = ret.value.toString();
                    }
                    Object.keys(ret).forEach((key) => {
                        if (ret[key] instanceof Function) delete ret[key];
                    });
                    return ret;
                }),
        };
        // call template's (optional) callback
        if (zdlib.onSave({ ...templateData }) === false) return false;

        return templateData;
    }

    async saveGauddiTemplate(fileProps) {
        const {zdlib, tHook, editorIntf} = this;
        zdlib.editor.saving = true;
        try {
        try {
            const res = await zdlib.onBeforeSave(fileProps);
            editorIntf.mediaItem.id = res.id;
            editorIntf.mediaItem.name = res.name;
            editorIntf.mediaItem.templateName = res.name;
            editorIntf.resetChanges();

            zdlib.editor.showMessage(tHook.trans('editor.message.savefile.text'), 5, null, null, 'success');
        }
        catch (err) {
            console.error(err)
            zdlib.editor.showMessage(tHook.trans('editor.error.savedata.text'), 5, null, null, 'error');
        }
        }
        finally {
            zdlib.editor.saving = false;
        }
    }

    async getFileToSave(isNew, silent) {
        const { zdlib, editorIntf, fileDialog, api, workspaceId, tHook } = this;

        let fileProps;
        if (isNew && !silent) {
            fileProps = await fileDialog('save');
        } else fileProps = editorIntf.mediaItem;

        if (!fileProps) return false;

        if (editorIntf.isGauddiTemplate) {
            await this.saveGauddiTemplate(fileProps);
            return false;
        }

        const modifyFileProps =
            zdlib.onBeforeSave &&
            zdlib.onBeforeSave({
                ...fileProps,
                ...{ nameS: fileProps.name, indexI: fileProps.id },
            });

        if (modifyFileProps === false) {
            console.log('## Save cancelled by template ##');
            return false;
        }

        if (modifyFileProps) {
            if (modifyFileProps.nameS || modifyFileProps.name)
                fileProps.newName = modifyFileProps.nameS || modifyFileProps.name;
            if (
                Object.prototype.hasOwnProperty.call(modifyFileProps, 'nameExtS') &&
                typeof modifyFileProps.nameExtS === 'string'
            ) {
                fileProps.hasNewNameExtS = true;
                fileProps.newNameExtS = modifyFileProps.nameExtS;
            }
            //console.log('>>>', modifyFileProps, 'nameS: ==>', fileProps.newName, 'nameExtS: ==>', fileProps.newNameExtS) ;
        }

        if (fileProps.id) {
            const playlists = await getMediaPublishedPlaylists(api, workspaceId, fileProps.id);
            if (playlists.length > 0) {
                const res = await zdlib.editor.messageDialog({
                    title: `Update players?`,
                    icon: 'confirm',
                    text: `This file is on the following playlists:
                            <ul>${playlists.map((playlist) => `<li>${playlist.name}</li>`)}</ul>
                            <br/>Updating the file will update all players showing it.`,
                    buttons: [
                        tHook.trans('common.action.cancel'),
                        tHook.trans('editor.warning.fileonplaylists.action'),
                    ],
                });
                if (res === 0) {
                    console.log('## Save cancelled by user ##');
                    return false;
                }
            }
        }

        return fileProps;
        /* const {fileName, folderId, newName, hasNewNameExtS, newNameExtS} = fileProps;
        return {...{fileName, folderId, newName, hasNewNameExtS, newNameExtS}, ...(fileProps.selectedItem || {})};*/
    }

    async logSaveError(message) {
        const { zdlib } = this;
        await zdlib.editor.logActivity(`${message || 'Unknown error'}`);
        return { message };
    }

    getUsedAssets = (data, baseUrl) => {
        const assets = {};

        const getAssetFromValue = (value, name) => {
            // look for url in value's previewUrl and url fields.
            const url = value?.previewUrl || value?.url;
            // Exit, unless there's a url that starts with CMS asset base url
            if (!url?.startsWith(baseUrl)) return;
            const { id: mediaId } = value;
            if (mediaId) {
                assets[mediaId] = { mediaId, name: `${name}(${value.name})` };
            }
        };

        const findAssets = (data, name) => {
            if (Array.isArray(data)) {
                data.forEach(findAssets);
                return;
            }
            if (!data || typeof data !== 'object') return;
            const { id: mediaId, width, height, workspaceId } = data;
            if (mediaId && width && height && workspaceId && workspaceId === this.workspaceId) {
                assets[mediaId] = { mediaId, name: `${name}(${data.name})` };
            } else {
                Object.keys(data).forEach(key => findAssets(data[key], `${name}-${key}`));
            }
        }

        data.components?.forEach((comp) => {
            // get asset from image/video component value
            if (comp.ui?.name === 'SE_Image' || comp.ui?.name === 'SE_Audio') getAssetFromValue(comp.value, comp.id);
            // get asset from component customValue: probably nothing there, but possible
            getAssetFromValue(comp.customValue, `~${comp.id}`);
            // get assets from list component's value (=items array).
            // Each item is an object, whose fields may contain image/video assets
            /*if (comp.ui?.name === 'SE_List')
                comp.value?.forEach((listItem, i) =>
                    Object.values(listItem).forEach((field) => getAssetFromValue(field, `${comp.id}[${i}]`))
                );*/
            if (comp.ui?.name === 'SE_List' && comp.value?.length > 0) {
                comp.value.forEach((item, i) => findAssets(item, `${comp.id}[${i}]`));
            }
        });
        return Object.values(assets);
    };

    async save(asNew, silent) {
        const { zdlib, editorIntf, tHook } = this;

        const isNew = asNew || !editorIntf.mediaItem?.id || editorIntf.mediaItem?.shared;

        if (isNew && silent) {
            console.warn('Tried to save file silently, but there was no file to replace');
            return { error: 'silent', message: 'No file to replace in silent mode' };
        }

        // get target file from save dialog, or skip dialog when replacing current
        // - get existing file props, when not saving as new
        const fileProps = await this.getFileToSave(isNew, silent);

        // cancelled by user or template, or handled as Gauddi template
        if (!fileProps || editorIntf.isGauddiTemplate) {
            return false;
        }
        const replaceExisting = !!fileProps.id;

        zdlib.editor.saving = true;
        console.log('-------------- begin saving ----------------');
        try {
            if (zdlib.onSaveBegin) zdlib.onSaveBegin();

            // generate template data
            let jsonData;
            try {
                jsonData = this.getSavedJSONData(fileProps);
                //console.log('#¤#¤#¤#¤#¤#¤#¤#¤XXXXX\n\n', jsonData);
                if (jsonData === false) return false;
            } catch (err) {
                if (!silent)
                    zdlib.editor.showMessage(tHook.trans('editor.error.savefile.text'), 5, null, null, 'error');
                const message = `Error getting JSON data to save: ${err.message}`;
                console.error(message);
                return this.logSaveError(message);
            }

            // gather assets used by template
            let assets = [];
            try {
                assets = this.getUsedAssets(jsonData, MediaBaseUrl);
                //console.log('############ assets: ', assets)
            } catch (err) {
                if (!silent)
                    zdlib.editor.showMessage(tHook.trans('editor.error.savedata.text'), 5, null, null, 'error');
                const message = `Getting template assets failed: ${err.message}`;
                console.error(message);
                return this.logSaveError(message);
            }


            let mediaItem;
            let response;
            const templateId = editorIntf.mediaItem.templateId;
            const name = fileProps.newName || fileProps.name;
            const headers = new Headers();
            headers.append('Authorization', `Bearer ${this.token}`);
            headers.append('Content-Type', 'application/json');
            const body = JSON.stringify(
                {
                    name,
                    data: jsonData, // or just {} to test
                    duration: Math.max(3, Math.round(zdlib.duration)), // Engage backend won't accept shorter than 3 sec
                    folderId: fileProps.folderId,
                    width: zdlib.templateSize.width,
                    height: zdlib.templateSize.height,
                    assets,
                    templateId,
                },
                (key, value) => {
                    return key === 'event' || typeof value === 'function' ? undefined : value;
                }
            );

            try {
                if (replaceExisting) {
                    const request = {
                        method: 'PUT',
                        body,
                        headers,
                        redirect: 'follow',
                    };
                    //console.log('---------------------------------\n\n', request, '\n\n----------------------------');
                    response = await fetch(
                        `${apiConfig.BASE_URL}/${apiConfig.API_VERSION}/workspaces/${this.workspaceId}/media/zetacast/${fileProps.id}`,
                        request
                    );
                } else {
                    const request = {
                        method: 'POST',
                        body,
                        headers,
                        redirect: 'follow',
                    };
                    //console.log('---------------------------------\n\n', request, '\n\n----------------------------');
                    response = await fetch(
                        `${apiConfig.BASE_URL}/${apiConfig.API_VERSION}/workspaces/${this.workspaceId}/media/zetacast`,
                        request
                    );
                }

                if (response.status !== 200)
                    throw new Error(`(${response.status}) ${response.statusText || 'Unknown error'}`);
                mediaItem = await response.json();
                console.log('response-----------\n', mediaItem);
                console.log(
                    'edit----------\n',
                    `${processEnv.CLIENT_ROOT}/?workspace=${this.workspaceId}&mediaId=${mediaItem.id}`
                );

            } catch (err) {
                if (!silent) zdlib.editor.showMessage('Sending template data failed', 5, null, null, 'error');
                return this.logSaveError(`Sending template data failed: ${err.message}; templateId=${templateId}`);
            }
            const templateItem = await getTemplateItem(this.api, this.workspaceId, mediaItem.templateId);
            editorIntf.mediaItem.id = mediaItem.id;
            editorIntf.mediaItem.name = name;
            editorIntf.mediaItem.folderId = mediaItem.folderId;
            editorIntf.mediaItem.shared = mediaItem.shared;
            editorIntf.mediaItem.sourcePath = templateItem.sourcePath;
            editorIntf.mediaItem.templateId = templateItem.id;
            editorIntf.mediaItem.templateName = templateItem.name;
            zdlib.sid = mediaItem.id;

            // disabled until ws logging on CoP gets fixed

           /* if (!templateItem.liveTemplate) {
                void sendLogLine(zdlib, mediaItem.id, [`%c** ${name} saved in ${zdlib.editor.getWorkspaceProperties().workspace.name} by ${zdlib.editor.user.userName} **`, 'background-color:orange;color:black']);
            }*/

            editorIntf.history = jsonData.history.concat();
            editorIntf.resetChanges();
            editorIntf.changeBrowserUrl({ mediaId: mediaItem.id,
                                          sourcePath: editorIntf.mediaItem.sourcePath,
                                          templateId: editorIntf.mediaItem.templateId},
                                        false);

            if (!silent)
                zdlib.editor.showMessage(tHook.trans('editor.message.savefile.text'), 5, null, null, 'success');
            zdlib.editor.logActivity(`Content Creator: template ${name} saved`, `id=${mediaItem.id}`);
        } finally {
            zdlib.editor.saving = false;
            if (zdlib.onSaveEnd) zdlib.onSaveEnd();
            console.log('-------------- end saving ----------------');
        }

        return fileProps;
    }
}

const FileHandler = observer((props) => {
    const { editorIntf, zdlib } = props;
    const handler = React.useRef(null);
    const { workspace } = useWorkspace();
    const { accessToken } = useOidcAccessToken(DEFAULT_OIDC_CONFIGURATION_NAME);
    const tHook = useTranslation();
    const api = useApi();

    React.useEffect(() => {
        if (editorIntf && zdlib && workspace && accessToken && api) {
            handler.current = new InternalHandler({
                editorIntf,
                zdlib,
                api,
                tHook,
                workspaceId: workspace.id,
                token: accessToken,
            });
        }
    }, [editorIntf, zdlib, workspace, accessToken, api, tHook]);

    const dialog = editorIntf.dialogs.find((item) => item?.key === 'openfile' || item?.key === 'savefile');
    if (!dialog?.options) return null;
    return dialog.options.purpose === 'open'
                ? <OpenDialog zdlib={zdlib} editorIntf={editorIntf} options={dialog.options}/>
                : <SaveDialog zdlib={zdlib} editorIntf={editorIntf} options={dialog.options}/>
});

export default FileHandler;
