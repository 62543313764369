import { extendObservable, isObservableProp, reaction } from 'mobx';
import { parse, stringify } from 'query-string';

class MessageHandler {
    constructor(editorIntf, zdlib) {
        this.editorIntf = editorIntf;

        if (!isObservableProp(editorIntf, 'dialogs'))
            extendObservable(editorIntf, {
                dialogs: [],
            });
    }

    observe(enabled) {
        const { editorIntf } = this;
        if (enabled) {
            if (!this.dialog_reaction) this.dialog_observe = reaction(
                () => editorIntf.dialogs.length,
                this.dialogsChanged.bind(this));
        } else {
            if (this.dialog_observe) {
                this.dialog_observe();
                this.dialog_observe = null;
            }
        }
    }

    dialogsChanged(change, replace) {
        const { editorIntf } = this;
        const sArray = editorIntf.dialogs.map((dialog) => {
            return dialog.key;
        });
        // console.log('################ dialogsChanged', sArray)
        this.editorIntf.changeBrowserUrl(
            { dialogs: sArray.length && stringify(sArray, { sort: false, arrayFormat: 'comma' }) },
            replace
        );
    }

    urlParamsChanged(params) {
        this.observe(false);
        try {
            const {dialogs} = params;
            const {editorIntf} = this;

            const objs = dialogs && parse(dialogs, {sort: false, arrayFormat: 'comma'});
            //console.log('***', objs && Object.values(objs))

            // never SHOW dialogs specified in url params, because we can't restore callbacks etc.
            // just close all currently visible dialogs, that are NOT present in dialogs query param

            editorIntf.dialogs.forEach((visibleDialog) => {
                if (
                    !objs ||
                    !Object.values(objs).some((dialogId) => {
                        return dialogId === visibleDialog.key;
                    })
                ) {
                    //console.log('...close:', visibleDialog)
                    if (visibleDialog.close) visibleDialog.close();
                }
            });

            if (dialogs && editorIntf.dialogs.length === 0) editorIntf.changeBrowserUrl({dialogs: undefined}, true);
        }
        finally {
            this.observe(true);
        }
    }
}

export default MessageHandler;
