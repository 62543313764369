import React from 'react';
import ReactDOM from 'react-dom';
import { KeyboardArrowLeft as ChevronLeft } from '@mui/icons-material';
import { Box, Paper, Slide, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import LibraryView from './LibraryView';

const useStyles = makeStyles()((theme) => ({
    drawerPaper: {
        pointerEvents: 'auto',
        backgroundColor: theme.palette.background.main,
        overflowX: 'hidden',
        position: 'fixed',
        height: '100vh',
        zIndex: 500,
    },
    titlebar: {
        cursor: 'pointer',
        paddingLeft: '2px',
    },
    backIcon: {
        color: theme.palette.primary.text,
        minHeight: 40,
        minWidth: 40,
        paddingLeft: 4,
        paddingRight: 4,
    },
    title: {
        color: theme.palette.primary.text,
    },
}));

export default function MediaPicker(props) {
    const { open, onClose, title, LibraryViewProps, editorIntf } = props;
    const { classes } = useStyles();

    const theme = useTheme();

    return ReactDOM.createPortal(
        <Slide direction="right" in={open} mountOnEnter unmountOnExit>
            <Paper elevation={0} className={classes.drawerPaper} square>
                <Box
                    minWidth={editorIntf.layoutConfig.controls.width}
                    maxWidth={editorIntf.layoutConfig.controls.width}
                    height={1}
                >
                    <Box
                        width={1}
                        height={editorIntf.layoutConfig.infobar.height}
                        display="flex"
                        alignItems="center"
                        onClick={onClose}
                        className={classes.titlebar}
                        bgcolor={theme.palette.primary.main}
                    >
                        <ChevronLeft size="large" color="secondary" className={classes.backIcon} />
                        <Typography variant="h5" color="primary" className={classes.title}>
                            {title}
                        </Typography>
                    </Box>
                    <Box padding={2} height={'calc(100% - 60px)'}>
                        <LibraryView {...LibraryViewProps} />
                    </Box>
                </Box>
            </Paper>
        </Slide>,
        window.document.body
    );
}
