import { locales as encoLocales } from '@zetadisplay/engage-components';
import type { LocaleKind, TranslationInterface } from '@zetadisplay/zeta-localization';

import enUS_CE from './enUS_CE.json';
import fiFI_CE from './fiFI_CE.json';

export const locales: TranslationInterface = {
    'en-US': encoLocales.locales['en-US'].concat(enUS_CE),
    'fi-FI': encoLocales.locales['fi-FI'].concat(fiFI_CE),
    'sv-SE': encoLocales.locales['sv-SE'],
    'nl-NL': encoLocales.locales['nl-NL'],
    'de-DE': encoLocales.locales['de-DE'],
    'nb-NO': encoLocales.locales['nb-NO'],
};

export const supportedLocales = Object.keys(locales) as LocaleKind[];

export const isSupportedLocale = (locale: string) => !!supportedLocales.find((supported) => supported === locale);
