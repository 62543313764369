import {processEnv} from "./processEnv";

const clientRoot = processEnv.CLIENT_ROOT;
const deploymentPath = processEnv.PUBLIC_URL || '/';
const redirectUri = '/signin-callback.html';

const serviceWorkerUrl = `${deploymentPath}/OidcServiceWorker.js`.replace(/\/\/+/g, '/');
const shouldEnableServiceWorker = false;

const oidcConfiguration = {
    authority: processEnv.AUTH_AUTHORITY,
    client_id: processEnv.AUTH_CLIENT_ID,
    redirect_uri: `${clientRoot}${redirectUri}`,
    scope: 'email openid profile offline_access',
    service_worker_relative_url: shouldEnableServiceWorker ? serviceWorkerUrl : undefined,
    service_worker_only: false,
};

export const DEFAULT_OIDC_CONFIGURATION_NAME = 'contenteditor';

//export { clientRoot };
export default oidcConfiguration;
