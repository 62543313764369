
export const Timer = (zdlib) => {
    const timers = { zdlib:  {start: performance.now(), last: performance.now()} };
    const start = (label = 'zdlib') => {
        timers[label] = {start: performance.now(), last: performance.now()};
        return 'Timer started: ' + label
    }
    const time = (label = 'zdlib') => {
        const now = performance.now()
        timers[label].last = now;
        return now - timers[label].start;
    }
    const log = (label, ignoreElapsed = false) => {
        const now = performance.now();
        const lbl = label || 'zdlib';
        if (!timers[lbl]) start(lbl);
        const ln = zdlib.env?.isTranscoding ? '   ' : '\n';
        const time = now - timers[lbl].start;
        if (!ignoreElapsed) {
            const last = timers[lbl].last;
            timers[lbl].last = now;
            const elapsed = now - last;
            return `${ln}+${elapsed.toFixed(1)} @${label ? label + ': ' : ' '}${time.toFixed(0)} ms`
        }
        else return `${ln}@${label ? label + ': ' : ' '}${time.toFixed(0)} ms`
    }
    const kill = (label) => {
        if (label) {
            delete timers[label] ;
            return 'Timer stopped: ' + label
        }
    }
    return {
        start,
        time,
        log,
        kill,
        }
};