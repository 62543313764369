import React from 'react';
import { KeyboardArrowLeft as ChevronLeft } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { observer } from 'mobx-react';
import { getSortedPages } from 'State/PageHandler';

import { getControl } from '../Editors/components';
import { getLocalizedString } from '../localization/localizationUtils';
import HTMLParser from '../UIComponents/HTMLParser';
import {Use_mq_Narrow} from "./mediaQueries";

const useStyles = makeStyles()((theme) => ({
    title: {
        color: `${theme.palette.primary.text} !important`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
    },
    backIcon: {
        color: theme.palette.primary.text,
        minHeight: 40,
        minWidth: 40,
        paddingLeft: 4,
        paddingRight: 4,
    },
}));

const Controls = observer((props) => {
    const theme = useTheme();
    const componentProps = { ...props, ...{ theme } };
    const controls = props.zdlib.editor.activePageComponents.map((component, index) =>
        getControl(componentProps, component, index)
    );

    return <div style={{ paddingLeft: 24, paddingTop: 16, paddingRight: 24 }}>{controls}</div>;
});

const Page = observer((props) => {
    const { zdlib, editorIntf, tHook } = props;
    const theme = useTheme();
    const { classes } = useStyles();
    const narrow = Use_mq_Narrow();

    const subpage = editorIntf.getSubpage();
    const activePage =
        subpage ||
        (zdlib.editor.activePageId &&
            zdlib.pages.find((page) => {
                return page.id === zdlib.editor.activePageId;
            }));

    if (!activePage) return <Box minWidth={theme.controls.width} maxWidth={theme.controls.width} />;

    function handleClick() {
        if (subpage) zdlib.editor.showSubpage();
        else zdlib.editor.activePageId = null;
    }

    const pages = getSortedPages(zdlib);
    const canShowMenu = subpage || pages.length > 1;
    const canShowTitle = subpage || pages.length !== 1 || activePage.title;
    const title = canShowTitle
        ? getLocalizedString(zdlib.currentLanguage, activePage.title || activePage.id) ||
          (subpage ? tHook.trans('common.action.back') : '')
        : null;

    //const translatedTitle = title && activePage.translate ? tHook.trans(title) : title;

    return (
        <Box
            minWidth={editorIntf.layoutConfig.controls.width}
            maxWidth={editorIntf.layoutConfig.controls.width}
            bgcolor={theme.palette.background.default}
            style={{ overflowY: 'auto', overflowX: 'hidden', height: narrow ? 'calc(100vh - 80px)' : '100vh' }}
        >
            {canShowTitle && (
                <Box
                    width={1}
                    height={editorIntf.layoutConfig.infobar.height}
                    bgcolor={theme.palette.primary.main}
                    display="flex"
                    alignItems="center"
                    onClick={canShowMenu ? handleClick : undefined}
                    style={{
                        cursor: canShowMenu ? 'pointer' : 'default',
                        overflow: 'hidden',
                        paddingLeft: canShowMenu ? 2 : 16,
                        userSelect: 'none',
                    }}
                >
                    {canShowMenu && (
                        <ChevronLeft size="large" color="action" classes={{ colorAction: classes.backIcon }} />
                    )}
                    <Typography variant="h5" color="primary" classes={{ h5: classes.title }} component="div">
                        {HTMLParser(title, { data: activePage })}
                    </Typography>
                </Box>
            )}
            <Controls {...props} />
        </Box>
    );
});

export default Page;
