import type { LocalizableString, LocalizedString } from './localizationTypes';

/**
 *
 * @param language 2-character ISO 639-1 code
 * @param value (unlocalized) string, or localized object
 */
export const getLocalizedString = (language: string, value?: LocalizableString): string => {
    if (!value) return '';
    if (typeof value !== 'object') return value;
    const langCode = language || 'en';
    const translated = value[langCode];
    return translated || value.en || '';
};

export const makeLocalizableString = (
    str: LocalizableString | undefined,
    languages: Array<string>
): LocalizedString => {
    if (typeof str === 'object') return str;
    const newValue: LocalizedString = { en: '' };
    const val = str || '';
    languages.forEach((lang) => {
        newValue[lang] = val;
    });
    return newValue;
};
