
export enum envVarKeys {
    API_ROOT,
    API_ROOT_PR_DEPLOYMENT,
    AUTH_AUTHORITY,
    AUTH_CLIENT_ID,
    ENGAGE_ENV,
    SE_VERSION,
    PUBLIC_URL,
    CLIENT_ROOT,
    PORTAL,
    PATCH
}

type envVarNames = keyof typeof envVarKeys;

export type envVars = {
    [key in envVarNames]?: string;
}

export const processEnv: envVars = new Proxy({} as envVars, {
    get(target: envVars, p: string): string {
        /*// @ts-expect-error - TS doesn't know about import meta
        console.warn(`processEnv: ${p}`, '>>>', import.meta.env[`VITE_${p}`]);*/
        return (import.meta?.env && import.meta.env[`VITE_${p}`]) || process?.env[p];
    }
});

