import styled from 'styled-components';

type MiniButtonProps = {
  color: string;
};

export const MiniButton = styled.div`
   color: ${(props: MiniButtonProps) => props.color};
   display: flex;   
   border-radius: 50%;
   cursor: pointer;
   user-select: none;
   padding: 4px;   
   &:hover {
       background-color: ${(props: MiniButtonProps) => `${props.color}36`};
   }
   &:active {
    background-color: ${(props: MiniButtonProps) => `${props.color}52`};
}
`
