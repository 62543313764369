import { IconButton } from '@mui/material';
import { Help } from '@styled-icons/material/Help';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import React, { useRef } from 'react';

const useStyles = makeStyles()((theme) => ({
    largeInfo: {
        color: theme.palette.background.text,
        top: -3,
        position: 'relative',
    },
    smallInfo: {
        color: theme.palette.background.disabled,
        top: -1,
        left: -2,
        position: 'relative',
    },
}));

export function InfoButton({ large, info, zdlib }) {
    const { classes } = useStyles();
    const id = `b${Math.round(1000000 * Math.random())}`;

    const btnRef = useRef(null);
    const closeFunc = useRef(null);

    const show = React.useCallback(() => {
        const opt = { ...info };
        opt.onClose = () => {
            if (closeFunc.current) closeFunc.current();
            closeFunc.current = null;
            if (info.onClose) info.onClose();
        };
        closeFunc.current = zdlib.editor.showInfo(id, opt);
    }, [info, id, zdlib]);

    React.useEffect(() => {
        if (
            (info.autoOpen || info.hideButton) &&
            btnRef.current &&
            id &&
            !closeFunc.current &&
            zdlib.editor?.showInfo
        ) {
            show();
        }
    }, [info, id, zdlib, show]);

    React.useEffect(() => {
        return () => {
            if (closeFunc.current) closeFunc.current();
        };
    }, []);

    return info.hideButton ? (
        <span ref={btnRef} id={id} />
    ) : (
        <IconButton
            color="secondary"
            id={id}
            classes={{ colorSecondary: large ? classes.largeInfo : classes.smallInfo }}
            onClick={(ev) => {
                ev.preventDefault();
                if (closeFunc.current) {
                    closeFunc.current();
                    closeFunc.current = null;
                } else {
                    show();
                }
            }}
            ref={btnRef}
            size="large"
        >
            <Help size={large ? 24 : 22} />
        </IconButton>
    );
}
