import { Typography } from '@mui/material';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import React from 'react';

import { getVersionStr } from '../UIComponents/getVersionStr';
import {Use_mq_Narrow} from "./mediaQueries";
import {observer} from "mobx-react";

const useStyles = makeStyles()(() => ({
    text: {
        color: '#999999',
        fontSize: 10,
        fontWeight: 200,
        letterSpacing: 2.2,
        padding: 8,
        marginTop: 8,
    },
}));

const ContentEditorLogo = observer(() => {
    const { classes } = useStyles();
    const narrow = Use_mq_Narrow();

    return (
        <Typography variant="caption" color="textPrimary" classes={{ caption: classes.text }}
           style={narrow ? {display: 'none'} : undefined} >
            {getVersionStr()}
        </Typography>
    );
})

export default ContentEditorLogo;

