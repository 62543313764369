import jsonpatch from 'fast-json-patch';
import {autorun, extendObservable, observable, toJS} from 'mobx';

import { convertPortalAssetUrls } from './convertPortalAssetUrls';
import {LocalDevServerConnector} from "./LocalDevServerConnector";

class ChangeHandler {
    constructor(editorIntf, zdlib) {
        this.editorIntf = editorIntf;
        this.zdlib = zdlib;

        editorIntf.loadChangesFromLocal = this.loadChangesFromLocal.bind(this);
        editorIntf.resetChanges = this.resetChanges.bind(this);
        zdlib.editor.resetChanges = editorIntf.resetChanges;

        this.diff = observable.array([], { deep: false, name: 'diff' });

        const THIS = this;
        extendObservable(zdlib.editor, {
            get changes() {
                return toJS(THIS.diff);
                }
        })

        zdlib.editor.logActivity = (msg, details) => {
            // TODO: implement logActivity()
            if (zdlib.env.isLocal) console.log('>> TBD: logActivity >> ', msg, '--', details || '');
        };
        zdlib.editor.onDataLoaded = this.onDataLoaded.bind(this);
        zdlib.editor.registerControlUpdater = this.registerControlUpdater.bind(this);
        zdlib.editor.updateComponent = this.updateComponent.bind(this);
        zdlib.editor.getHistory = this.getHistory.bind(this);

        if (zdlib.env.isLocal) {
           this.localDevConnector = new LocalDevServerConnector(zdlib, editorIntf);
        }

    }

    localDevConnector = null;



    onUnload(event) {
        const {zdlib} = this;
        if (!zdlib.env.isLocal && zdlib.editor.changes.length) {
            event.preventDefault();
            event.returnValue = '';
        }
    }

    observe(enabled) {
        const { store } = this;
        if (enabled) {
            if (!store) {
                this.resetChanges();
                return;
            }
            window.addEventListener('beforeunload', this.onUnload);
            if (!this.autoruns)
                this.autoruns = [
                    autorun(
                    (reaction) => {
                        this.createDiff()
                    },
                    { delay: 500 }),
                ]
        } else {
            window.removeEventListener('beforeunload', this.onUnload);
            if (this.autoruns) {
                this.autoruns.forEach(disposer => disposer());
                this.autoruns = null;
            }
        }
    }

    createDiff() {
        if (this.store) {
            //trace(reaction);
            const current = this.getCurrent();
            const diff = jsonpatch.compare(this.store, current);
            this.diff.replace(diff);
            this.saveChangesToLocal(diff, current);
        }
    }

    getHistory(current) {
        const { editorIntf, zdlib } = this;
        if (!current) return editorIntf.history.concat();

        const { fullName, userName, id, locale } = zdlib.editor.user;
        const { fileName } = current;

        return editorIntf.history.concat({
            fileName,
            saveTime: new Date().toString(),
            previousMediaId: editorIntf.mediaItem?.id || '',
            user: {
                fullName,
                userName,
                id,
                locale,
            },
            revert: this.getReversePatches(),
        });
    }

    getReversePatches() {
        if (!this.store) return [];
        const current = this.getCurrent();
        return jsonpatch.compare(current, this.store);
    }



    async onDataLoaded(loadedData) {
        const { editorIntf, zdlib } = this;
        editorIntf.history = loadedData.history?.concat() || [];
        await convertPortalAssetUrls({
            loadedData,
            zdlib,
            editorIntf,
        });
    }


    // save to local storage not needed: okta doesn't cause full reload after all (?)
    // + some use cases with loading local data don't work properly
    // -> disable it except when running on localhost, using a local test file

    getStoreId() {
        const {zdlib} = this;
        const params =  new URLSearchParams(document.location.search);
        const storeId = zdlib.env.isLocal && !params.has('templateId') && params.get('sourcePath');
        return storeId && `ZDCELocal/${storeId.endsWith('dev-build') ? `dev/${zdlib.spot?.name || ''}` : storeId}`;
    }

    localChangesLoaded = false;

    saveChangesToLocal(diff, components) {
        const {zdlib, editorIntf} = this;
        // use localstorage on localhost only
        if (!zdlib.env.isLocal || editorIntf.isGauddiTemplate) return;
        const storeId = this.getStoreId();
        if (!storeId || !this.localChangesLoaded) return;
        //console.log('save to local ---->>>', diff);
        const data = JSON.stringify({
            diff,
            components,
            time: new Date(),
            sourcePath: new URLSearchParams(document.location.search).get('sourcePath'),
        });
        localStorage.setItem(storeId, data);
        if (this.localDevConnector) void this.localDevConnector.send(data);
    }

    async loadChangesFromLocal() {
        const {zdlib} = this;
        // use localstorage on localhost only
        if (!zdlib.env.isLocal) return;
        const storeId = this.getStoreId();
        if (!storeId) return;
        const data = localStorage.getItem(storeId);
        this.localChangesLoaded = true;
        if (!data) return;
        if (this.localDevConnector) {
            let res = await this.localDevConnector.send(data)
            if (res !== false)
                console.log(`Data from local storage sent to dev server: ${res?.ok ? 'OK' : 'ERROR'} - ${res?.status}`);
            res = await this.localDevConnector.send(JSON.stringify(zdlib.editor.getWorkspaceProperties()), 'workspace');
            if (res !== false)
                console.log(`Workspace props sent to dev server: ${res?.ok ? 'OK' : 'ERROR'} - ${res?.status}`);
        }
        const {diff, time} = JSON.parse(data);
        //console.log('*** loaded local from', storeId, time, data);
        const { editorIntf } = this;
        if (editorIntf.history.length > 0) {
            const last = editorIntf.history[editorIntf.history.length - 1];
            // filed name was changed to saveTime at some point
            const saveTime = last.saveTime || last.time;
            if (saveTime && new Date(saveTime).getTime() > new Date(time).getTime()) {
                // template was saved at a later time than last local changes => discard
                return;
            }
        }
        this.applyPatchesLocal(diff);
    }


    applyPatchesLocal(diff) {
        const { zdlib } = this;
        const data = this.getCurrent();
        jsonpatch.applyPatch(data, diff, false, true);
        Object.keys(data).forEach(id => {
            const item = data[id];
            let obj;
            //let ui;
            try {
                obj = JSON.parse(item.value);
            } catch (err) {/**/}
           /* try {
                ui = JSON.parse(item.ui);
            } catch (err) {}*/
            const existing = zdlib.components.getItem(id);
            if (existing) {
                existing.value = obj !== undefined ? obj : item.value;
                if (item.pageId) existing.pageId = item.pageId;
                //existing.ui = ui;
            } else {
                zdlib.components.replaceItem({
                    id,
                    pageId: item.pageId,
                    value: obj !== undefined ? obj : item.value,
                    log: item.log,
                    //ui,
                })
            }
        })
    }


    getCurrent() {
        const { zdlib } = this;
        const values = {};
        toJS(zdlib.components)
            .filter((comp) => comp && !comp.disableSave)
            .forEach((comp) => {
                values[comp.id] = {
                    value: typeof comp.value === 'object' ?  JSON.stringify(comp.value) : comp.value,
                }
                if (zdlib.env.isLocal) {
                    if (comp.log) values[comp.id].log = true;
                    values[comp.id].pageId = comp.pageId;
                    //values[comp.id].ui = JSON.stringify(comp.ui);
                }
                if (Object.prototype.hasOwnProperty.call(comp, 'customValue'))
                    values[comp.id].customValue = typeof comp.customValue === 'object' ?  JSON.stringify(comp.customValue) : comp.customValue;
            });
        return values;
    }

    resetChanges = (comp) => {
        if (comp) {
            if (!this.store) return;
            this.store[comp.id].value = typeof comp.value === 'object' ?  JSON.stringify(comp.value) : comp.value;
            this.createDiff();
            return;
        }
        this.observe(false);
        try {
            this.store = this.getCurrent();
            this.diff.replace([]);
        } finally {
            this.observe(true);
        }
    };

    updateFunctions = {};

    registerControlUpdater = (componentId, updateFunction) => {
        if (!updateFunction && this.updateFunctions[componentId]) {
            //console.log('---Unregister update:', componentId);
            delete this.updateFunctions[componentId];
        } else if (this.updateFunctions[componentId] !== updateFunction) {
            //console.log('+++Register update:', componentId);
            this.updateFunctions[componentId] = updateFunction;
        }
    };

    updateComponent = (id) => {
        if (this.updateFunctions && this.updateFunctions[id]) this.updateFunctions[id]();
    };




}

export default ChangeHandler;
