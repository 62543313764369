import { Person } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useUserInformation } from "@zetadisplay/engage-components/modules/auth";
import { makeStyles } from "@zetadisplay/zeta-ui-components/utils/theme";

const useStyles = makeStyles()((theme) => ({
    label: {
        paddingLeft: 8,
        maxWidth: 220,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        color: theme.palette.text.disabled,
        fontWeight: 600,
    },
}));

export default function UserControls() {
    const theme = useTheme();
    const { classes } = useStyles();
    const { user } = useUserInformation();

    const userName =
        user?.profile?.firstName || user?.profile?.lastName
            ? `${user?.profile.firstName} ${user?.profile.lastName}`
            : user?.profile?.userName;

    return (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Person style={{ color: theme.palette.text.disabled, width: 20 }} />
            {<div className={classes.label}>{userName}</div>}
        </Box>
    );
}
