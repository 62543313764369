import {LibraryModelsTemplateTypesEnum} from "@zetadisplay/engage-api-client/models/library-models-template-types-enum";

import {getMediaItem, getTemplateItem, getTemplates} from "../LibraryView_engage/apiUtils";
import {errorCodeEnum} from "./errorCodeEnum";
import {getGauddiImportTemplateId} from "./getGauddiImportTemplateId";


let lastParams = {};

export const getTemplateProps = async ({ zdlib, 
                                         api,
                                         workspaceId,
                                         editorIntf,
                                         forceReload,
                                         }) => {
    const params = new URLSearchParams(document.location.search);
    const queryParams = getQueryParams(params);
    if (forceReload) {
        return {status: 'loading', templatePath: queryParams.sourcePath}
    }
    if (Object.keys(queryParams).every(key => queryParams[key] === lastParams[key])) return undefined;
    lastParams = {...queryParams};
    const {sourcePath, templateId, mediaId} = queryParams;
    let templatePath = sourcePath;
    let templateItem, mediaItem;
        //try {
            if (!sourcePath && !mediaId && !templateId) {
                templatePath = '';
                editorIntf.mediaItem = null;
                return {status: {error: errorCodeEnum.noSourcePath}};
            }
            try {
                if (zdlib.env.isLocal && sourcePath?.includes('files/') && !mediaId && !templateId) {
                    if (sourcePath !== editorIntf.mediaItem?.sourcePath) {
                        // on local, use test file path
                        editorIntf.mediaItem = {sourcePath};
                        templatePath = sourcePath;
                    }
                } else if (mediaId || templateId) {
                    if (mediaId && mediaId !== editorIntf.mediaItem?.id) {
                        // if there is a mediaId query param, get the media item, then from it get template and its sourcePath
                        mediaItem = await getMediaItem(api, workspaceId, mediaId);
                        editorIntf.mediaItem = mediaItem;
                        templateItem = await getTemplateItem(api, workspaceId, mediaItem.templateId);

                    } else if (!mediaId && templateId && templateId !== editorIntf.mediaItem?.templateId) {
                        editorIntf.mediaItem = {templateId};
                        // if there is no mediaId, then this is an empty template -> get sourcePath
                        templateItem = await getTemplateItem(api, workspaceId, templateId);
                    }
                    if (templateItem)  {
                        editorIntf.mediaItem.templateId = templateItem.id;
                        editorIntf.mediaItem.templateName = templateItem.name;
                        editorIntf.templateItem = templateItem;

                        if (templateItem.type === LibraryModelsTemplateTypesEnum.ZetaCast) {
                            templatePath = templateItem.sourcePath;
                            editorIntf.mediaItem.sourcePath = templateItem.sourcePath;
                            editorIntf.isGauddiTemplate = false;
                        } else if (templateItem.type === LibraryModelsTemplateTypesEnum.Html) {
                            templatePath = getGauddiImportTemplateId();
                            editorIntf.isGauddiTemplate = true;
                            if (zdlib.env.isLocal) {
                                console.log(`%cGauddi template detected - using local ${templatePath} folder`, 'background: cyan; color: green');
                            }
                        }

                        params.set('sourcePath', templatePath);
                        params.set('enable-log', '');
                        window.history.replaceState({}, '', `?${params.toString()}`);

                    } else {
                        return undefined;
                    }
                }
            } catch (err) {
                editorIntf.mediaItem = null;
                if (mediaItem && mediaItem.workspaceId !== workspaceId) {
                    // media item is from another workspace
                    // get list of available templates
                    let templateExists;
                    try {
                        const templates = await getTemplates(api, workspaceId);
                        templateExists = templates.find(template => template.id === mediaItem.templateId);
                    } catch (err) {
                        templateExists = false;
                    }
                    if (!templateExists) {
                        return {status: {error: errorCodeEnum.templateNotShared}};
                    }
                }
                return {status: {error: errorCodeEnum.invalidMediaItem}};
            }
    return {status: 'loading', templatePath}
}

const getQueryParams = (params) => ({
    sourcePath: params.get('sourcePath'),
    mediaId: params.get('mediaId'),
    templateId: params.get('templateId'),
})





